import { FC, useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Amplify, { API } from 'aws-amplify'
import {
  Form,
  Select,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Tabs,
  Input,
  Popconfirm,
} from 'antd'
import { CopyOutlined, CloseOutlined } from '@ant-design/icons'
import debounce from 'lodash.debounce'

import aws_export from '../../aws-exports'
import Utils from '../../utils/Utils'
import Button from '../../components/common/Button'
import ProjectFredChart from '../../components/project/FredChart'
import styles from '../../pages/project/ProjectPage.module.scss'
import stylesAdmin from './AdminPage.module.scss'

const KEY = 'MarketData'
const LoansPage: FC = () => {
  const { edit } = useParams()
  const [hash] = Utils.useHash()
  const [form] = Form.useForm()
  const [data, setData] = useState<null | any>(null)
  const [activeTabKey, setActiveTabKey] = useState<any>()
  const navigate = useNavigate()

  const fetchData = async (): Promise<any> => {
    const res = await API.get('usersApi', '/info', {
      queryStringParameters: { Key: KEY },
    }).catch((e) => message.error("Can't upload item. Try again."))
    const MarketData = res?.data?.Value ? JSON.parse(res.data.Value) : []
    setData({ MarketData })
    if (edit && MarketData.length === 0) addNewTab(true)
  }

  const searchSeries = async (text: string): Promise<any> => {
    return await API.get('investorsApi', '/chart-search', {
      queryStringParameters: { text },
    })
      .then((res: any) => res?.data)
      .catch((e) => message.error("Can't upload. Try again."))
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchData()
  }, [])

  useEffect(() => {
    if (data) form.setFieldsValue(data)
  }, [form, data])

  useEffect(() => {
    const newHash = decodeURI(hash || '').replace('#', '')
    if (newHash && activeTabKey !== newHash) {
      setActiveTabKey(newHash)
    }
  }, [hash])

  const submitFn = async (values: any): Promise<any> => {
    values = (values?.MarketData || []).map((item: any) => ({
      ...item,
      ChartIds: (item?.ChartIds || []).map((chart: any) => ({
        key: chart?.key,
        label: chart?.title || chart?.key,
      })),
    }))

    await API.put('usersApi', '/info', {
      body: [{ Key: KEY, Value: JSON.stringify(values) }],
    }).then(() => {
      message.success('Charts were saved!')
      navigate('/market-data')
    })
  }

  const DebounceSelect = ({
    fetchOptions,
    debounceTimeout = 800,
    ...props
  }: any) => {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState<any[]>([])
    const fetchRef = useRef(0)

    const debounceFetcher = useMemo(() => {
      const loadOptions = (value: string) => {
        if (value.length < 2) return
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setOptions([])
        setFetching(true)

        fetchOptions(value).then((newOptions: any) => {
          if (fetchId !== fetchRef.current) return

          setOptions(newOptions)
          setFetching(false)
        })
      }

      return debounce(loadOptions, debounceTimeout)
    }, [fetchOptions, debounceTimeout])

    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options.map((item) => ({
          ...item,
          title: item?.label,
          label: (
            <>
              {item?.label}{' '}
              <span className="extra-small-text">({item?.notes})</span>
            </>
          ),
        }))}
      />
    )
  }

  const addNewTab = (isFirst = false) => {
    const newTabsAmount = (data?.MarketData || []).filter((item: any) =>
      item?.Title.includes('New Tab')
    ).length
    if (isFirst && newTabsAmount > 0) return
    const Title = `New Tab ${newTabsAmount > 0 ? newTabsAmount : ''}`
    setActiveTabKey(Title)
    setData({
      MarketData: [...(data?.MarketData || []), { Title }],
    })
  }

  const deleteTab = (Title: string) => {
    const newData = {
      MarketData: data.MarketData.filter((item: any) => item.Title !== Title),
    }
    setData(newData)

    if (activeTabKey === Title) setActiveTabKey(undefined)
  }

  const onInputChange = debounce((newData: any, field: any, value: any) => {
    try {
      if (value?.target?.value) value = value.target.value
      if (field[1] === 'Title') setActiveTabKey(value)
      newData.MarketData[field[0]][field[1]] = value
      setData(newData)
    } catch (e) {
      console.log(e)
    }
  }, 1000)

  const tabs = (data?.MarketData || []).map((item: any, i: any) => ({
    label: (
      <>
        {item?.Title}{' '}
        {edit && (
          <Popconfirm
            icon={false}
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={deleteTab.bind(null, item?.Title)}
          >
            <CloseOutlined />
          </Popconfirm>
        )}
      </>
    ),
    key: item?.Title,
    forceRender: true,
    children: (
      <>
        {!edit && item?.Title && (
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 10 }}>
            <h3 className={stylesAdmin.Title}>{item?.Title}</h3>
            <Tooltip title="Copy Link">
              <CopyOutlined
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${window.location.origin}/market-data#${encodeURI(
                      item?.Title
                    )}`
                  )
                }
              />
            </Tooltip>
          </div>
        )}

        {edit && (
          <>
            <Form.Item name={['MarketData', i, 'Title']} key="Title">
              <Input
                placeholder="Title"
                onChange={onInputChange.bind(null, { ...data }, [i, 'Title'])}
              />
            </Form.Item>
            <Form.Item name={['MarketData', i, 'ChartIds']} key="ChartIds">
              <DebounceSelect
                mode="multiple"
                placeholder="Chart Ids"
                fetchOptions={searchSeries}
                onChange={onInputChange.bind(null, { ...data }, [
                  i,
                  'ChartIds',
                ])}
              />
            </Form.Item>
          </>
        )}
        <div
          className={styles.block}
          style={{
            flexDirection: 'column',
            margin: '40px 0',
            gap: '40px',
          }}
        >
          {item?.ChartIds &&
            item.ChartIds.map((chart: any) => (
              <div key={chart?.key}>
                <Tooltip title="Copy Id">
                  <CopyOutlined
                    onClick={() => navigator.clipboard.writeText(chart?.key)}
                    style={{ position: 'absolute' }}
                  />
                </Tooltip>
                <ProjectFredChart
                  id={chart?.key}
                  showMaxMin
                  showStandardDeviation
                  showTable
                />
              </div>
            ))}
        </div>
      </>
    ),
  }))

  return (
    <>
      <div className="breadcrumbWrapper">
        <div className="breadcrumbContent">
          <Breadcrumb>
            <Breadcrumb.Item>Market Data</Breadcrumb.Item>
            <Breadcrumb.Item></Breadcrumb.Item>
          </Breadcrumb>
          <span className={styles.saveBtn}>
            {!edit && (
              <Button text="Edit" onClick={() => navigate('edit')} isActive />
            )}
            {edit && (
              <>
                <Button
                  text="Add Tab"
                  onClick={addNewTab.bind(null, false)}
                  isActive
                />
                <Button text="Save" onClick={form.submit} isActive />
              </>
            )}
          </span>
        </div>
      </div>

      <Form
        layout="vertical"
        className={styles.form}
        form={form}
        style={{ marginTop: 60 }}
        onFinish={(vals) => {
          submitFn(vals).then((val) => {
            if (val == 0) form.resetFields()
          })
        }}
      >
        <Tabs
          items={tabs}
          activeKey={activeTabKey}
          onChange={(key) => setActiveTabKey(key)}
        />
      </Form>
    </>
  )
}

export default LoansPage
