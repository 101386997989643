import { FC } from 'react'
import { Table } from 'antd'
import Utils from '../../../utils/Utils'

const StatusesTable: FC<{ data: null | any }> = ({ data }) => {
  const columns = [
    {
      title: 'Loan Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Loans',
      dataIndex: 'loans',
      key: 'loans',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance: any) => Utils.currencyFormat(balance),
    },
  ]

  return (
    <Table
      rowKey={(record) => record?.status}
      pagination={false}
      dataSource={[
        ...(data?.LoanStatuses ? data?.LoanStatuses : []),
        {
          status: 'Total',
          loans: data?.TotalNumberofLoans,
          balance: data?.TotalBalance,
        },
      ]}
      columns={columns}
    />
  )
}
export default StatusesTable
