import { FC, useRef, useState, useEffect } from 'react'
import { Form, Input, message } from 'antd'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Amplify, { API } from 'aws-amplify'
import JoditEditor from 'jodit-react'

import aws_export from '../../aws-exports'
import Utils from '../../utils/Utils'

import Button from '../../components/common/Button'
import MessagesForm from './MessagesForm'
import styles from './MessagesPage.module.scss'

const MessagesFormPage: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  const editor = useRef(null)

  const [users, setUsers] = useState<any>([])
  const [fromUser, setFromUser] = useState<any>({})
  const [systemUser, setSystemUser] = useState<any>({})
  const [initialValues, setInitialValues] = useState<any>({})

  const { user } = useAuthenticator((context) => [context.user])

  const isReply = location.pathname.includes('/reply')
  const isView = !!id && !isReply
  const isAdmin = user ? Utils.isAdmin(user) : false

  const fetchOptions = async (): Promise<any> => {
    const res = await API.get('usersApi', '/users', {}).catch((e) =>
      message.error("Can't upload items. Try again.")
    )
    if (!res?.users) return

    if (isAdmin) {
      setUsers([...res.users, ...(isReply ? [res.systemUser] : [])])
    } else {
      setUsers([res.systemUser])
    }
    setSystemUser(res.systemUser)
  }

  const fetchMessage = async (): Promise<any> => {
    const res = await API.get('messageApi', '/item/' + id, {}).catch((e) =>
      message.error("Can't upload item. Try again.")
    )
    if (!res?.data) return

    const result = res.data
    if (isReply) {
      if (isAdmin) {
        result.toUsername = result?.fromUsername
      }

      result.subject = 'RE: ' + result?.subject
      result.message = '\n\n-----Original Message-----\n\n' + result?.message
    }
    form.setFieldsValue(result)
    setInitialValues({
      ...initialValues,
      ...result,
    })
  }

  const onBack = () => {
    let path = '/messages/sent'
    if (
      isView &&
      initialValues?.fromUsername !== (isAdmin ? systemUser.id : user.username)
    ) {
      path = '/messages'
    }
    if (isReply) path = '/messages/' + id
    navigate(path)
  }

  useEffect(() => {
    setFromUser({
      fromEmail: systemUser.email,
      fromUsername: isAdmin ? systemUser.id : user?.username,
      from: isAdmin
        ? systemUser.given_name
        : `${user?.attributes?.given_name} ${user?.attributes?.family_name}`,
    })
    setInitialValues({
      ...initialValues,
      priority: 'low',
      toUsername: !isAdmin ? systemUser?.id : initialValues?.toUsername,
    })
  }, [systemUser])

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchOptions()

    if (id) fetchMessage()
  }, [])

  if (isView) {
    return (
      <>
        <h3 className={styles.title}>Message Detail</h3>
        <div className={styles.buttons}>
          <Button text="Back" onClick={onBack} />
          <Button
            text="Reply"
            onClick={() => navigate(`/messages/${id}/reply`)}
          />
        </div>
        <Form className={styles.form} form={form}>
          <Form.Item name="from" label="From">
            <Input disabled />
          </Form.Item>
          <Form.Item name="to" label="To">
            <Input disabled />
          </Form.Item>
          {initialValues?.cc && (
            <Form.Item name="cc" label="Cc">
              <Input disabled />
            </Form.Item>
          )}
          <Form.Item name="subject" label="Subject">
            <Input disabled />
          </Form.Item>
          <Form.Item name="message" label="Message">
            <JoditEditor
              ref={editor}
              value={initialValues.message}
              // @ts-ignore:
              config={{
                readonly: true,
                height: 400,
                theme: 'dark',
                toolbar: false,
                statusbar: false,
              }}
            />
          </Form.Item>
        </Form>
      </>
    )
  }

  return (
    <MessagesForm
      users={users}
      isUserDisabled={!isAdmin || isReply}
      onBack={onBack}
      fromUser={fromUser}
      initialValues={initialValues}
    />
  )
}

export default MessagesFormPage
