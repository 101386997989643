import { FC, useState, useEffect } from 'react'
import Amplify, { API } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { message, Table } from 'antd'
import cs from 'classnames'

import aws_export from '../../aws-exports'
import NotificationsWidget from '../../components/common/NotificationsWidget'
import MessagesWidget from '../../components/common/MessagesWidget'
import NewsWidget from '../../components/common/NewsWidget'
import styles from '../../pages/dashboard/DashboardPage.module.scss'

const AdminDashboardPage: FC = () => {
  const [usersRequests, setUsersRequests] = useState([])
  const [accessRequests, setAccessRequests] = useState([])
  const [accessRequestsTotal, setAccessRequestsTotal] = useState(0)

  const fetchUsersRequests = async (offset = 0): Promise<any> => {
    const res = await API.get('usersApi', '/users', {
      queryStringParameters: {
        unconfirmed: false,
        investors: true,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    setUsersRequests(res?.users || [])
  }

  const fetchAccessRequests = async (offset = 0): Promise<any> => {
    const res = await API.get('investorsApi', '/request', {}).catch((e) =>
      message.error("Can't upload items. Try again.")
    )

    setAccessRequests(
      (res?.data || []).filter((item: any) => item?.Status === 'new')
    )
    setAccessRequestsTotal(res?.total || 0)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchUsersRequests()
    fetchAccessRequests()
  }, [])

  const newItemsIcon = (
    <span
      className="status"
      style={{
        background: 'red',
        marginRight: 5,
      }}
    ></span>
  )

  return (
    <div className={styles.dashboardPage}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={cs(styles.block, 'notifications')}>
            <NotificationsWidget />
          </div>
          <div className={styles.block}>
            <MessagesWidget />
          </div>
          <div>
            <div className={styles.block}>
              <Link
                to="/users-requests"
                className={cs(styles.blockTitle, styles.blockTitleLink)}
              >
                {usersRequests.length > 0 && newItemsIcon}
                Users Requests{' '}
                {usersRequests.length > 0 && <>({usersRequests.length})</>}
              </Link>
            </div>
            <div>
              {usersRequests && (
                <Table
                  pagination={false}
                  dataSource={usersRequests}
                  columns={[
                    { dataIndex: 'email', key: 'email', title: 'Email' },
                    {
                      dataIndex: 'name',
                      key: 'name',
                      title: 'Full Name',
                      render: (name: any, record: any) => (
                        <>
                          {record?.given_name} {record?.family_name}
                        </>
                      ),
                    },
                  ]}
                />
              )}
            </div>
          </div>
          <div>
            <div className={styles.block}>
              <Link
                to="/loans"
                className={cs(styles.blockTitle, styles.blockTitleLink)}
              >
                {accessRequests.length > 0 && newItemsIcon}
                Loans Access Requests{' '}
                {accessRequestsTotal > 0 && (
                  <>
                    ({accessRequests.length}/{accessRequestsTotal})
                  </>
                )}
              </Link>
            </div>
            <div>
              {accessRequests && (
                <Table
                  pagination={false}
                  dataSource={accessRequests}
                  columns={[
                    {
                      dataIndex: 'LoanAccount',
                      key: 'LoanAccount',
                      title: 'Loan Account',
                    },
                    {
                      dataIndex: 'FullName',
                      key: 'FullName',
                      title: 'Full Name',
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>

        <div>
          <div className={styles.column}>
            <div className={cs('flexGrow', styles.block)}>
              <Link
                to="/transaction-data"
                className={cs(
                  styles.blockTitle,
                  styles.blockTitleLink,
                  styles.blockTitleLinkCenter
                )}
              >
                Transaction Data
              </Link>
            </div>
            <div className={cs('flexGrow', styles.block)}>
              <Link
                to="/market-data"
                className={cs(
                  styles.blockTitle,
                  styles.blockTitleLink,
                  styles.blockTitleLinkCenter
                )}
              >
                Market Data
              </Link>
            </div>

            <div className={styles.block}>
              <Link
                to="/news"
                className={cs(styles.blockTitle, styles.blockTitleLink)}
              >
                News
              </Link>
              <NewsWidget showTitle={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardPage
