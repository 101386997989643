import "./Product.css";

export type ProductData = {
  index: string;
  text: string;
  image?: string;
};

export default function Product(productData: ProductData) {
  return (
    <div className="product-container">
      <div className="product-img">
        <img src={productData.image} className="product-image" />
      </div>
      <div className="product-index lotline-font-header3-w">
        {productData.index}
      </div>
      <div className="product-text lotline-font-header3L-w">
        {productData.text}
      </div>
    </div>
  );
}
