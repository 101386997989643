import { FC, useEffect, useState } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useParams, useNavigate } from 'react-router-dom'
import { Form, Breadcrumb, message } from 'antd'

import aws_export from '../../aws-exports'
import Button from '../../components/common/Button'
import styles from './AdminPage.module.scss'

const UserPage: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [user, setUser] = useState<any>({})

  const fetchUser = async (): Promise<any> => {
    const res = await API.get('usersApi', '/user/' + id, {}).catch((e) =>
      message.error("Can't upload item. Try again.")
    )
    setUser(res || {})
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchUser()
  }, [])

  useEffect(() => {
    form.setFieldsValue({})
  }, [form, user])

  const submitFn = async (values: any): Promise<any> => {
    await API.put('usersApi', '/user/' + id, {
      body: { ...values },
    }).catch((e) => message.error("Can't update item. Try again."))

    navigate('/users')
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => navigate(-1)}>Back To All</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>User {id}</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.title}>User {id}</h3>
      {user?.UserAttributes &&
        user?.UserAttributes.map((attr: any) => (
          <>
            {attr.Name === 'given_name' && (
              <Form.Item key={attr.Name} label="First Name">
                {attr.Value}
              </Form.Item>
            )}
            {attr.Name === 'family_name' && (
              <Form.Item key={attr.Name} label="Second Name">
                {attr.Value}
              </Form.Item>
            )}
            {attr.Name === 'email' && (
              <Form.Item key={attr.Name} label="Email">
                {attr.Value}
              </Form.Item>
            )}
          </>
        ))}
      <Form
        layout="vertical"
        className={styles.form}
        form={form}
        onFinish={(vals) => {
          submitFn(vals).then((val) => {
            if (val == 0) {
              form.resetFields()
            }
          })
        }}
      >
        <Button text="Confirm" onClick={form.submit} />
      </Form>
    </>
  )
}

export default UserPage
