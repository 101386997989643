import { FC } from 'react'
import { Table } from 'antd'
import Utils from '../../../utils/Utils'

const ProjectSummary: FC<{
  avg: any,
  total: any,
}> = ({ avg, total }) => {
  return (
    <>
      <Table.Summary.Row className="ant-table-row">
        <Table.Summary.Cell index={0} colSpan={1}>
          <div>Totals</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={10}>
          <div>
            Portfolio Yield <span>{Utils.percentFormat(avg?.soldRate)}</span>{' '}
            <span>({total ? total : 0} loans)</span>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          <div>{Utils.currencyFormat(avg?.regPayment)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          <div>{Utils.currencyFormat(avg?.prinBalance)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4}></Table.Summary.Cell>
        <Table.Summary.Cell colSpan={10} index={5}></Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  )
}

export default ProjectSummary
