import { FC, useEffect, useState } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { Select, message } from 'antd'
import aws_export from '../../aws-exports'

import Utils from '../../utils/Utils'
import Button from '../../components/common/Button'
import ProjectsTable from '../../components/common/Table'
import ProjectDescription from '../../components/investors/ProjectDescription'
import ProjectSummary from '../../components/investors/ProjectSummary'
import styles from './AdminPage.module.scss'

const { Option } = Select

const LoanPage: FC = () => {
  const pageSize = 10
  const navigate = useNavigate()
  const [loans, setLoans] = useState([])
  const [total, setTotal] = useState<null | number>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [avg, setAvg] = useState<{
    soldRate: number,
    regPayment: number,
    prinBalance: number,
  }>({
    soldRate: 0,
    regPayment: 0,
    prinBalance: 0,
  })
  const [filters, setFilters] = useState<any>({})
  const [filterValues, setFilterValues] = useState<any>(null)

  const fetchLoans = async (offset = 0): Promise<any> => {
    setLoading(true)
    const res = await API.get('investorsApi', '/list', {
      queryStringParameters: {
        Offset: offset,
        PageSize: pageSize,
        ...filters,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    setLoans(res?.data || [])
    setTotal(res?.total || 0)
    setAvg(res?.avg || {})
    setLoading(false)
  }

  const fetchFilters = async (): Promise<any> => {
    const res = await API.get('investorsApi', '/filters', {
      queryStringParameters: filters,
    }).catch((e) => message.error("Can't upload filters. Try again."))
    setFilterValues({ statuses: res?.statuses, loans: res?.loans })
  }

  const onFilter = (name: string, val: string) => {
    if (val.length) {
      setFilters({ ...filters, [name]: JSON.stringify(val) })
    } else {
      const { [name]: deleted, ...others } = filters
      setFilters(others)
    }
  }

  useEffect(() => {
    fetchFilters()
  }, [filters])

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  const columns = [
    {
      title: 'Loan Account',
      dataIndex: 'Account',
      key: 'Account',
    },
    {
      title: 'Borrower Name',
      dataIndex: 'BorrowerName',
      key: 'BorrowerName',
      align: 'left',
      width: '12%',
      render: (name: any, record: any) => (
        <>
          {record.BorrowerFirstName} {record.BorrowerLastName}
        </>
      ),
    },
    {
      title: 'Project',
      dataIndex: 'PropertyDescription',
      key: 'PropertyDescription',
      align: 'left',
      width: '15%',
    },
    {
      title: 'Market',
      dataIndex: 'Market',
      key: 'Market',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (Status: string) => (
        <span
          className="status"
          style={{
            background: Utils.getStatusColor(Status),
          }}
        ></span>
      ),
    },
    {
      title: 'Percent Owned',
      dataIndex: 'PercentOwned',
      key: 'PercentOwned',
    },
    {
      title: 'Interest Rate',
      dataIndex: 'SoldRate',
      key: 'SoldRate',
      render: (SoldRate: any) => Utils.percentFormat(SoldRate),
    },
    {
      title: 'Maturity Date',
      dataIndex: 'MaturityDate',
      key: 'MaturityDate',
      render: (MaturityDate: any) => Utils.dateFormat(MaturityDate),
    },
    {
      title: 'Term Left',
      dataIndex: 'RemainingTerm',
      key: 'RemainingTerm',
      render: (remainingTerm: any, record: any) =>
        record?.MaturityDate
          ? Utils.getMonthDifference(new Date(record?.MaturityDate), new Date())
          : '',
    },
    {
      title: 'Next Payment',
      dataIndex: 'NextDueDate',
      key: 'NextDueDate',
      render: (NextDueDate: any) => Utils.dateFormat(NextDueDate),
    },
    {
      title: 'Regular Payment',
      dataIndex: 'RegularPayment',
      key: 'RegularPayment',
      render: (regPayment: any) => Utils.currencyFormat(regPayment),
    },
    {
      title: 'Loan Balance',
      dataIndex: 'PrinBal',
      key: 'PrinBal',
      render: (loanBalance: any, record: any) => {
        return loanBalance
          ? Utils.currencyFormat(loanBalance)
          : Utils.currencyFormat(0)
      },
    },
    {
      title: 'Access Requests',
      dataIndex: 'Requests',
      key: 'Requests',
      render: (Requests: any, record: any) => (
        <Button
          text={`Requests (${record.NewRequests}/${Requests})`}
          isDisabled={Requests == 0}
          onClick={() => navigate(`/loans/${record.Account}/requests`)}
          isSmall
        />
      ),
    },
    {
      title: 'Investors',
      dataIndex: 'Investors',
      key: 'Investors',
      render: (Investors: any, record: any) => (
        <Button
          text={`Investors (${Investors})`}
          isDisabled={Investors == 0}
          onClick={() => navigate(`/loans/${record.Account}/investors`)}
          isSmall
        />
      ),
    },
    {
      dataIndex: 'Account',
      key: 'action',
      render: (Account: any) => (
        <span className={styles.btns}>
          <Button
            text="Edit"
            onClick={() => navigate(`/investors/project/${Account}/edit`)}
            isSmall
          />
        </span>
      ),
    },
  ]

  return (
    <>
      <h3 className={styles.title}>Loans</h3>
      <div className={styles.filter}>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Status"
          allowClear
          mode="multiple"
          optionFilterProp="children"
          onChange={(val) => onFilter('FilterStatus', val)}
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {(filterValues?.statuses || []).map((status: any) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Loans"
          allowClear
          mode="multiple"
          optionFilterProp="children"
          onChange={(val) => onFilter('FilterLoans', val)}
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {(filterValues?.loans || []).map((loan: any) => (
            <Option key={loan} value={loan}>
              {loan}
            </Option>
          ))}
        </Select>
      </div>
      <ProjectsTable
        data={loans}
        columns={columns}
        total={total}
        filters={filters}
        loading={loading}
        rowKey="Account"
        fetchData={fetchLoans}
        summary={() => (
          <ProjectSummary total={total} avg={avg}></ProjectSummary>
        )}
        expandedRowRender={(record: any) => (
          <ProjectDescription record={record}></ProjectDescription>
        )}
      />
    </>
  )
}

export default LoanPage
