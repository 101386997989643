import { FC, useState, useEffect } from 'react'
import Amplify, { API } from 'aws-amplify'
import { Slider, Typography, Table } from 'antd'
import { DualAxes } from '@ant-design/charts'
import moment from 'moment'
import cs from 'classnames'

import aws_export from '../../../aws-exports'
import styles from '../../../pages/project/ProjectPage.module.scss'

const { Paragraph } = Typography
const ProjectFredChart: FC<{
  id: string,
  showMaxMin?: boolean,
  showStandardDeviation?: boolean,
  showTable?: boolean,
}> = ({
  id,
  showMaxMin = true,
  showStandardDeviation = false,
  showTable = false,
}) => {
  const [data, setData] = useState<null | any>(null)
  const [observations, setObservations] = useState<any>([])
  const [dataRange, setDataRange] = useState<null | any>(null)

  const fetchData = async (id: string, mounted: boolean) => {
    const res = await API.get('investorsApi', `/chart/${id}`, {})
    const rangeValues = {
      rangeMin: moment(res?.observation_start).unix(),
      rangeMax: moment(res?.observation_end).unix(),
    }
    if (mounted) {
      setData({ ...res, ...rangeValues })
      setObservations(res.observations)
      setDataRange(rangeValues)
    }
  }

  useEffect(() => {
    let mounted = true

    fetchData(id, mounted)

    return () => {
      mounted = false
    }
  }, [id])

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  useEffect(() => {
    if (!observations.length) return
    let min: any, max: any
    const values: any = []
    observations.map((item: any) => {
      values.push(item.value)
      if (!max || item.value > max.value) max = item
      if (!min || item.value < min.value) min = item
    })
    const average =
      values.reduce((acc: any, curr: any) => acc + curr, 0) / values.length

    const dispersion =
      values
        .map((val: any) => (val - average) ** 2)
        .reduce((acc: any, curr: any) => acc + curr, 0) / --values.length
    const standeredDeviation = Math.sqrt(dispersion)

    setData({
      ...data,
      min,
      max,
      average: average.toFixed(),
      standeredDeviationMin: (average - standeredDeviation).toFixed(),
      standeredDeviationMax: (average + standeredDeviation).toFixed(),
    })
  }, [observations])

  const onRangeChange = (value: any) => {
    setDataRange({ rangeMin: value[0], rangeMax: value[1] })
    setObservations(
      data.observations.filter((item: any) => {
        const date = moment(item.date).unix()
        return value[0] < date && date < value[1]
      })
    )
  }

  const toDateFormat = (date: any) =>
    data?.data?.frequency === 'Quarterly'
      ? `Q${moment(date).quarter()} ${moment(date).format('YYYY')}`
      : moment(date).format('MMM YYYY')

  const config = {
    data: observations ? [observations, observations] : [],
    xField: 'date',
    yField: ['recessions', 'value'],
    legend: false,
    yAxis: {
      recessions: {
        label: false,
      },
      value: {
        tickCount: 5,
      },
    },
    annotations: {
      value: [
        showMaxMin && {
          type: 'dataMarker',
          position: data?.min || [0, 0],
          text: { content: 'min', style: { fill: 'white' } },
          point: {
            style: {
              fill: 'red',
              stroke: 'red',
              width: '20px',
              height: '20px',
            },
          },
        },
        showMaxMin && {
          type: 'dataMarker',
          position: data?.max || [0, 0],
          text: { content: 'max', style: { fill: 'white' } },
          point: {
            style: {
              fill: '#00D05C',
              stroke: '#00D05C',
              width: '20px',
              height: '20px',
            },
          },
        },

        showStandardDeviation && {
          type: 'line',
          style: {
            stroke: 'green',
            lineDash: [3, 3],
          },
          start: ['min', data?.standeredDeviationMin || 0],
          end: ['max', data?.standeredDeviationMin || 0],
          text: {
            content: data?.standeredDeviationMin,
            style: { fill: 'green' },
          },
        },
        showStandardDeviation && {
          type: 'line',
          style: {
            stroke: 'green',
            lineDash: [3, 3],
          },
          start: ['min', data?.average || 0],
          end: ['max', data?.average || 0],
          text: {
            content: 'Average ' + data?.average,
            position: 'center',
            style: { fill: 'green' },
          },
        },
        showStandardDeviation && {
          type: 'line',
          style: {
            stroke: 'green',
            lineDash: [3, 3],
          },
          start: ['min', data?.standeredDeviationMax || 0],
          end: ['max', data?.standeredDeviationMax || 0],
          text: {
            content: data?.standeredDeviationMax,
            position: 'end',
            style: { fill: 'green' },
          },
        },
      ],
    },
    tooltip: {
      customContent: (title: any, items: any) => {
        if (!items.length) return
        const index = 0
        const item = items[index]
        let { date, value, recessions } = item?.data
        return (
          <>
            <ul style={{ paddingLeft: 0 }}>
              <li
                key={`${item.title}_${index}`}
                className="g2-tooltip-list-item"
                data-index={index}
              >
                <span>
                  <span style={{ marginRight: 16 }}>{toDateFormat(date)}:</span>
                  <span className="g2-tooltip-list-item-value">{value}</span>
                </span>
              </li>
              <li
                key={`${item.title}_${index + 1}`}
                className="g2-tooltip-list-item"
                data-index={index + 1}
              >
                <span>{recessions === 1 ? 'recession' : ''}</span>
              </li>
            </ul>
          </>
        )
      },
    },
    geometryOptions: [
      {
        geometry: 'column',
        isStack: true,
        isGroup: true,
        columnWidthRatio: 1,
        columnStyle: {
          stroke: 'transparent',
          fill: '#9AA2B1',
          fillOpacity: 0.3,
        },
      },
      {
        geometry: 'line',
        lineStyle: {
          stroke: '#cea63e',
          lineWidth: 2,
        },
      },
    ],
  }

  return (
    <>
      <div
        className={cs(styles.blockItem, styles.blockItemTransparent)}
        style={{ height: '64px', padding: '0 20px', flexGrow: 1 }}
      >
        <Paragraph ellipsis={{ rows: 2 }}>
          <h3 style={{ fontSize: '14px' }}>{data?.title}</h3>
        </Paragraph>
      </div>
      <div
        className={cs(styles.content, showTable ? styles.block : '')}
        style={{ gap: 30 }}
      >
        <div style={{ minWidth: '55%' }}>
          {/* @ts-ignore: */}
          <DualAxes {...config} />
          {dataRange && (
            // @ts-ignore:
            <Slider
              className={styles.range}
              range
              trackStyle={{ backgroundColor: 'red', color: 'red', fill: 'red' }}
              handleStyle={{ borderColor: 'red' }}
              min={data?.rangeMin}
              max={data?.rangeMax}
              onChange={onRangeChange}
              defaultValue={[data?.rangeMin, data?.rangeMax]}
              tooltip={{
                formatter: (value: any) =>
                  value ? moment.unix(value).format('L') : '',
              }}
            />
          )}
        </div>
        {showTable && (
          <div style={{ minWidth: '40%' }}>
            <Table
              showHeader={false}
              pagination={false}
              dataSource={[
                {
                  name: 'Start',
                  key: 'Start',
                  value: dataRange?.rangeMin
                    ? toDateFormat(moment.unix(dataRange?.rangeMin))
                    : '',
                },
                {
                  name: 'End',
                  key: 'End',
                  value: dataRange?.rangeMax
                    ? toDateFormat(moment.unix(dataRange?.rangeMax))
                    : '',
                },
                {
                  name: 'Min',
                  key: 'Min',
                  value: data?.min
                    ? `${data?.min.value} (${toDateFormat(data?.min.date)})`
                    : '',
                },
                {
                  name: 'Max',
                  key: 'Max',
                  value: data?.max
                    ? `${data?.max.value} (${toDateFormat(data?.max.date)})`
                    : '',
                },
                { name: 'Average', key: 'Average', value: data?.average || '' },
                {
                  name: 'Standered Deviation Min',
                  key: 'Standered Deviation Min',
                  value: data?.standeredDeviationMin || '',
                },
                {
                  name: 'Standered Deviation Max',
                  key: 'Standered Deviation Max',
                  value: data?.standeredDeviationMax || '',
                },
              ]}
              columns={[
                { dataIndex: 'name', key: 'name' },
                { dataIndex: 'value', key: 'value' },
              ]}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ProjectFredChart
