import React, { useState } from 'react'
import { Button as AButton, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import cs from 'classnames'

import MainMenu from '../MainMenu'
import Logo from '../Logo'
import styles from './Header.module.scss'

const Header: React.FC = () => {
  const [visible, setVisible] = useState(false)

  const toggleDrawer = () => {
    setVisible(!visible)
  }

  return (
    <header className={styles.lotline_header}>
      <header className={cs('content', styles.lotline_header_content)}>
        <div className={styles.lotline_header_logo}>
          <Link to="/">
            <Logo className={styles.lotline_header_logo_img} />
          </Link>
        </div>
        <div className={styles.lotline_header_menu}>
          <MainMenu mode="horizontal"></MainMenu>
        </div>
        <div className={styles.lotline_header_mobile_menu}>
          <AButton
            className={styles.lotline_header_mobile_menu_btn}
            type="primary"
            icon={<MenuOutlined />}
            onClick={toggleDrawer}
          ></AButton>
        </div>
        <Drawer
          visible={visible}
          onClose={toggleDrawer}
          contentWrapperStyle={{ width: '100%' }}
        >
          <MainMenu mode="inline"></MainMenu>
        </Drawer>
      </header>
    </header>
  )
}

export default Header
