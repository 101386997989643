import { FC } from "react";
import { Form, Input, Radio } from "antd";
import rightArrow from "../../../assets/svgs/rightArrow.svg";

type FormHandlers = {
  submitFn: (values: any) => Promise<number>;
};

const MobileContactForm: FC<FormHandlers> = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  return (
    <Form
      form={form}
      onFinish={(vals) => {
        props.submitFn(vals).then((val) => {
          if (val == 0) {
            form.resetFields();
          }
        });
      }}
    >
      <span className="contact-us-heading lotline-font-header2">
        CONTACT US
      </span>
      <div className="form-row lotline-font-regular">First Name</div>
      <div className="form-row lotline-font-regular">
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "Enter your first name",
            },
          ]}
        >
          <Input
            placeholder="Enter your first name"
            className="lotline-font-regularM"
          ></Input>
        </Form.Item>
      </div>
      <div className="form-row lotline-font-regular">Last Name</div>
      <div className="form-row lotline-font-regular">
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: "Enter your last name",
            },
          ]}
        >
          <Input
            placeholder="Enter your last name"
            className="lotline-font-regularM"
          ></Input>
        </Form.Item>
      </div>
      <div className="form-row lotline-font-regular">Company Name</div>
      <div className="form-row lotline-font-regular">
        <Form.Item name="companyName">
          <Input
            placeholder="Enter your company name"
            className="lotline-font-regularM"
          ></Input>
        </Form.Item>
      </div>
      <div className="form-row lotline-font-regular">E-mail</div>
      <div className="form-row lotline-font-regular">
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "The input is not valid e-mail",
            },
          ]}
        >
          <Input
            placeholder="Enter your e-mail"
            className="lotline-font-regularM"
          ></Input>
        </Form.Item>
      </div>
      <div className="form-row lotline-font-regular">Phone number</div>
      <div className="form-row lotline-font-regular">
        <Form.Item name="phoneNumber">
          <Input
            placeholder="Enter your phone number"
            className="lotline-font-regularM"
          ></Input>
        </Form.Item>
      </div>
      <div className="form-row lotline-font-regular">
        <Form.Item name="select" initialValue="0">
          <Radio.Group name="select">
            <Radio style={radioStyle} value="0">
              <span className="lotline-font-regular">Are you builder?</span>
            </Radio>
            <Radio style={{ ...radioStyle, paddingTop: 20 }} value="1">
              <span className="lotline-font-regular">Or an investor?</span>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="form-row lotline-font-regular" style={{ paddingTop: 24 }}>
        Message to LotLine
      </div>
      <div className="form-row lotline-font-regular">
        <Form.Item
          name="message"
          rules={[
            {
              required: true,
              message: "Enter your message",
            },
          ]}
        >
          <TextArea
            rows={3}
            style={{ opacity: 0.9 }}
            placeholder="Enter your message"
            className="lotline-font-regularM"
          ></TextArea>
        </Form.Item>
      </div>
      <div className="form-row">
        <a onClick={form.submit} className="lotline-font-button">
          Submit <img src={rightArrow} width="16" height="12" />
        </a>
      </div>
    </Form>
  );
};

export default MobileContactForm;
