import { FC, useEffect } from "react";
import "./ContactUS.css";
import { message } from "antd";
import Amplify, { API } from "aws-amplify";
import aws_export from "../../../aws-exports";
import DesktopContactForm from "./DesktopContactForm";
import MobileContactForm from "./MobileContactForm";

const ContactUS: FC = () => {
  const finish = async (values: any): Promise<number> => {
    const res = await API.post("messageApi", "/message", { body: values });
    if (res.code == 0) {
      message.info("Your message was sent!");
    } else {
      message.error("Error during message save:" + res.message);
    }
    console.log(res);
    return res.code;
  };

  useEffect(() => {
    Amplify.configure(aws_export);
  }, []);
  return (
    <div className="contact-us-col">
      <div className="contact-us-container lotline-left-border lotline-right-border">
        <div className="contact-us-info">
          <span className="contact-us-heading lotline-font-header2-w">
            CONTACT US
          </span>
        </div>
        <div className="contact-us-form">
          <DesktopContactForm submitFn={finish}></DesktopContactForm>
        </div>
      </div>
      <div className="contact-us-mobile-container lotline-left-border lotline-right-border">
        <MobileContactForm submitFn={finish}></MobileContactForm>
      </div>
    </div>
  );
};
export default ContactUS;
