import { FC, useEffect, useState, useRef, useCallback } from 'react'
import { Menu, Checkbox, Form } from 'antd'
import cs from 'classnames'

import styles from './ProjectPageMenu.module.scss'

const items = [
  'Summary',
  'Loan Summary',
  'Borrower',
  'Project',
  'Comparables',
  'Surveillance',
  'Market',
  'Docs',
]

const ProjectPageMenu: FC<{ edit: boolean, showSections: string[] }> = ({
  edit = false,
  showSections = items,
}) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const [isMenuFixed, setMenuFixed] = useState(false)

  const isSticky = useCallback(() => {
    const top = menuRef?.current?.getBoundingClientRect()?.top
    setMenuFixed(top ? top < 100 : false)
  }, [menuRef])

  useEffect(() => {
    isSticky()
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  }, [])

  const scrollTo = (hash: string) => {
    const block = hash ? document.getElementById(hash) : false
    if (block) {
      window.scrollTo({ top: block.offsetTop - 150, behavior: 'smooth' })
    }
  }

  const options = items.map((item) => ({
    value: item,
    label: (
      <li
        key={item}
        className="ant-menu-item"
        onClick={scrollTo.bind(null, item)}
      >
        {item}
      </li>
    ),
  }))

  return (
    <div ref={menuRef}>
      {edit && (
        <ul
          className={cs('ant-menu ant-menu-horizontal', styles.menu, {
            [styles.fixed]: isMenuFixed,
          })}
        >
          <Form.Item name="ShowSections" className={styles.formItem}>
            <Checkbox.Group options={options} className={styles.checkboxes} />
          </Form.Item>
        </ul>
      )}
      {!edit && (
        <div className={cs(styles.menu, { [styles.fixed]: isMenuFixed })}>
          <Menu mode="horizontal">
            {showSections.map((item) => (
              <Menu.Item key={item} onClick={scrollTo.bind(null, item)}>
                {item === 'Comparables' ? 'Location' : item}
              </Menu.Item>
            ))}
          </Menu>
        </div>
      )}
    </div>
  )
}

export { items, ProjectPageMenu }
