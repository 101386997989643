import { FC, useEffect, useState, useCallback } from 'react'
import Amplify, { API } from 'aws-amplify'
import { Select, Radio, Progress, Button, message } from 'antd'
import { Link } from 'react-router-dom'

import aws_export from '../../aws-exports'
import ProjectsTable from '../../components/common/Table'
import styles from './ProjectsPage.module.scss'
import Utils from '../../utils/Utils'

const ProjectsPage: FC = () => {
  const { Option } = Select
  const pageSize = 10

  const [data, setData] = useState<null | []>(null)
  const [total, setTotal] = useState<null | number>(null)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState<any>({})

  const [filterValues, setFilterValues] = useState<any>(null)

  const fetchFilters = async (): Promise<any> => {
    const res = await API.get('investorsApi', '/filters', {
      queryStringParameters: filters,
    })
    setFilterValues({ states: res?.states })
  }

  const fetchData = useCallback(
    async (offset = 0, sorters = {}): Promise<any> => {
      setLoading(true)
      const res = await API.get('investorsApi', '/list', {
        queryStringParameters: {
          Offset: offset,
          PageSize: pageSize,
          FilterShowStatusActive: true,
          ...filters,
          OrderBy: Utils.getOrderBySorters(sorters),
        },
      }).catch((e) => message.error("Can't upload items. Try again."))

      setData(res?.data || [])
      setTotal(res?.total || 0)
      setLoading(false)
    },
    [filters]
  )

  const onFilter = (name: string, val: string) => {
    if (val) {
      setFilters({ ...filters, [name]: val })
    } else {
      const { [name]: deleted, ...others } = filters
      setFilters(others)
    }
  }

  useEffect(() => {
    fetchFilters()
  }, [filters])

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  const columns = [
    {
      title: 'Loan Account',
      dataIndex: 'Account',
      key: 'Account',
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Borrower Name',
      dataIndex: 'BorrowerName',
      key: 'BorrowerName',
      render: (name: any, record: any) => (
        <>
          {record.BorrowerFirstName} {record.BorrowerLastName}
        </>
      ),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (Status: string) => (
        <span
          className="status"
          style={{
            background: Utils.getStatusColor(Status),
          }}
        ></span>
      ),
    },
    {
      title: 'Project name',
      dataIndex: 'PropertyDescription',
      key: 'PropertyDescription',
    },
    {
      title: 'Market',
      dataIndex: 'Market',
      key: 'Market',
    },
    {
      title: 'Percent Owned',
      dataIndex: 'PercentOwned',
      key: 'PercentOwned',
    },
    {
      title: 'Interest Rate',
      dataIndex: 'SoldRate',
      key: 'SoldRate',
      render: (SoldRate: any) => Utils.percentFormat(SoldRate),
      sorter: {
        compare: () => {},
        multiple: 2,
      },
    },
    {
      title: 'Maturity Date',
      dataIndex: 'MaturityDate',
      key: 'MaturityDate',
      render: (MaturityDate: any) => Utils.dateFormat(MaturityDate),
      sorter: {
        compare: () => {},
        multiple: 3,
      },
    },
    {
      title: 'Term Left',
      dataIndex: 'RemainingTerm',
      key: 'RemainingTerm',
      render: (remainingTerm: any, record: any) =>
        record?.MaturityDate
          ? Utils.getMonthDifference(new Date(record?.MaturityDate), new Date())
          : '',
    },
    {
      title: 'Next Payment',
      dataIndex: 'NextDueDate',
      key: 'NextDueDate',
      render: (NextDueDate: any) => Utils.dateFormat(NextDueDate),
      sorter: {
        compare: () => {},
        multiple: 4,
      },
    },
    {
      title: 'Regular Payment',
      dataIndex: 'RegularPayment',
      key: 'RegularPayment',
      render: (RegularPayment: any, record: any) => {
        return RegularPayment
          ? Utils.currencyFormat(RegularPayment)
          : Utils.currencyFormat(0)
      },
      sorter: {
        compare: () => {},
        multiple: 5,
      },
    },
    {
      title: 'Loan Balance',
      dataIndex: 'OrigBal',
      key: 'OrigBal',
      // dataIndex: 'UnearnedDisc',
      render: (loanBalance: any, record: any) => {
        return loanBalance
          ? Utils.currencyFormat(loanBalance)
          : Utils.currencyFormat(0)
      },
      sorter: {
        compare: () => {},
        multiple: 6,
      },
    },
    {
      title: 'Progress',
      dataIndex: 'Progress',
      key: 'Progress',
      render: (item: any, record: any) =>
        record && record.PrinBal && record.OrigBal && record.OrigBal > 0 ? (
          <Progress
            strokeColor="#00D05C"
            strokeLinecap="square"
            strokeWidth={20}
            style={{ minWidth: 140 }}
            percent={parseInt(
              ((record.PrinBal * 100) / record.OrigBal).toFixed(2)
            )}
          />
        ) : (
          ''
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      fixed: 'right',
      render: (action: any, record: any) => (
        <div>
          <Button className={styles.buttongold}>
            <Link to={`/investors/project/${record.Account}`}>Learn More</Link>
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className={styles.title}>Investment Offerings</div>
      <div className={styles.filter}>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Location"
          allowClear
          optionFilterProp="children"
          onChange={(val) => onFilter('FilterState', val)}
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {(filterValues?.states || []).map((state: any) => (
            <Option key={state} value={state}>
              {state}
            </Option>
          ))}
        </Select>

        <Radio.Group
          defaultValue=""
          buttonStyle="solid"
          onChange={(e) => onFilter('FilterStatusDate', e.target.value)}
        >
          <Radio.Button value="">All</Radio.Button>
          <Radio.Button value="potential">Upcoming</Radio.Button>
          <Radio.Button value="current">Active</Radio.Button>
          <Radio.Button value="past">Past</Radio.Button>
        </Radio.Group>
      </div>
      <ProjectsTable
        data={data}
        columns={columns}
        total={total}
        loading={loading}
        filters={filters}
        fetchData={fetchData}
        rowKey="Account"
      />
    </>
  )
}

export default ProjectsPage
