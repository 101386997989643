/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "messageApi",
            "endpoint": "https://bo7pfg49nj.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "investorsApi",
            "endpoint": "https://zn8kb7yye9.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "newsApi",
            "endpoint": "https://lyvccvk2g6.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "notificationsApi",
            "endpoint": "https://l2rrlvaohk.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "modelshopApi",
            "endpoint": "https://3ru34izc2c.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "usersApi",
            "endpoint": "https://exw6soggf9.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:0371f2a7-25b8-43c6-8b2e-ef847cf1f5d8",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_c2kXBKzgx",
    "aws_user_pools_web_client_id": "1eauiq7jffob9vsc5s56pcdv3j",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lotlinebackendba2b6cc9689147d0b4091f4d650240d2135504-prod",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-2",
            "maps": {
                "items": {
                    "dashboardMap-prod": {
                        "style": "VectorEsriDarkGrayCanvas"
                    }
                },
                "default": "dashboardMap-prod"
            },
            "search_indices": {
                "items": [
                    "locationSearchIndex-prod"
                ],
                "default": "locationSearchIndex-prod"
            }
        }
    }
};


export default awsmobile;
