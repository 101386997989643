import {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react'
import { MapView } from '@aws-amplify/ui-react'
import {
  ViewState,
  MapLayerMouseEvent,
  MapboxEvent,
  NavigationControl,
} from 'react-map-gl'
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import mapboxgl from 'mapbox-gl'

import PolygonsFeature from './components/PolygonsFeature'
import { MarkerWithPopup, Marker } from './components/MarkerWithPopup'
import { GeoData } from './components/TestGeoData'
import styles from './Map.module.scss'

const INITIAL_VIEW = {
  latitude: 35.79219676010834,
  longitude: -78.64288330078125,
  zoom: 8,
}

const Map: FC<{ title?: string, data: null | any[] }> = ({ title, data }) => {
  const [map, setMap] = useState<any>(null)
  const [markers, setMarkers] = useState<Marker[]>([])
  const [poligons, setPoligons] = useState([])
  const [viewState, setViewState] = useState<Partial<ViewState>>(INITIAL_VIEW)
  const [fullStateMode, setFullStateMode] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const mapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (data && data.length) {
      setMarkers(
        data
          .filter((i) => i.longitude && i.latitude)
          .map((i) => ({
            address: `${i.ZipCode} ${i.County} ${i.State} ${i.City} ${i.Street}`,
            longitude: i.longitude,
            latitude: i.latitude,
          }))
      )
      setPoligons(GeoData)
    }
  }, [data])

  const goToMarkers = useCallback(() => {
    if (markers.length) {
      setViewState({
        latitude: markers[0].latitude,
        longitude: markers[0].longitude,
        zoom: 8,
      })
    }
  }, [markers])

  useEffect(() => {
    if (markers.length) {
      setViewState({
        latitude: markers[0].latitude,
        longitude: markers[0].longitude,
        zoom: 8,
      })
    }
  }, [markers])

  const handleMapClick = async (event: MapLayerMouseEvent) => {
    const { point, lngLat } = event
    const features = map.queryRenderedFeatures(point)

    try {
      features.map((item: any) => {
        if (item?.properties?.title) {
          new mapboxgl.Popup()
            .setLngLat(lngLat)
            .setText(item.properties.title)
            .addTo(map)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleMapLoad = (e: MapboxEvent) => setMap(e.target)

  const fullScreenToggle = () => {
    if (mapRef.current) {
      if (!fullStateMode) {
        setScrollY(window.scrollY)
        mapRef.current
          .requestFullscreen()
          .then(() => {
            setFullStateMode(true)
          })
          .catch()
      } else {
        document.exitFullscreen().then(() => {
          setFullStateMode(false)
          window.scrollTo({ top: scrollY })
        })
      }
    }
  }

  useLayoutEffect(() => {
    document.onfullscreenchange = (event) => {
      setFullStateMode(document.fullscreenElement !== null)
      if (!document.fullscreenElement) window.scrollTo({ top: scrollY })
    }
    return () => {
      document.onfullscreenchange = null
    }
  })

  return (
    <div className={fullStateMode ? styles.mapfs : styles.map} ref={mapRef}>
      <div className={styles.mapContent}>
        <div className={styles.mapContentHeader}>
          {title && <div className={styles.blockTitle}>{title}</div>}
          <div className={styles.fullScreen}>
            <ReloadOutlined onClick={goToMarkers} />
            {!fullStateMode && (
              <FullscreenOutlined onClick={fullScreenToggle} />
            )}
            {fullStateMode && (
              <FullscreenExitOutlined onClick={fullScreenToggle} />
            )}
          </div>
        </div>
      </div>

      {data && (
        <>
          <MapView
            {...viewState}
            onMove={(e) => setViewState(e.viewState)}
            onClick={handleMapClick}
            onLoad={handleMapLoad}
            scrollZoom={false}
          >
            <NavigationControl position="bottom-left" showCompass={false} />
            {markers.length &&
              markers.map((marker: any, i) => (
                <MarkerWithPopup
                  key={i}
                  latitude={marker.latitude}
                  longitude={marker.longitude}
                  address={marker.address}
                />
              ))}
            <PolygonsFeature data={poligons} />
          </MapView>
        </>
      )}
    </div>
  )
}

export default Map
