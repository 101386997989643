import { FC } from 'react'
import cs from 'classnames'
import styles from './Card.module.scss'

type CardProps = {
  mainTitle?: string,
  mainText?: string,
  title?: string,
  text?: string,
  data?: any,
}

const Card: FC<CardProps> = ({
  mainTitle,
  mainText,
  title,
  text,
  data = {},
}) => {
  return (
    <div className={cs(styles.card, mainTitle ? styles.cardBig : '')}>
      <div className={styles.mainTitle}>{mainTitle}</div>
      <div className={styles.mainText}>{mainText}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
      <div className={styles.data}>
        {Object.keys(data).map((name, i) => (
          <div key={i} className={styles.dataRow}>
            <span className={styles.dataName}>{name}</span>
            <span className={styles.dataValue}>{data[name]}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Card
