import { FC, useMemo, useContext } from 'react'
import { Table } from 'antd'
import Utils from '../../../utils/Utils'
import { DataContext } from '../../../pages/project/ProjectPage'

const ProjectPageSummary: FC = () => {
  const { data } = useContext(DataContext)

  const yeldInfo = useMemo(
    () => [
      {
        key: 'CouponRate',
        name: 'Coupon Rate:',
        value: Utils.percentFormat(data?.CouponRate),
      },
      {
        key: 'ServicingFee',
        name: 'Servicing Fee:',
        value: Utils.percentFormat(data?.ServicingFee),
      },
      {
        key: 'NetInvestorYield',
        name: 'Net Investor Yield:',
        value: Utils.percentFormat(data?.NetInvestorYield),
      },
    ],
    [data]
  )

  const overview = useMemo(
    () => [
      {
        key: 'LienType',
        name: 'Lien Type:',
        value: data?.LienType,
      },
      {
        key: 'RepaymentType',
        name: 'Repayment Type:',
        value: data?.RepaymentType,
      },
      {
        key: 'InterestReserve',
        name: 'Interest Reserve:',
        value: Utils.currencyFormat(data?.InterestReserve),
      },
      {
        key: 'Originated',
        name: 'Orination Date:',
        value: Utils.dateFormat(data?.Originated),
      },
      {
        key: 'MaturityDate',
        name: 'Maturity Date:',
        value: Utils.dateFormat(data?.MaturityDate),
      },
    ],
    [data]
  )
  const strategy = useMemo(
    () => [
      {
        key: 'LoanPurpose',
        name: 'Loan Purpose:',
        value: data?.LoanPurpose,
      },
      {
        key: 'Repayment',
        name: 'Repayment:',
        value: data?.Repayment,
      },
      {
        key: 'PrimaryCreditAnalysis',
        name: 'Primary Credit Analysis:',
        value: data?.PrimaryCreditAnalysis,
      },
    ],
    [data]
  )

  const creditInfo = useMemo(
    () => [
      {
        key: 'Lots',
        name: 'Lots:',
        value: data?.Lots,
        value2: 'Per Lot',
      },
      {
        key: 'AsCompletedValue',
        name: 'As Completed Value:',
        value: Utils.currencyFormat(data?.AsCompletedValue),
        value2: Utils.currencyFormat(data?.AsCompletedValuePerLot),
      },
      {
        key: 'MaximumLoanAmount',
        name: 'Maximum Loan Amount:',
        value: Utils.currencyFormat(data?.MaximumLoanAmount),
        value2: Utils.currencyFormat(data?.MaximumLoanAmountPerLot),
      },
      {
        key: 'AsCompletedLTV',
        name: 'As Completed LTV:',
        value: Utils.percentFormat(data?.AsCompletedLTV),
        value2: '',
      },
      {
        key: 'Term',
        name: 'Term (Months):',
        value: Utils.getMonthDifference(
          new Date(data.MaturityDate),
          new Date()
        ),
        value2: '',
      },
      {
        key: 'PrepaymentPenalty',
        name: 'Prepayment Penalty:',
        value: data?.PrepaymentPenalty,
        value2: '',
      },
      {
        key: 'ExpectedRepaymentDate',
        name: 'Expected Repayment Date (Months):',
        value: data?.ExpectedRepaymentDate,
        value2: '',
      },
      {
        key: 'ExtensionOption',
        name: 'Extension Option:',
        value: data?.ExtensionOption,
        value2: '',
      },
    ],
    [data]
  )

  return (
    <>
      <Table
        showHeader={false}
        pagination={false}
        dataSource={yeldInfo}
        title={() => 'Yield Information'}
        columns={[
          { dataIndex: 'name', key: 'name', width: '50%' },
          { dataIndex: 'value', key: 'value' },
        ]}
      />
      <Table
        showHeader={false}
        pagination={false}
        dataSource={overview}
        title={() => 'Overview'}
        columns={[
          { dataIndex: 'name', key: 'name', width: '50%' },
          { dataIndex: 'value', key: 'value' },
        ]}
      />
      <Table
        showHeader={false}
        pagination={false}
        dataSource={strategy}
        title={() => 'Strategy'}
        columns={[
          { dataIndex: 'name', key: 'name', width: '50%' },
          { dataIndex: 'value', key: 'value' },
        ]}
      />
      <Table
        showHeader={false}
        pagination={false}
        dataSource={creditInfo}
        title={() => 'Credit Information'}
        columns={[
          { dataIndex: 'name', key: 'name', width: '50%' },
          { dataIndex: 'value', key: 'value' },
          { dataIndex: 'value2', key: 'value2' },
        ]}
      />
    </>
  )
}

export default ProjectPageSummary
