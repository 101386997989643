import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import Amplify, { API } from 'aws-amplify'
import { message } from 'antd'
import cs from 'classnames'

import Card from '../../components/common/Card'
import PortfolioChart from '../../components/dashboard/PortfolioChart'
import Map from '../../components/common/Map'
import NotificationsWidget from '../../components/common/NotificationsWidget'
import Market from '../../components/dashboard/Market'
import NewsWidget from '../../components/common/NewsWidget'
import Cards from '../../components/dashboard/Cards'
import StatusesTable from '../../components/dashboard/StatusesTable'
import styles from './DashboardPage.module.scss'
import aws_export from '../../aws-exports'
import Utils from 'utils/Utils'

const DashboardPage: FC = () => {
  const { user } = useAuthenticator((context) => [context.user])
  const navigate = useNavigate()
  const [portfolio, setPortfolio] = useState<any>(null)
  const [properties, setProperties] = useState(null)

  const isAdmin = user ? Utils.isAdmin(user) : false

  const fetchProperties = async (): Promise<any> => {
    const res = await API.get('investorsApi', '/properties', {}).catch((e) =>
      message.error("Can't upload properties. Try again.")
    )
    setProperties(res || [])
  }

  const fetchPortfolio = async (): Promise<any> => {
    const res = await API.get('investorsApi', '/portfolio', {}).catch((e) =>
      message.error("Can't upload portfolio. Try again.")
    )
    setPortfolio(res?.data || {})
  }

  useEffect(() => {
    if (isAdmin) {
      navigate('/admin-dashboard')
      return
    }

    Amplify.configure(aws_export)
    fetchProperties()
    fetchPortfolio()
  }, [])

  return (
    <div className={styles.dashboardPage}>
      <div className={styles.content}>
        <div className={styles.leftBlock}>
          <div className={cs(styles.notifications, 'notifications')}>
            <NotificationsWidget />
          </div>
          <Cards data={portfolio} />
          <StatusesTable data={portfolio} />
          <Card
            data={{
              'Total Loans': portfolio?.DebtMarginLoansTotal,
              'Average Debt Margin (Current)':
                portfolio?.DebtMarginLoansAverage,
              'Debt Margin Flag': portfolio?.DebtMarginLoansFlag,
              'Loans Below Flag': portfolio?.DebtMarginLoansBelowFlag,
            }}
            title={'Debt Margin Loans'}
          />
          <Card
            data={{
              'Total Loans': portfolio?.ContractDepositLoansTotal,
              'Market Decline Flag': portfolio?.ContractDepositLoansFlag,
              'Loans Below Flag': portfolio?.ContractDepositLoansBelowFlag,
            }}
            title={'Contract Deposit Loans'}
          />
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.chart}>
            <div className={styles.blockTitle}>Portfolio Value Over Time</div>
            <PortfolioChart data={portfolio?.ChartData} />
          </div>

          <Map data={properties} title="Investment Footprint" />

          <div className={styles.market}>
            <div className={styles.blockTitle}>Market Data</div>
            <Market />
          </div>
          <NewsWidget />
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
