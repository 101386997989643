import { FC, useRef, useEffect } from 'react'
import { Form, Input, message, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { API } from 'aws-amplify'
import JoditEditor from 'jodit-react'

import Button from '../../components/common/Button'
import styles from './MessagesPage.module.scss'

const { Option } = Select

type MessagesFormProps = {
  users: [],
  initialValues: {},
  onBack: () => void,
  fromUser: {},
  isUserDisabled: boolean,
}

const MessagesForm: FC<MessagesFormProps> = ({
  users,
  initialValues,
  onBack,
  fromUser,
  isUserDisabled = false,
}) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const editor = useRef(null)
  const config = {
    readonly: false,
    height: 400,
    theme: 'dark',
    toolbar: true,
    statusbar: false,
    buttons: [
      'bold',
      'strikethrough',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'fontsize',
      'paragraph',
      '|',
      'link',
      'align',
      '|',
    ],
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const submitFn = async (values: any) => {
    const toUser: any = users.filter(
      (user: any) => user.id === values.toUsername
    )[0]
    const body = {
      ...values,
      to: `${toUser?.given_name} ${toUser?.family_name || ''}`,
      toEmail: toUser?.email,
      ...fromUser,
    }

    const res = await API.post('messageApi', '/item/object', { body })

    if (res?.code == 0) {
      message.info('Your message was sent!')
      navigate('/messages/sent')
    } else {
      message.error('Error during message save:' + res.message)
    }
  }

  return (
    <>
      <h3 className={styles.title}>Send message</h3>

      <Form className={styles.form} form={form} onFinish={submitFn}>
        <Form.Item
          name="toUsername"
          label="To"
          rules={[
            {
              required: true,
              message: 'Enter Email',
            },
          ]}
        >
          <Select
            disabled={isUserDisabled}
            showSearch
            allowClear
            optionFilterProp="children"
          >
            {users.map((user: any) => (
              <Option key={user.id} value={user.id}>
                {user.given_name} {user.family_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="cc"
          label="Cc"
          rules={[
            {
              type: 'email',
              message: 'Enter valid E-mail',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
              message: 'Enter Subject',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="priority" label="Priority">
          <Select allowClear={false} optionFilterProp="children">
            {['low', 'medium', 'high'].map((val: any) => (
              <Option key={val} value={val}>
                {val}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="message"
          label="Message"
          rules={[
            {
              required: true,
              message: 'Enter message',
            },
          ]}
        >
          {/* @ts-ignore: */}
          <JoditEditor ref={editor} value={''} config={config} />
        </Form.Item>
        <div className={styles.buttons}>
          <Button text="Send" onClick={form.submit} />
          <Button text="Cancel" onClick={onBack} />
        </div>
      </Form>
    </>
  )
}

export default MessagesForm
