import { FC, useEffect, useState } from 'react'
import { Form, Input, message, Select } from 'antd'
import Amplify, { API } from 'aws-amplify'
import aws_export from '../../aws-exports'

import Button from '../../components/common/Button'
import styles from './NotificationsPage.module.scss'

const { TextArea } = Input
const { Option } = Select

const NotificationsFormPage: FC = () => {
  const [form] = Form.useForm()
  const [to, setTo] = useState<string | null>(null)
  const [groups, setGroups] = useState([])
  const [users, setUsers] = useState([])

  const submitFn = async (values: any): Promise<number> => {
    const res = await API.post('notificationsApi', '/item/object', {
      body: values,
    })
    if (res.code == 0) {
      message.info('Your message was sent!')
    } else {
      message.error('Error during message save:' + res.message)
    }

    return res.code
  }

  const fetchOptions = async (): Promise<any> => {
    const res = await API.get('usersApi', '/users', {})
    setGroups(res.groups)
    setUsers(res.users)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchOptions()
  }, [])

  return (
    <>
      <h3 className={styles.title}>Send Notification</h3>
      <Form
        className={styles.form}
        form={form}
        onValuesChange={(vals) => {
          let res = null
          Object.keys(vals).map((i) => {
            if (['group', 'user'].includes(i) && vals[i]) res = i
          })
          setTo(res)
        }}
        onFinish={(vals) => {
          submitFn(vals).then((val) => {
            if (val == 0) {
              form.resetFields()
            }
          })
        }}
      >
        {groups.length > 0 && (
          <Form.Item
            name="group"
            dependencies={['user']}
            rules={[
              ({ getFieldValue }) => ({
                required:
                  getFieldValue('user') && getFieldValue('user').length > 0
                    ? false
                    : true,
                message: 'Choose user or group',
              }),
            ]}
          >
            <Select
              showSearch
              disabled={!!(to && to === 'user')}
              placeholder="Group"
              allowClear
              optionFilterProp="children"
            >
              {groups.map((group: any) => (
                <Option key={group} value={group}>
                  {group}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {users.length > 0 && (
          <Form.Item
            name="user"
            dependencies={['group']}
            rules={[
              ({ getFieldValue }) => ({
                required:
                  getFieldValue('group') && getFieldValue('group').length > 0
                    ? false
                    : true,
                message: 'Choose user or group',
              }),
            ]}
          >
            <Select
              disabled={!!(to && to === 'group')}
              showSearch
              placeholder="Users"
              allowClear
              optionFilterProp="children"
            >
              {users.map((user: any) => (
                <Option key={user.id} value={user.id}>
                  {user.email}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="message"
          rules={[
            {
              required: true,
              message: 'Enter message',
            },
          ]}
        >
          <TextArea placeholder="Enter message"></TextArea>
        </Form.Item>
        <Button text="Send" onClick={form.submit} />
      </Form>
    </>
  )
}

export default NotificationsFormPage
