import { FC, useEffect, useState, createContext } from 'react'
import Amplify, { API, Storage } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Progress,
  Badge,
  Tooltip,
  Table,
  Breadcrumb,
  message,
  Form,
  Collapse,
  Select,
  Input,
  Switch,
} from 'antd'
import {
  InfoOutlined,
  MessageOutlined,
  PaperClipOutlined,
  CarryOutOutlined,
  FileTextOutlined,
} from '@ant-design/icons'
import cs from 'classnames'

import aws_export from '../../aws-exports'
import Utils from '../../utils/Utils'
import Map from '../../components/common/Map'
import Button from '../../components/common/Button'
import { items, ProjectPageMenu } from '../../components/project/Menu'
import ProjectPageDescription from '../../components/project/Description'
import ProjectPageCarousel from '../../components/project/Carousel'
import ProjectPageSummary from '../../components/project/Summary'
import ProjectChart from '../../components/project/Chart'
import ProjectFredChart from '../../components/project/FredChart'

import styles from './ProjectPage.module.scss'

export const DataContext = createContext<any>({})

const { Panel } = Collapse
const { Option } = Select

const ProjectPage: FC = () => {
  const navigate = useNavigate()
  const { user } = useAuthenticator((context) => [context.user])
  const [form] = Form.useForm()
  const { id, edit } = useParams()
  const [data, setData] = useState<null | any>(null)
  const [dicts, setDicts] = useState<null | any>(null)
  const [showSections, setShowSections] = useState<any[]>([])
  const [preview, setPreview] = useState(false)

  const isAdmin = user ? Utils.isAdmin(user) : false

  const fetchData = async (): Promise<any> => {
    const res = await API.get('investorsApi', '/item/' + id, {}).catch((e) =>
      message.error("Can't upload item. Try again.")
    )
    res.ChartIds = JSON.parse(res?.ChartIds || '[]')
    setData(res || {})
    setShowSections(res?.ShowSections ? JSON.parse(res.ShowSections) : items)

    const dicts = await API.get('investorsApi', '/loans/dicts', {}).catch((e) =>
      message.error("Can't upload item. Try again.")
    )
    dicts.tags = Utils.getDictByKey(dicts?.tags, 'id')
    setDicts(dicts)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchData()
  }, [])

  const totalReserved =
    data?.PrinBal && data?.OrigBal && data?.OrigBal > 0
      ? parseInt(((data.PrinBal * 100) / data.OrigBal).toFixed(2))
      : 0

  const secondaryAvailablePecent =
    data?.SecondaryParticipationAvailable && data?.OrigBal && data?.OrigBal > 0
      ? parseInt(
          ((data.SecondaryParticipationAvailable * 100) / data.OrigBal).toFixed(
            2
          )
        )
      : 0

  const drawRequestsFieldMap: any = {
    ProjectCompletion: 'Project Completion: (Required)',
    Amount: 'Amount: (Required)',
    CreatedOn: 'Created on:',
    CreatedBy: 'Created by:',
    InspectionFee: 'Inspection fee:',
    InspectionDate: 'Inspection date:',
    RequestedOn: 'Requested on:',
    ProcessedOn: 'Processed on:',
    WireFee: 'Wire fee:',
    Total: 'Total amount to borrower:',
  }

  const submitFn = async (values: any): Promise<any> => {
    values.ShowSections = JSON.stringify(values?.ShowSections || [])
    values.ChartIds = JSON.stringify(values?.ChartIds || [])
    values.ShowStandardDeviation = +values?.ShowStandardDeviation
    values.ShowMaxMin = +values?.ShowMaxMin

    if (values.properties)
      Object.keys(values.properties).map((key: any) => {
        values.properties[key].Images = values.properties[key].Images.map(
          (item: any) => item.uid
        )
      })

    await API.put('investorsApi', '/item/' + id, { body: values })
    if (values.properties) {
      await Promise.all(
        Object.keys(values.properties).map(async (id: any) => {
          const res = await Storage.list(id + '/')

          res.map(async (img: any) => {
            if (!values.properties[id]?.Images.includes(img.key)) {
              console.log('delete! ' + img.key)
              await Storage.remove(img.key)
            }
          })
        })
      )
    }
    navigate('/investors/project/' + id)
  }

  const onChange = (values: any) => {
    if (values?.ShowSections) setShowSections(values?.ShowSections)
  }

  const onPreview = () => {
    setData({ ...data, ...form.getFieldsValue() })
    setPreview(!preview)
  }

  const requestAccess = async () => {
    await API.put('investorsApi', '/request', {
      body: { LoanAccount: id },
    })
      .then(async () => {
        message.success('Request was sent!')
        setData({ ...data, RequestAccessStatus: 'new' })
        const body = {
          group: 'ADMIN',
          message: `${user?.attributes?.email} sent access request to loan ${id}`,
        }
        await API.post('notificationsApi', '/item/object', { body }).catch(() =>
          message.error("Can't update status. Try again.")
        )
      })
      .catch(() => message.error("Can't send request. Try again."))
  }

  useEffect(() => {
    if (edit)
      form.setFieldsValue({
        description: data?.description,
        ShowSections: showSections,
        Status: data?.Status,
        SecondaryParticipationAvailable: data?.SecondaryParticipationAvailable,
        ChartIds: data?.ChartIds,
        ShowStandardDeviation: !!data?.ShowStandardDeviation,
        ShowMaxMin: !!data?.ShowMaxMin,
      })
  }, [form, data, showSections])

  return (
    <DataContext.Provider value={{ form, data, dicts, setDicts }}>
      <div className="breadcrumbWrapper">
        <div className="breadcrumbContent">
          <Breadcrumb>
            <Breadcrumb.Item>
              <a onClick={() => (edit ? navigate('/loans') : navigate(-1))}>
                Back To All
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {edit ? 'Loan Edit Page' : 'Loan Details Page'}
            </Breadcrumb.Item>
          </Breadcrumb>
          {edit && (
            <span className={styles.saveBtn}>
              <Button
                text={preview ? 'Edit' : 'Preview'}
                onClick={onPreview}
                isActive
              />
              {!preview && (
                <Button text="Save" onClick={form.submit} isActive />
              )}
            </span>
          )}
          {isAdmin && !edit && (
            <span className={styles.saveBtn}>
              <Button
                text={'Edit'}
                isActive
                onClick={() => navigate(`/investors/project/${id}/edit`)}
              />
            </span>
          )}
        </div>
      </div>
      <div className={styles.projectPage}>
        <Form
          layout="vertical"
          form={form}
          onValuesChange={onChange}
          onFinish={submitFn}
          className={styles.form}
        >
          {!!edit && !preview && (
            <Form.Item
              name="Status"
              key="Status"
              label="Status"
              className={styles.statuses}
            >
              <Select
                showSearch
                placeholder="Status"
                allowClear
                optionFilterProp="children"
              >
                {Utils.statuses.map((status: any, i: any) => (
                  <Option key={status + i} value={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <div className={styles.project}>
            <div className={styles.project_info}>
              <ProjectPageCarousel
                form={form}
                edit={!!edit}
                preview={preview}
              />
            </div>
            <div className={styles.project_details}>
              <div className={styles.numbers} style={{ marginBottom: 30 }}>
                {Utils.currencyFormat(data?.OrigBal || 0)}
                <div className={styles.numbers_dscr}>Total Loan</div>
              </div>
              <div className={styles.numbers}>
                {Utils.currencyFormat(data?.PrinBal || 0)}
                <div className={styles.numbers_dscr}>
                  Total Reserved
                  <Tooltip title={totalReserved + '%'}>
                    <Badge
                      count={
                        <InfoOutlined
                          style={{
                            color: '#CEA63E',
                            border: '1px solid #CEA63E',
                            borderRadius: '50%',
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </div>
              </div>
              <Progress
                className={styles.progress}
                percent={totalReserved}
                showInfo={false}
                strokeColor="#CEA63E"
                strokeLinecap="square"
              />
              <div className={styles.numbers}>
                {!!edit && !preview && (
                  <Form.Item
                    name="SecondaryParticipationAvailable"
                    key="SecondaryParticipationAvailable"
                  >
                    <Input type="number" />
                  </Form.Item>
                )}
                {(!edit || preview) &&
                  Utils.currencyFormat(data?.SecondaryParticipationAvailable)}
                <div className={styles.numbers_dscr}>
                  Secondary Participation Available
                  <Tooltip title={secondaryAvailablePecent + '%'}>
                    <Badge
                      count={
                        <InfoOutlined
                          style={{
                            color: '#CEA63E',
                            border: '1px solid #CEA63E',
                            borderRadius: '50%',
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </div>
              </div>
              <Progress
                className={styles.progress}
                percent={secondaryAvailablePecent}
                showInfo={false}
                strokeColor="#CEA63E"
                strokeLinecap="square"
              />

              {data?.RequestAccessStatus &&
                data?.RequestAccessStatus !== 'approved' && (
                  <Button text="Access Requested" isFullWidth isDisabled />
                )}
              {data?.RequestAccessStatus &&
                data?.RequestAccessStatus === 'approved' && (
                  <Button text="PROJECT DETAILS" isFullWidth isActive />
                )}
              {!data?.RequestAccessStatus && (
                <Button
                  text="Request access"
                  onClick={requestAccess}
                  isFullWidth
                />
              )}
            </div>
          </div>

          <ProjectPageMenu
            edit={!!edit && !preview}
            showSections={showSections}
          />

          {showSections.includes('Summary') && (
            <ProjectPageDescription edit={!!edit && !preview} />
          )}

          <div className={styles.block}>
            {showSections.includes('Loan Summary') && (
              <Collapse
                className={styles.blockCol}
                defaultActiveKey={['1']}
                ghost
              >
                <Panel
                  className={styles.blockItem}
                  header={<h3>Loan Summary</h3>}
                  key="1"
                >
                  <div id="Loan Summary">
                    <ProjectPageSummary />
                  </div>
                </Panel>
              </Collapse>
            )}
            {(showSections.includes('Comparables') ||
              showSections.includes('Surveillance')) && (
              <div className={styles.blockCol}>
                {showSections.includes('Comparables') && (
                  <div
                    className={cs(styles.blockItem, styles.map)}
                    id="Comparables"
                  >
                    <Map
                      data={data?.properties}
                      title={`Location${
                        data?.properties.length > 1 ? 's' : ''
                      }`}
                    />
                  </div>
                )}
                {showSections.includes('Surveillance') && (
                  <div
                    className={cs(styles.chart, styles.blockItem)}
                    id="Surveillance"
                  >
                    <Collapse defaultActiveKey={['1']} ghost>
                      <Panel
                        header={<h3>Property Value Composition</h3>}
                        key="1"
                      >
                        <div>
                          <div className={styles.content}>
                            <ProjectChart edit={!!edit && !preview} />
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.block}>
            {showSections.includes('Project') && (
              <div className={styles.blockCol}>
                <div className={cs(styles.blockItem)} id="Project">
                  <div className={styles.content}>
                    <h3>Funding Process</h3>
                  </div>
                  <div className={cs(styles.content, styles.flex)}>
                    <Progress
                      percent={totalReserved}
                      className={cs(styles.fundingProcess, 'fundingProcess')}
                      strokeColor={'#16C95B'}
                      strokeLinecap="square"
                      strokeWidth={3}
                      trailColor={'#67738B'}
                      steps={5}
                    />
                    <div className={styles.card}>
                      <span>
                        {Utils.currencyFormat(
                          data?.OrigBal - data?.PrinBal || 0
                        )}
                      </span>
                      <p>Reserve Remaining</p>
                    </div>
                    <div className={styles.card}>
                      <span>{totalReserved}%</span> <p>Project Completion</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showSections.includes('Borrower') && (
              <div className={styles.blockCol}>
                <div className={cs(styles.blockItem)} id="Borrower">
                  <div className={styles.content}>
                    <h3>Borrower Info</h3>
                  </div>
                  <div className={cs(styles.content, styles.flex)}>
                    <div className={styles.card}>
                      <span>
                        {data?.BorrowerFirstName} {data?.BorrowerLastName}
                      </span>
                      <p>Borrower</p>
                    </div>
                    <div className={styles.card}>
                      <span>
                        <CarryOutOutlined
                          style={{ fontSize: '17px', color: '#CEA63E' }}
                        />{' '}
                        On time
                      </span>
                      <p>Payment Bucket</p>
                    </div>
                    <div className={styles.card}>
                      <span>
                        <CarryOutOutlined
                          style={{ fontSize: '17px', color: '#CEA63E' }}
                        />{' '}
                        Current
                      </span>{' '}
                      <p>{Utils.currencyFormat(data?.BorrowerCurrent)}</p>
                    </div>
                    <div className={styles.card}>
                      <span>
                        <FileTextOutlined
                          style={{ fontSize: '17px', color: '#CEA63E' }}
                        />
                      </span>
                      <p>Wire Instructions</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {showSections.includes('Market') &&
            (data.ChartIds.length > 0 || (edit && !preview)) && (
              <div id="Market">
                <Collapse
                  defaultActiveKey={['1']}
                  ghost
                  className={cs(styles.block, styles.blockItem)}
                  style={{ display: 'block' }}
                >
                  <Panel header={<h3>Market</h3>} key="1">
                    {!!edit && !preview && (
                      <div className={styles.content}>
                        <Form.Item name="ChartIds" key="ChartIds">
                          <Select
                            mode="tags"
                            placeholder="Chart Ids"
                            allowClear
                            optionFilterProp="children"
                            onChange={(val: any) =>
                              setData({ ...data, ChartIds: val })
                            }
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          name="ShowStandardDeviation"
                          key="ShowStandardDeviation"
                          label="Show standard deviation"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                        <Form.Item
                          name="ShowMaxMin"
                          key="ShowMaxMin"
                          label="Show max&min"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      </div>
                    )}
                    <div
                      className={styles.block}
                      style={{
                        flexWrap:
                          data?.ChartIds?.length > 2 ? 'wrap' : 'nowrap',
                        gap: '20px',
                      }}
                    >
                      {data?.ChartIds &&
                        data.ChartIds.map((id: string) => (
                          <div className={styles.blockCol} key={id}>
                            <ProjectFredChart
                              id={id}
                              showMaxMin={!!data?.ShowMaxMin}
                              showStandardDeviation={
                                !!data?.ShowStandardDeviation
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </Panel>
                </Collapse>
              </div>
            )}
        </Form>
        {showSections.includes('Docs') && (
          <div className={styles.block} id="Docs">
            {data?.drawRequests &&
              data?.drawRequests.map((item: any, i: any) => (
                <div
                  className={cs(styles.blockCol, styles.blockCol30)}
                  key={`drawRequests${i}`}
                >
                  <div className={cs(styles.blockItem)} id="Docs">
                    <div className={cs(styles.content, styles.flex)}>
                      <h3>Draw Request</h3>
                      <span className={styles.tags_item}>processed</span>
                    </div>
                    <Table
                      showHeader={false}
                      pagination={false}
                      dataSource={Object.keys(item).map((key) => ({
                        key,
                        name: drawRequestsFieldMap[key] || '',
                        value: item[key],
                      }))}
                      columns={[
                        { dataIndex: 'name', key: 'name' },
                        { dataIndex: 'value', key: 'value' },
                      ]}
                    />
                    <div className={cs(styles.content, styles.flex)}>
                      <PaperClipOutlined style={{ fontSize: '17px' }} />
                      <MessageOutlined style={{ fontSize: '17px' }} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </DataContext.Provider>
  )
}

export default ProjectPage
