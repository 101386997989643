import { FC, useEffect, useState, useCallback } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Link } from 'react-router-dom'
import { message, Button, Table } from 'antd'

import aws_export from '../../aws-exports'
import Utils from '../../utils/Utils'
import ProjectsTable from '../../components/common/Table'
import ProjectDescription from '../../components/investors/ProjectDescription'
import ProjectSummary from '../../components/investors/ProjectSummary'
import styles from './InvestorsPage.module.scss'

const InvestorsPage: FC = () => {
  const pageSize = 10
  const { user } = useAuthenticator((context) => [context.user])
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<null | []>(null)
  const [total, setTotal] = useState<null | number>(null)
  const [avg, setAvg] = useState<{
    soldRate: number,
    regPayment: number,
    prinBalance: number,
  }>({
    soldRate: 0,
    regPayment: 0,
    prinBalance: 0,
  })

  const fetchData = useCallback(
    async (offset = 0, sorters = {}): Promise<any> => {
      setLoading(true)

      const res = await API.get('investorsApi', '/list', {
        queryStringParameters: {
          Offset: offset,
          PageSize: pageSize,
          FilterUser: user.username,
          FilterShowStatusActive: true,
          OrderBy: Utils.getOrderBySorters(sorters),
        },
      }).catch((e) => message.error("Can't upload items. Try again."))

      setTotal(res?.total || 0)
      setAvg(res?.avg || {})
      setData(res?.data || [])
      setLoading(false)
    },
    [user]
  )

  useEffect(() => {
    console.log(user?.username)
    Amplify.configure(aws_export)
    fetchData(0, {})
  }, [])

  const columns = [
    {
      title: 'Loan Account',
      dataIndex: 'Account',
      key: 'Account',
      width: '5%',
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Borrower Name',
      dataIndex: 'BorrowerName',
      key: 'BorrowerName',
      width: '12%',
      render: (name: any, record: any) => (
        <>
          {record.BorrowerFirstName} {record.BorrowerLastName}
        </>
      ),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Project',
      dataIndex: 'PropertyDescription',
      key: 'PropertyDescription',
      width: '12%',
    },
    {
      title: 'Market',
      dataIndex: 'Market',
      key: 'Market',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      render: (Status: string) => (
        <span
          className="status"
          style={{
            background: Utils.getStatusColor(Status),
          }}
        ></span>
      ),
    },
    {
      title: 'Percent Owned',
      dataIndex: 'PercentOwned',
      key: 'PercentOwned',
    },
    {
      title: 'Interest Rate',
      dataIndex: 'SoldRate',
      key: 'SoldRate',
      render: (SoldRate: any) => Utils.percentFormat(SoldRate),
      sorter: {
        compare: () => {},
        multiple: 2,
      },
    },
    {
      title: 'Maturity Date',
      dataIndex: 'MaturityDate',
      key: 'MaturityDate',
      render: (MaturityDate: any) => Utils.dateFormat(MaturityDate),
      sorter: {
        compare: () => {},
        multiple: 3,
      },
    },
    {
      title: 'Term Left',
      dataIndex: 'RemainingTerm',
      key: 'RemainingTerm',
      render: (remainingTerm: any, record: any) =>
        record?.MaturityDate
          ? Utils.getMonthDifference(new Date(record?.MaturityDate), new Date())
          : '',
    },
    {
      title: 'Next Payment',
      dataIndex: 'NextDueDate',
      key: 'NextDueDate',
      render: (NextDueDate: any) => Utils.dateFormat(NextDueDate),
      sorter: {
        compare: () => {},
        multiple: 4,
      },
    },
    {
      title: 'Regular Payment',
      dataIndex: 'RegularPayment',
      key: 'RegularPayment',
      render: (regPayment: any) => Utils.currencyFormat(regPayment),
      sorter: {
        compare: () => {},
        multiple: 5,
      },
    },
    {
      title: 'Loan Balance',
      dataIndex: 'PrinBal',
      key: 'PrinBal',
      render: (loanBalance: any) => Utils.currencyFormat(loanBalance),
      sorter: {
        compare: () => {},
        multiple: 6,
      },
    },
    {
      title: '',
      dataIndex: 'Actions',
      key: 'Actions',
      render: (action: any, record: any) => (
        <div>
          <Button className={styles.buttongold}>
            <Link to={`/investors/project/${record.Account}`}>Learn More</Link>
          </Button>
        </div>
      ),
    },
    Table.EXPAND_COLUMN,
  ]

  return (
    <>
      <div className={styles.title}>Portfolio</div>
      <ProjectsTable
        data={data}
        columns={columns}
        total={total}
        loading={loading}
        fetchData={fetchData}
        pageSize={pageSize}
        rowKey="Account"
        summary={() => (
          <ProjectSummary total={total} avg={avg}></ProjectSummary>
        )}
        expandedRowRender={(record: any) => (
          <ProjectDescription record={record}></ProjectDescription>
        )}
      />
    </>
  )
}

export default InvestorsPage
