import * as React from 'react'
import Banner from '../../components/home/Banner'
import MoreHouses from '../../components/home/MoreHouses'
import Products from '../../components/home/Products'
import Why from '../../components/home/Why'
import Projects from '../../components/home/Projects'
import ContactUS from '../../components/home/ContactUS'
import Footer from '../../components/common/Footer'
import Header from '../../components/common/Header'

const HomePage: React.FC = () => {
  return (
    <>
      <Header></Header>
      <div className="home-content">
        <Banner></Banner>
        <MoreHouses></MoreHouses>
        <Products></Products>
        <Why></Why>
        <Projects></Projects>
        <ContactUS></ContactUS>
      </div>
      <Footer></Footer>
    </>
  )
}

export default HomePage
