import { FC } from 'react'
import styles from './ProjectDescription.module.scss'
import Utils from '../../../utils/Utils'

const ProjectDescription: FC<{
  record: any,
}> = ({ record }) => {
  return (
    <div className={styles.expandable}>
      <div className={styles.column}>
        <div className={styles.columnrow}>
          <div className={styles.titlecol}>Yield Information</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Coupon Rate</div>
          <div className={styles.valuecol}>
            {Utils.percentFormat(record?.CouponRate)}
          </div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Servicing Fee</div>
          <div className={styles.valuecol}>
            {Utils.percentFormat(record?.ServicingFee)}
          </div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Net Investor Yield</div>
          <div className={styles.valuecol}>
            {Utils.percentFormat(record?.NetInvestorYield)}
          </div>
        </div>

        <div className={styles.columnrow}>
          <div className={styles.titlecol}>Overview</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Lien Type</div>
          <div className={styles.valuecol}>{record?.LienType}</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Repayment Type</div>
          <div className={styles.valuecol}>{record?.RepaymentType}</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Interest Reserve</div>
          <div className={styles.valuecol}>
            {Utils.currencyFormat(record?.InterestReserve)}
          </div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Orination Date</div>
          <div className={styles.valuecol}>
            {Utils.dateFormat(record?.Originated)}
          </div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Maturity Date</div>
          <div className={styles.valuecol}>
            {Utils.dateFormat(record?.MaturityDate)}
          </div>
        </div>

        <div className={styles.columnrow}>
          <div className={styles.titlecol}>Strategy</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Loan Purpose</div>
          <div className={styles.valuecol}>{record?.LoanPurpose}</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Repayment</div>
          <div className={styles.valuecol}>{record?.Repayment}</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Primary Credit Analysis</div>
          <div className={styles.valuecol}>{record?.PrimaryCreditAnalysis}</div>
        </div>
      </div>

      <div className={styles.column}>
        <div className={styles.columnrow}>
          <div className={styles.titlecol}>Credit Information</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Lots</div>
          <div className={styles.valuecol}>
            {Utils.percentFormat(record?.Lots)}
          </div>
          <div className={styles.valuecol}>Per Lot</div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>As Completed Value</div>
          <div className={styles.valuecol}>
            {Utils.currencyFormat(record?.AsCompletedValue)}
          </div>
          <div className={styles.valuecol}>
            {Utils.currencyFormat(record?.AsCompletedValuePerLot)}
          </div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Maximum Loan Amount</div>
          <div className={styles.valuecol}>
            {Utils.currencyFormat(record?.MaximumLoanAmount)}
          </div>
          <div className={styles.valuecol}>
            {Utils.currencyFormat(record?.MaximumLoanAmountPerLot)}
          </div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>As Completed LTV</div>
          <div className={styles.valuecol}>
            {Utils.percentFormat(record?.AsCompletedLTV)}
          </div>
          <div className={styles.valuecol}></div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Term (Months)</div>
          <div className={styles.valuecol}>
            {Utils.getMonthDifference(
              new Date(record.MaturityDate),
              new Date()
            )}
          </div>
          <div className={styles.valuecol}></div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Prepayment Penalty</div>
          <div className={styles.valuecol}>{record?.PrepaymentPenalty}</div>
          <div className={styles.valuecol}></div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Expected Repayment Date (Months)</div>
          <div className={styles.valuecol}>{record?.ExpectedRepaymentDate}</div>
          <div className={styles.valuecol}></div>
        </div>
        <div className={styles.columnrow}>
          <div className={styles.namecol}>Extension Option</div>
          <div className={styles.valuecol}>{record?.ExtensionOption}</div>
          <div className={styles.valuecol}></div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDescription
