export const GeoData: any = [
  {
    id: 'area1',
    type: 'Feature',
    properties: {
      title: 'Title area1',
      color: '#520f0c',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-75.559446, 39.629812],
          [-75.535144, 39.647212],
          [-75.509742, 39.686113],
          [-75.47764, 39.715013],
          [-75.459439, 39.765813],
          [-75.415062, 39.801919],
          [-75.41049247763708, 39.80467223432209],
          [-75.3903156232519, 39.81682921783048],
          [-75.35164821406889, 39.840127153513386],
          [-75.341765, 39.846082],
          [-75.293376, 39.848782],
          [-75.25880581804768, 39.85467390078439],
          [-75.221025, 39.861113],
          [-75.21119606147448, 39.86651863168209],
          [-75.183023, 39.882013],
          [-75.14143552174727, 39.89391837248129],
          [-75.13342, 39.896213],
          [-75.13572, 39.947112],
          [-75.11922, 39.965412],
          [-75.06012997072258, 39.99201100326259],
          [-75.059017, 39.992512],
          [-74.989914, 40.037311],
          [-74.97285475644559, 40.046505365536284],
          [-74.932211, 40.068411],
          [-74.863809, 40.08221],
          [-74.825907, 40.12391],
          [-74.769488, 40.129145],
          [-74.72337929715148, 40.1528955462317],
          [-74.721604, 40.15381],
          [-74.74849765725779, 40.18490861410399],
          [-74.760605, 40.198909],
          [-74.823907, 40.241508],
          [-74.856508, 40.277407],
          [-74.90331, 40.315607],
          [-74.92811056909137, 40.33982844768929],
          [-74.946006, 40.357306],
          [-74.969597, 40.39977],
          [-75.024775, 40.403455],
          [-75.056102, 40.416066],
          [-75.070568, 40.455165],
          [-75.062227, 40.481391],
          [-75.078503, 40.548296],
          [-75.136748, 40.575731],
          [-75.186737, 40.569406],
          [-75.18819987812519, 40.59261336664859],
          [-75.18923640135588, 40.609056961122086],
          [-75.191059, 40.637971],
          [-75.19184187174827, 40.677242125441396],
          [-75.192612, 40.715874],
          [-75.177477, 40.764225],
          [-75.11135873167228, 40.789982289214386],
          [-75.108505, 40.791094],
          [-75.090962, 40.849187],
          [-75.065438, 40.885682],
          [-75.09772, 40.926679],
          [-75.12325394420847, 40.96530553438769],
          [-75.133086, 40.980179],
          [-75.070532, 41.01862],
          [-75.015271, 41.061215],
          [-74.99238599518219, 41.09302824699339],
          [-74.98304207424778, 41.106017558856784],
          [-74.979873, 41.110423],
          [-74.905256, 41.155668],
          [-74.867405, 41.22777],
          [-74.815703, 41.296151],
          [-74.760325, 41.340325],
          [-74.694914, 41.357423],
          [-74.457584, 41.248225],
          [-74.36703826144738, 41.20421147244379],
          [-74.301994, 41.172594],
          [-74.234473, 41.142883],
          [-74.23436283123036, 41.142835271534594],
          [-74.21161764319858, 41.132981363536295],
          [-74.041054, 41.059088],
          [-73.89398186003588, 40.997198203538886],
          [-73.893979, 40.997197],
          [-73.90728, 40.951498],
          [-73.92047152937927, 40.918606332041],
          [-73.92203089786648, 40.914718213458784],
          [-73.93489258096068, 40.88264898194209],
          [-73.938081, 40.874699],
          [-73.94748456908319, 40.85777345328739],
          [-73.96583451704629, 40.82474525955859],
          [-73.968082, 40.8207],
          [-73.97120801828238, 40.81631562632088],
          [-73.98459249468017, 40.79754332855229],
          [-74.013784, 40.756601],
          [-74.02349153373218, 40.73745316548729],
          [-74.02947453619967, 40.72565186308668],
          [-74.03092708568377, 40.72278675049359],
          [-74.04115624430348, 40.70261002570879],
          [-74.046966591833, 40.69114928026859],
          [-74.047313, 40.690466],
          [-74.06772033031899, 40.67038059424459],
          [-74.07094353771528, 40.66720823277558],
          [-74.086806, 40.651596],
          [-74.16014678315318, 40.64607651644479],
          [-74.170611, 40.645289],
          [-74.20012290199108, 40.63186890194569],
          [-74.202247, 40.630903],
          [-74.203688, 40.592691],
          [-74.216839, 40.558618],
          [-74.249211, 40.545064],
          [-74.260611, 40.502436],
          [-74.261889, 40.464706],
          [-74.22465246601467, 40.448662489486495],
          [-74.206188, 40.440707],
          [-74.157094, 40.447571],
          [-74.108295, 40.443788],
          [-74.047884, 40.418908],
          [-74.019334, 40.471244],
          [-73.997943, 40.476668],
          [-73.976982, 40.408508],
          [-73.971381, 40.34801],
          [-73.981681, 40.279411],
          [-74.00102079443458, 40.216966591977894],
          [-74.030181, 40.122814],
          [-74.03496238226347, 40.102584305948184],
          [-74.064135, 39.979157],
          [-74.077247, 39.910991],
          [-74.090945, 39.799978],
          [-74.09961891432518, 39.763784361495794],
          [-74.101443, 39.756173],
          [-74.190974, 39.625118],
          [-74.29101533615479, 39.508369797497785],
          [-74.291585, 39.507705],
          [-74.304343, 39.471445],
          [-74.36699, 39.402017],
          [-74.412692, 39.360816],
          [-74.521797, 39.313816],
          [-74.54078785558248, 39.30002549118439],
          [-74.581008, 39.270819],
          [-74.646595, 39.212002],
          [-74.67143, 39.179802],
          [-74.714341, 39.119804],
          [-74.705876, 39.102937],
          [-74.778777, 39.023073],
          [-74.807917, 38.985948],
          [-74.864458, 38.94041],
          [-74.933571, 38.928519],
          [-74.967274, 38.933413],
          [-74.955363, 39.001262],
          [-74.903664, 39.087437],
          [-74.885914, 39.143627],
          [-74.905181, 39.174945],
          [-74.91515580556269, 39.17669750351878],
          [-74.998002, 39.191253],
          [-75.048487, 39.215222],
          [-75.090794, 39.210802],
          [-75.136667, 39.181883],
          [-75.166667, 39.222577],
          [-75.21251, 39.262755],
          [-75.244357, 39.2857],
          [-75.285333, 39.292212],
          [-75.326754, 39.332473],
          [-75.355558, 39.347823],
          [-75.399304, 39.37949],
          [-75.41710707748808, 39.388910686713686],
          [-75.442393, 39.402291],
          [-75.465212, 39.43893],
          [-75.536431, 39.460559],
          [-75.528088, 39.498114],
          [-75.527676, 39.535278],
          [-75.512732, 39.578],
          [-75.543965, 39.596],
          [-75.559446, 39.629812],
        ],
      ],
    },
  },
  {
    id: 'area2',
    type: 'Feature',
    properties: {
      title: 'Title area2',
      color: '#14442d',
    },

    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.416319, 44.099422],
          [-73.39986856936459, 44.152485647691684],
          [-73.395399, 44.166903],
          [-73.349889, 44.230356],
          [-73.316618, 44.257769],
          [-73.31745597466508, 44.26352218987678],
          [-73.324229, 44.310023],
          [-73.334637, 44.356877],
          [-73.320954, 44.382669],
          [-73.293613, 44.440559],
          [-73.312871, 44.507246],
          [-73.34798323242649, 44.54616285359699],
          [-73.36268281274378, 44.56245522168669],
          [-73.367275, 44.567545],
          [-73.389966, 44.61962],
          [-73.36556, 44.700297],
          [-73.357671, 44.751018],
          [-73.33443, 44.802188],
          [-73.34201248394999, 44.80807553866099],
          [-73.365678, 44.826451],
          [-73.379822, 44.857037],
          [-73.338979, 44.917681],
          [-73.34474, 44.970468],
          [-73.343124, 45.01084],
          [-73.19231044906688, 45.01286116295209],
          [-73.16992381668568, 45.013161182623485],
          [-73.048386, 45.01479],
          [-72.93644, 45.014267],
          [-72.582371, 45.011543],
          [-72.55427161622349, 45.0094677206535],
          [-72.532503, 45.00786],
          [-72.348583, 45.005625],
          [-72.023292, 45.006792],
          [-71.915009, 45.007791],
          [-71.89771151185879, 45.00807227383668],
          [-71.691898, 45.011419],
          [-71.60984, 45.012709],
          [-71.501088, 45.013377],
          [-71.531605, 44.976023],
          [-71.494403, 44.911837],
          [-71.522393, 44.880811],
          [-71.570402, 44.805276],
          [-71.626909, 44.747224],
          [-71.584574, 44.665351],
          [-71.551722, 44.627598],
          [-71.544922, 44.579278],
          [-71.588076, 44.54785],
          [-71.579974, 44.501778],
          [-71.646551, 44.468869],
          [-71.69092, 44.421234],
          [-71.76319252131339, 44.40356826277639],
          [-71.778613, 44.399799],
          [-71.818838, 44.352939],
          [-71.83779062638878, 44.34776454247699],
          [-71.875863, 44.33737],
          [-71.945163, 44.337744],
          [-72.002314, 44.324871],
          [-72.046302, 44.291983],
          [-72.05399, 44.246926],
          [-72.061338, 44.184951],
          [-72.05382688361469, 44.15981709380338],
          [-72.036883, 44.103119],
          [-72.075486, 44.034614],
          [-72.07969627284778, 44.0302566433846],
          [-72.07994422400259, 44.03000003018069],
          [-72.116706, 43.991954],
          [-72.105875, 43.94937],
          [-72.16978, 43.873425],
          [-72.183333, 43.808177],
          [-72.21150212234988, 43.77301941044169],
          [-72.222069, 43.759831],
          [-72.284805, 43.72036],
          [-72.329522, 43.608393],
          [-72.33359650135627, 43.60559134158909],
          [-72.37944, 43.574069],
          [-72.380894, 43.493394],
          [-72.39689079629089, 43.429051746335084],
          [-72.39692302503589, 43.428922115998596],
          [-72.413377, 43.362741],
          [-72.40690185803167, 43.33744883873478],
          [-72.402532, 43.32038],
          [-72.421583, 43.263442],
          [-72.43361119795948, 43.23290381226179],
          [-72.440563, 43.215254],
          [-72.45039593921588, 43.16121351690638],
          [-72.451802, 43.153486],
          [-72.435191, 43.086622],
          [-72.462248, 43.044214],
          [-72.45195470906819, 43.02049491063029],
          [-72.444977, 43.004416],
          [-72.492597, 42.967648],
          [-72.532186, 42.954945],
          [-72.531469, 42.89795],
          [-72.556112, 42.866252],
          [-72.55390205084858, 42.85803161016968],
          [-72.5396, 42.804832],
          [-72.477615, 42.761245],
          [-72.458519, 42.726853],
          [-72.51671118278219, 42.728467669829186],
          [-72.809113, 42.736581],
          [-72.86429185484538, 42.737713885159195],
          [-72.93026261251798, 42.73906834045759],
          [-73.01864861335038, 42.740883006399784],
          [-73.02301789329009, 42.74097271272689],
          [-73.14249512051438, 42.743425717196885],
          [-73.264957, 42.74594],
          [-73.290944, 42.80192],
          [-73.278673, 42.83341],
          [-73.273832807021, 42.94363174752649],
          [-73.27000873116357, 43.030714292687485],
          [-73.26978, 43.035923],
          [-73.25535583805758, 43.314712398599994],
          [-73.252832, 43.363493],
          [-73.242042, 43.534925],
          [-73.292113, 43.584509],
          [-73.327702, 43.625913],
          [-73.395767, 43.568087],
          [-73.424977, 43.598775],
          [-73.414546, 43.658209],
          [-73.393723, 43.6992],
          [-73.36110715168428, 43.75323345728389],
          [-73.350707, 43.770463],
          [-73.38252614953988, 43.80815898854949],
          [-73.390302, 43.817371],
          [-73.374051, 43.875563],
          [-73.407742, 43.929887],
          [-73.411248, 43.975596],
          [-73.405977, 44.011485],
          [-73.43688, 44.042578],
          [-73.416319, 44.099422],
        ],
      ],
    },
  },
]
