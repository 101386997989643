import { FC, useEffect, useState } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useNavigate, useParams } from 'react-router-dom'
import { Select, message, Breadcrumb } from 'antd'
import aws_export from '../../aws-exports'

import ProjectsTable from '../../components/common/Table'
import styles from './AdminPage.module.scss'

const LoanInvestorsPage: FC = () => {
  const { id } = useParams()
  const pageSize = 10
  const navigate = useNavigate()
  const [requests, setRequests] = useState([])
  const [total, setTotal] = useState<null | number>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchRequests = async (offset = 0): Promise<any> => {
    setLoading(true)
    const res = await API.get('investorsApi', '/investors', {
      queryStringParameters: {
        Offset: offset,
        PageSize: pageSize,
        LoanAccount: id,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    setRequests(res?.data || [])
    setTotal(res?.total || 0)
    setLoading(false)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  const columns = [
    {
      title: 'Lender account',
      dataIndex: 'LenderAccount',
      key: 'LenderAccount',
    },
    {
      title: 'Lender Name',
      dataIndex: 'FullName',
      key: 'FullName',
    },
    {
      title: 'Email',
      dataIndex: 'EmailAddress',
      key: 'EmailAddress',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
    },
  ]

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="1">
          <a onClick={() => navigate('/loans')}>Back To All</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="2">Loan {id}</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.title}>Investors</h3>

      <ProjectsTable
        data={requests}
        columns={columns}
        total={total}
        loading={loading}
        rowKey="LenderAccount"
        fetchData={fetchRequests}
      />
    </>
  )
}

export default LoanInvestorsPage
