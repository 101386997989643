import { FC, useEffect, useState, useCallback } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Select, Radio, Table, message } from 'antd'
import { Link } from 'react-router-dom'

import aws_export from '../../aws-exports'
import ProjectsTable from '../../components/common/Table'
import styles from './HistoryPage.module.scss'
import Utils from '../../utils/Utils'

const HistoryPage: FC = () => {
  const { Option } = Select
  const pageSize = 10
  const { user } = useAuthenticator((context) => [context.user])

  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<null | []>(null)
  const [total, setTotal] = useState<null | number>(null)
  const [historyAvg, setHistoryAvg] = useState<any>({})

  const [filters, setFilters] = useState<any>({})
  const [filterValues, setFilterValues] = useState<any>(null)

  const fetchFilters = async (): Promise<any> => {
    const res = await API.get('investorsApi', '/filters', {
      queryStringParameters: {
        ...filters,
        FilterUser: user.username,
      },
    }).catch((e) => message.error("Can't upload filters. Try again."))

    setFilterValues(res)
  }

  const fetchData = useCallback(
    async (offset = 0, sorters = {}): Promise<any> => {
      setLoading(true)

      const res = await API.get('investorsApi', '/history', {
        queryStringParameters: {
          Offset: offset,
          PageSize: pageSize,
          FilterUser: user.username,
          OrderBy: Utils.getOrderBySorters(sorters),
          ...filters,
        },
      }).catch((e) => message.error("Can't upload items. Try again."))

      setData(res?.data || [])
      setTotal(res?.total || 0)
      setHistoryAvg(res?.avg || {})

      setLoading(false)
    },
    [user, filters]
  )

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchFilters()
  }, [])

  const columns = [
    {
      title: 'Check Number',
      dataIndex: 'ACH_TransNumber',
      key: 'ACH_TransNumber',
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Check Date',
      dataIndex: 'DateRec',
      key: 'DateRec',
      render: (ACH_Transmission_DateTime: any, record: any) =>
        Utils.dateFormat(ACH_Transmission_DateTime || record.DateRec),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (Amount: any) => Utils.currencyFormat(Amount),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Serv. Fee',
      dataIndex: 'ServFee',
      key: 'ServFee',
      render: (ServFee: any) => Utils.currencyFormat(ServFee),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Interest',
      dataIndex: 'ToInterest',
      key: 'ToInterest',
      render: (ToInterest: any) => Utils.currencyFormat(ToInterest),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Principal',
      dataIndex: 'ToPrincipal',
      key: 'ToPrincipal',
      render: (ToPrincipal: any) => Utils.currencyFormat(ToPrincipal),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Charges',
      dataIndex: 'Charges',
      key: 'Charges',
      render: (Charges: any) => Utils.currencyFormat(Charges),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Other',
      dataIndex: 'ToOtherPayments',
      key: 'ToOtherPayments',
      render: (ToOtherPayments: any) => Utils.currencyFormat(ToOtherPayments),
      sorter: {
        compare: () => {},
        multiple: 1,
      },
    },
    {
      title: 'Loan Account',
      dataIndex: 'LoanAccount',
      key: 'LoanAccount',
      render: (LoanAccount: any, record: any) => (
        <Link to={`/investors/project/${LoanAccount || record?.Account}`}>
          {LoanAccount || record?.Account}
        </Link>
      ),
    },
    {
      title: 'Borrower Name',
      dataIndex: 'BorrowerName',
      key: 'BorrowerName',
      render: (name: any, record: any) => (
        <>
          {record.BorrowerFirstName} {record.BorrowerLastName}
        </>
      ),
    },
  ]

  const onFilter = (name: string, val: string) => {
    if (val.length) {
      setFilters({ ...filters, [name]: JSON.stringify(val) })
    } else {
      const { [name]: deleted, ...others } = filters
      setFilters(others)
    }
  }

  const summary = (
    <>
      <Table.Summary.Row className="ant-table-row">
        <Table.Summary.Cell index={0}>
          <div>Totals</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1}> </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          <div>{Utils.currencyFormat(historyAvg?.Amount)} </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          <div>{Utils.currencyFormat(historyAvg?.ServFee)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4}>
          <div>{Utils.currencyFormat(historyAvg?.Interest)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          <div>{Utils.currencyFormat(historyAvg?.Principal)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          <div>{Utils.currencyFormat(historyAvg?.Charges)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7}>
          <div>{Utils.currencyFormat(historyAvg?.Other)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8}> </Table.Summary.Cell>
        <Table.Summary.Cell index={9}> </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  )

  return (
    <>
      <div className={styles.title}>History</div>
      <div className={styles.filter}>
        <Select
          mode="multiple"
          showSearch
          style={{ width: 200 }}
          placeholder="Loan"
          allowClear
          optionFilterProp="children"
          onChange={(val) => onFilter('FilterLoans', val)}
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {(filterValues?.loans || []).map((loan: any) => (
            <Option key={loan} value={loan}>
              {loan}
            </Option>
          ))}
        </Select>

        <Radio.Group
          defaultValue=""
          buttonStyle="solid"
          onChange={(e) => onFilter('FilterDateInterval', e.target.value)}
        >
          <Radio.Button value="">All</Radio.Button>
          <Radio.Button value="30 DAY">30 days</Radio.Button>
          <Radio.Button value="3 MONTH">3 months</Radio.Button>
          <Radio.Button value="6 MONTH">6 months</Radio.Button>
          <Radio.Button value="1 YEAR">Last Year</Radio.Button>
        </Radio.Group>
      </div>
      <ProjectsTable
        data={data}
        columns={columns}
        total={total}
        filters={filters}
        loading={loading}
        rowKey="RecID"
        fetchData={fetchData}
        summary={() => summary}
      />
    </>
  )
}

export default HistoryPage
