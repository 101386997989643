import { FC } from "react";
import { Form, Input, Radio } from "antd";
import rightArrow from "../../../assets/svgs/rightArrow.svg";

type FormHandlers = {
  submitFn: (values: any) => Promise<number>;
};

const DesktopContactForm: FC<FormHandlers> = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  return (
    <Form
      form={form}
      onFinish={(vals) => {
        props.submitFn(vals).then((val) => {
          if (val == 0) {
            form.resetFields();
          }
        });
      }}
    >
      <div className="form-row">
        <div className="form-col lotline-font-regular-w">First Name</div>
        <div className="form-col lotline-font-regular-w">Last Name</div>
      </div>
      <div className="form-row">
        <div className="form-col">
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Enter your first name",
              },
            ]}
          >
            <Input
              placeholder="Enter your first name"
              className="lotline-font-regularM-w"
            ></Input>
          </Form.Item>
        </div>
        <div className="form-col">
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Enter your last name",
              },
            ]}
          >
            <Input
              placeholder="Enter your last name"
              className="lotline-font-regularM-w"
            ></Input>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="form-col lotline-font-regular-w">Company Name</div>
        <div className="form-col lotline-font-regular-w">E-mail</div>
      </div>
      <div className="form-row">
        <div className="form-col">
          <Form.Item name="companyName">
            <Input
              placeholder="Enter your company name"
              className="lotline-font-regularM-w"
            ></Input>
          </Form.Item>
        </div>
        <div className="form-col">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "The input is not valid e-mail",
              },
            ]}
          >
            <Input
              placeholder="Enter your e-mail"
              className="lotline-font-regularM-w"
            ></Input>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="form-col">
          <div className="lotline-font-regular-w">Phone number</div>
          <Form.Item name="phoneNumber" style={{ paddingTop: 20 }}>
            <Input
              placeholder="Enter your phone number"
              className="lotline-font-regularM-w"
            ></Input>
          </Form.Item>
        </div>

        <div className="form-col">
          <Form.Item name="select" initialValue="0">
            <Radio.Group name="select">
              <Radio style={radioStyle} value="0">
                <span className="lotline-font-regular-w">Are you builder?</span>
              </Radio>
              <Radio style={{ ...radioStyle, paddingTop: "1.4vw" }} value="1">
                <span className="lotline-font-regular-w">Or an investor?</span>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <div className="form-row ll-message lotline-font-regular-w">
        Message to LotLine
      </div>
      <div className="form-row">
        <Form.Item
          name="message"
          style={{ paddingLeft: 20, width: "100%" }}
          rules={[
            {
              required: true,
              message: "Enter your message",
            },
          ]}
        >
          <TextArea
            rows={3}
            placeholder="Enter your message"
            className="lotline-font-regularM-w"
          ></TextArea>
        </Form.Item>
      </div>
      <div className="form-row" style={{ paddingLeft: 20 }}>
        <a onClick={() => form.submit()} className="lotline-font-buttonL-w">
          Submit <img src={rightArrow} width="16" height="12" />
        </a>
      </div>
    </Form>
  );
};

export default DesktopContactForm;
