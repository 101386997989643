import { FC } from "react";
import { useParallax } from "react-scroll-parallax";
import { useMediaQuery } from "react-responsive";
import { Button as AntButton } from "antd";
import Button from "../../common/Button";
import Background1 from "../Background1";
import "./Banner.css";

import MobileBackground from "../MobileBackground";

const Banner: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767.9 });

  const { ref } = useParallax<HTMLDivElement>({ translateY: [30, -30] });

  const getRect = () => {
    return ref.current?.getBoundingClientRect();
  };

  const containerClasses =
    "lotline-banner-container lotline-left-border" +
    (isMobile ? " lotline-right-border " : "");
  return (
    <>
      <div className={containerClasses} ref={ref}>
        <div className="banner-time-to-build lotline-font-header-light-w">
          It's time to build
        </div>
        <div className="banner-more-houses lotline-font-header-medium-w">
          more houses
        </div>
        <div className="banner-we-provide lotline-font-header3L-w">
          We provide competitive capital to residential builders and developers
        </div>
        <div className="banner-button">
          <Button text="GET STARTED"></Button>
        </div>
        <div className="banner-button-mobile">
          <AntButton type="primary" className="lotline-font-button" block>
            GET STARTED
          </AntButton>
        </div>
        {!isMobile && (
          <div className="background-container">
            <Background1 rectFunc={getRect}></Background1>
          </div>
        )}
      </div>
      {isMobile && (
        <div className="background-container">
          <MobileBackground rectFunc={getRect}></MobileBackground>
        </div>
      )}
    </>
  );
};
export default Banner;
