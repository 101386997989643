import { FC, useState, useEffect, useRef } from 'react'
import Amplify, { API, Storage } from 'aws-amplify'
import { useParams, useNavigate } from 'react-router-dom'
import { Form, Upload, Select, message, Modal, Breadcrumb, Input } from 'antd'
import { LoadingOutlined, InboxOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import JoditEditor from 'jodit-react'

import aws_export from '../../aws-exports'
import Button from '../../components/common/Button'
import Utils from '../../utils/Utils'
import styles from './AdminPage.module.scss'

const { Option } = Select
const { Dragger } = Upload

const LoansPage: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [data, setData] = useState<null | any>(null)
  const [fileList, setFileList] = useState<any>({})
  const [loading, setLoading] = useState<boolean | string>(false)
  const [previewVisible, setPreviewVisible] = useState<boolean | any>(false)
  let [isBlocking, setIsBlocking] = useState(false)

  Utils.usePrompt('Are you sure you want to leave?', isBlocking, () =>
    console.log('fsdfdfdf')
  )

  const editor = useRef(null)

  const fetchData = async (): Promise<any> => {
    const res = await API.get('investorsApi', '/item/' + id, {}).catch((e) =>
      message.error("Can't upload item. Try again.")
    )
    setData(res)

    const images = await Promise.all(
      (res?.properties || []).map(async (item: any) => {
        const imgs = await Promise.all(
          (JSON.parse(item?.Images) || []).map(async (key: any) => ({
            uid: key,
            url: await Storage.get(key),
          }))
        )
        return {
          RecID: item.RecID,
          Description: item.Description,
          Videos: JSON.parse(item.Videos) || [],
          Images: imgs,
          Address: [item?.Street, item?.City, item?.State, item?.ZipCode]
            .filter((i) => i)
            .join(', '),
        }
      })
    ).then((res) =>
      res.reduce(
        (prev: any, item: any) => ({ ...prev, [item.RecID]: item }),
        {}
      )
    )

    form.setFieldsValue({ properties: images })
    setFileList(images)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchData()
  }, [])

  useEffect(() => {
    // onFormChange()
  }, [fileList])

  useEffect(() => {
    form.setFieldsValue({
      Status: data?.Status,
      description: data?.description,
    })
  }, [form, data])

  const getValues = (values: any) => {
    if (values.properties)
      Object.keys(values.properties).map((key: any) => {
        values.properties[key].Images = fileList[key].Images.map(
          (item: any) => item.uid
        )
      })
    return values
  }

  const submitFn = async (values: any): Promise<any> => {
    await API.put('investorsApi', '/item/' + id, { body: getValues(values) })
    if (id) localStorage.removeItem(id)
    setIsBlocking(false)

    if (values.properties) {
      await Promise.all(
        Object.keys(values.properties).map(async (id: any) => {
          const res = await Storage.list(id + '/')

          res.map(async (img: any) => {
            if (!values.properties[id]?.Images.includes(img.key)) {
              console.log('delete! ' + img.key)
              await Storage.remove(img.key)
            }
          })
        })
      )
    }

    navigate('/loans')
  }

  const onFormChange = () => {
    if (data && id) {
      console.log('onFormChange')
      console.log(data)
      // setIsBlocking(true)
      localStorage.setItem(id, JSON.stringify(getValues(form.getFieldsValue())))
    }
  }

  const uploadImageToS3 = async (file: any, id: any) => {
    try {
      setLoading(id)
      const put = await Storage.put(`${id}/${file.name}`, file, {
        contentType: file.type,
      })
      const res = await Storage.get(put.key)
      setLoading(false)
      const result = fileList[id]
      result.Images.push({
        uid: put.key,
        url: res,
      })
      setFileList({ ...fileList, [id]: result })
    } catch (error) {
      message.error('Error uploading file: ' + error)
      setLoading(false)
    }
  }

  const imageUpload = (id: any, item?: any) => {
    const handleRemove = (file: any) => {
      const newFileList = fileList[id]
      newFileList.Images = newFileList.Images.filter(
        (item: any) => item.uid !== file.uid
      )
      setFileList({ ...fileList, [id]: newFileList })
    }

    const beforeUpload = (file: any) => {
      if (!['image/png', 'image/jpeg'].includes(file.type)) {
        message.error(`${file.name} is not a png or jpeg file`)
        return false
      }

      uploadImageToS3(file, id)
      return false
    }

    return (
      <div key={id}>
        <Form.Item
          key={`${id}_description`}
          name={['properties', id, 'Description']}
          label="Property Name"
        >
          <Input value={item?.Description} placeholder="Property Name" />
        </Form.Item>
        <Form.Item
          key={`${id}_youtube`}
          name={['properties', id, 'Videos']}
          label="Youtube video ids"
        >
          <Select mode="tags" style={{ width: '100%' }}></Select>
        </Form.Item>
        <Form.Item key={id} name={['properties', id, 'Images']}>
          <ImgCrop aspect={100 / 60}>
            <Dragger
              name={id}
              fileList={item.Images}
              listType="picture-card"
              className="avatar-uploader"
              beforeUpload={beforeUpload}
              onPreview={(file) => setPreviewVisible(file)}
              onRemove={handleRemove}
            >
              {item.Images.length < 5 &&
                (loading === id ? (
                  <LoadingOutlined />
                ) : (
                  <div>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </div>
                ))}
            </Dragger>
          </ImgCrop>
          <Modal
            visible={!!previewVisible}
            title={previewVisible?.uid}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
          >
            <img
              alt="example"
              style={{ width: '100%' }}
              src={previewVisible?.url}
            />
          </Modal>
        </Form.Item>
      </div>
    )
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="1">
          <a onClick={() => navigate('/loans')}>Back To All</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="2">Loan {id}</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.title}>Loan {id}</h3>
      <Form
        layout="vertical"
        className={styles.form}
        form={form}
        onValuesChange={onFormChange}
        onFinish={(vals) => {
          submitFn(vals).then((val) => {
            if (val == 0) form.resetFields()
          })
        }}
      >
        <Form.Item name="Status" key="Status" label="Status">
          <Select
            showSearch
            placeholder="Status"
            allowClear
            optionFilterProp="children"
          >
            {Utils.statuses.map((status: any, i: any) => (
              <Option key={status + i} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" key="description" label="Description">
          {/* @ts-ignore: */}
          <JoditEditor ref={editor} value={''} config={Utils.editorConfig} />
        </Form.Item>
        <h3 className={styles.title}>Properties</h3>
        {fileList &&
          Object.keys(fileList).map((key: any) =>
            imageUpload(key, fileList[key])
          )}
        <div className={styles.btns}>
          <Button text="Save" onClick={form.submit} />
          <Button
            text="Preview"
            onClick={() =>
              window.open(`/investors/project/${id}/preview`, '_blank')
            }
          />
        </div>
      </Form>
    </>
  )
}

export default LoansPage
