import { FC } from "react";
import "./Why.css";
import Button from "../../common/Button";
import hands from "../../../assets/svgs/hands.svg";
import twentyfour from "../../../assets/svgs/24.svg";
import bars from "../../../assets/svgs/bars.svg";
import rightArrow from "../../../assets/svgs/rightArrow.svg";

const Why: FC = () => {
  return (
    <div className="why-container lotline-left-border lotline-right-border">
      <div className="why-container-col col-padding20">
        <div className="why-hdr-txt lotline-font-header2-w">Why Lot Line?</div>
        <div className="why-txt lotline-font-header3L-w">
          Our focus on single family development makes us:
        </div>
        <div className="why-item lotline-font-header3-w">
          <img
            src={rightArrow}
            width={24}
            height={24}
            style={{ paddingRight: 10 }}
            alt=""
          />
          Fast
        </div>
        <div className="why-item lotline-font-header3-w">
          <img
            src={rightArrow}
            width={24}
            height={24}
            style={{ paddingRight: 10 }}
            alt=""
          />
          Flexible
        </div>
        <div className="why-item lotline-font-header3-w">
          <img
            src={rightArrow}
            width={24}
            height={24}
            style={{ paddingRight: 10 }}
            alt=""
          />
          Competitive
        </div>
        <div className="why-get-started">
          <Button text="GET STARTED"></Button>
        </div>
      </div>
      <div className="why-container-col">
        <div className="why-card">
          <div className="why-tst">
            <div>
              <img src={hands} className="why-ans-img" alt="" />
            </div>
            <div className="lotline-font-regularM-w">
              We know your development pipeline is your essential working
              capital and we price and structure funding accordingly
            </div>
          </div>
        </div>
        <div className="why-card">
          <div className="why-tst">
            <div>
              <img src={bars} className="why-ans-img" alt="" />
            </div>
            <div className="lotline-font-regularM-w">
              We maximize the use of data and technology for efficient
              underwriting and processing
            </div>
          </div>
        </div>
        <div className="why-card">
          <div className="why-tst">
            <div>
              <img src={twentyfour} className="why-ans-img" alt="" />
            </div>
            <div className="lotline-font-regularM-w">
              We eliminate time wasting bureaucracy so you can focus on building
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
