import { FC, useState, useRef, useContext } from 'react'
import { Storage, API } from 'aws-amplify'
import { Form, Upload, Select, message, Modal, Input, Tooltip } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import {
  LoadingOutlined,
  InboxOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import { DataContext } from '../../../pages/project/ProjectPage'
import Utils from '../../../utils/Utils'
import { FilesContext } from './index'
import styles from './ProjectPageCarousel.module.scss'

const { Dragger } = Upload
const { Option } = Select
const type = 'DragableUploadList'

interface DragableUploadListItemProps {
  id: string;
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile;
  fileList: UploadFile[];
  moveRow: (id: any, dragIndex: any, hoverIndex: any) => void;
}

const UploadImages: FC = () => {
  const { form, dicts, setDicts } = useContext(DataContext)
  const { fileList, setFileList } = useContext(FilesContext)
  const [loading, setLoading] = useState<boolean | string>(false)
  const [previewVisible, setPreviewVisible] = useState<boolean | any>(false)

  const uploadImageToS3 = async (file: any, id: any) => {
    try {
      setLoading(id)
      const put = await Storage.put(`${id}/${file.name}`, file, {
        contentType: file.type,
      })
      const res = await Storage.get(put.key)
      setLoading(false)
      const result = fileList[id]
      result.Images.push({
        uid: put.key,
        url: res,
      })
      setFileList({ ...fileList, [id]: result })
    } catch (error) {
      message.error('Error uploading file: ' + error)
      setLoading(false)
    }
  }

  const moveRow = (id: string, dragIndex: number, hoverIndex: number) => {
    setLoading(true)
    const dragRow = fileList[id].Images[dragIndex]
    const result = fileList[id]
    result.Images = update(result.Images, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRow],
      ],
    })
    setLoading(false)
    setFileList({ ...fileList, [id]: result })
  }

  const DragableUploadListItem = ({
    id,
    originNode,
    moveRow,
    file,
    fileList,
  }: DragableUploadListItemProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const index = fileList.indexOf(file)
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {}
        if (dragIndex === index) {
          return {}
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        }
      },
      drop: (item: any) => {
        moveRow(id, item.index, index)
      },
    })
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })
    drop(drag(ref))
    const errorNode = (
      <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
    )
    return (
      <div
        ref={ref}
        className={`ant-upload-draggable-list-item ${
          isOver ? dropClassName : ''
        }`}
        style={{ cursor: 'move' }}
      >
        {file.status === 'error' ? errorNode : originNode}
      </div>
    )
  }

  const onChange = async (id: string, vals: any) => {
    const newVals = vals.filter((val: any) => !dicts?.tags[val])
    if (newVals.length > 0) {
      const newDicts = await API.put('investorsApi', '/loans/dicts', {
        body: { tags: newVals },
      }).catch((e) => message.error("Can't save items. Try again."))

      const tagsByVal = Utils.getDictByKey(newDicts.tags, 'value')

      newDicts.tags = Utils.getDictByKey(newDicts?.tags, 'id')
      setDicts(newDicts)

      const TagsIds = vals.map((id: string) =>
        newDicts.tags[id] ? String(id) : String(tagsByVal[id]?.id)
      )
      form.setFieldsValue({ properties: { [id]: { TagsIds } } })
    }
  }

  const onDelete = async (id: string, tagId: string, event: any) => {
    event.stopPropagation()

    const newDicts = await API.del('investorsApi', '/loans/dicts', {
      body: { tags: [tagId] },
    }).catch((e) => message.error("Can't save items. Try again."))
    newDicts.tags = Utils.getDictByKey(newDicts?.tags, 'id')
    setDicts(newDicts)

    const TagsIds = form
      .getFieldValue(['properties', id, 'TagsIds'])
      .filter((id: string) => id !== tagId)
    form.setFieldsValue({ properties: { [id]: { TagsIds } } })
  }

  const imageUpload = (id: any, item?: any) => {
    const handleRemove = (file: any) => {
      const newFileList = fileList[id]
      newFileList.Images = newFileList.Images.filter(
        (item: any) => item.uid !== file.uid
      )
      setFileList({ ...fileList, [id]: newFileList })
    }

    const beforeUpload = (file: any) => {
      if (!['image/png', 'image/jpeg'].includes(file.type)) {
        message.error(`${file.name} is not a png or jpeg file`)
        return false
      }

      uploadImageToS3(file, id)
      return false
    }

    return (
      <div key={id} className={styles.editModal}>
        <Form.Item
          key={`${id}_description`}
          name={['properties', id, 'Description']}
          label="Property Name"
        >
          <Input value={item?.Description} placeholder="Property Name" />
        </Form.Item>
        <Form.Item
          key={`${id}_tagsIds`}
          name={['properties', id, 'TagsIds']}
          label="Tags"
        >
          <Select
            mode="tags"
            showSearch
            allowClear
            optionFilterProp="children"
            style={{ width: '100%' }}
            onChange={onChange.bind(null, id)}
          >
            {Object.keys(dicts?.tags || []).map((tagId: any) => (
              <Option key={`tag${tagId}`} value={tagId}>
                <CloseCircleOutlined
                  onClick={onDelete.bind(null, id, tagId)}
                  className={styles.delBtn}
                />
                {dicts?.tags[tagId]?.value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          key={`${id}_youtube`}
          name={['properties', id, 'Videos']}
          label="Youtube video ids"
        >
          <Select mode="tags" style={{ width: '100%' }}></Select>
        </Form.Item>
        <Form.Item key={id} name={['properties', id, 'Images']}>
          <ImgCrop aspect={100 / 60}>
            <Dragger
              name={id}
              fileList={item.Images}
              listType="picture-card"
              className="avatar-uploader"
              beforeUpload={beforeUpload}
              onPreview={(file) => setPreviewVisible(file)}
              onRemove={handleRemove}
              itemRender={(originNode, file, currFileList) => (
                <DragableUploadListItem
                  id={id}
                  originNode={originNode}
                  file={file}
                  fileList={currFileList}
                  moveRow={moveRow}
                />
              )}
            >
              {item.Images.length < 5 &&
                (loading === id ? (
                  <LoadingOutlined />
                ) : (
                  <div>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </div>
                ))}
            </Dragger>
          </ImgCrop>
          <Modal
            visible={!!previewVisible}
            title={previewVisible?.uid}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
          >
            <img
              alt="example"
              style={{ width: '100%' }}
              src={previewVisible?.url}
            />
          </Modal>
        </Form.Item>
      </div>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {fileList &&
        Object.keys(fileList).map((key: any) =>
          imageUpload(key, fileList[key])
        )}
    </DndProvider>
  )
}

export default UploadImages
