import { FC, useEffect, useState } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useNavigate, useParams } from 'react-router-dom'
import { message, Breadcrumb } from 'antd'
import aws_export from '../../aws-exports'

import Button from '../../components/common/Button'
import ProjectsTable from '../../components/common/Table'
import styles from './AdminPage.module.scss'

const RequestsPage: FC = () => {
  const { id } = useParams()
  const pageSize = 10
  const navigate = useNavigate()
  const [requests, setRequests] = useState([])
  const [total, setTotal] = useState<null | number>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchRequests = async (offset = 0): Promise<any> => {
    setLoading(true)
    const res = await API.get('investorsApi', '/request', {
      queryStringParameters: {
        Offset: offset,
        PageSize: pageSize,
        LoanAccount: id,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    setRequests(res?.data || [])
    setTotal(res?.total || 0)
    setLoading(false)
  }

  const updateStatus = async (
    LenderAccount: string,
    Status: string,
    Username: string
  ): Promise<any> => {
    await API.put('investorsApi', '/request', {
      body: {
        Status,
        LenderAccount,
        LoanAccount: id,
      },
    })
      .then(async () => {
        fetchRequests()
        const body = {
          user: Username,
          message: `Your access request to loan ${id} was ${Status}`,
        }
        await API.post('notificationsApi', '/item/object', { body }).catch(() =>
          message.error("Can't update status. Try again.")
        )
      })
      .catch(() => message.error("Can't update status. Try again."))
  }

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  const columns = [
    {
      title: 'Lender account',
      dataIndex: 'LenderAccount',
      key: 'LenderAccount',
    },
    {
      title: 'Lender Name',
      dataIndex: 'FullName',
      key: 'FullName',
    },
    {
      title: 'Email',
      dataIndex: 'EmailAddress',
      key: 'EmailAddress',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
    },
    {
      dataIndex: 'LenderAccount',
      key: 'action',
      render: (LenderAccount: any, record: any) => (
        <span className={styles.btns}>
          {record && record?.Status !== 'approved' && (
            <Button
              text="Approve"
              isSmall
              onClick={updateStatus.bind(
                null,
                LenderAccount,
                'approved',
                record?.Username
              )}
            />
          )}
          {record && record?.Status !== 'declined' && (
            <Button
              text="Decline"
              isSmall
              onClick={updateStatus.bind(
                null,
                LenderAccount,
                'declined',
                record?.Username
              )}
            />
          )}
        </span>
      ),
    },
  ]

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="1">
          <a onClick={() => navigate('/loans')}>Back To All</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="2">Loan {id}</Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.title}>Access Requests</h3>

      <ProjectsTable
        data={requests}
        columns={columns}
        total={total}
        loading={loading}
        rowKey="LenderAccount"
        fetchData={fetchRequests}
      />
    </>
  )
}

export default RequestsPage
