import { FC } from "react";
import "./ProjectCard.css";

type ProjectCardProps = {
  key: number;
  lotCount: number;
  img: string;
  location: string;
  state: string;
};

const ProjectCard: FC<ProjectCardProps> = (cardProps) => {
  return (
    <div className="project-card">
      <img src={cardProps.img} className="project-card-image" />
      <div className="card-count">
        <span className="pc-count lotline-font-regular-w">
          {cardProps.lotCount}
        </span>
        <span className="lotline-font-regular-w">&nbsp;finished lots</span>
      </div>
      <div className="card-location lotline-font-header3-w">
        {cardProps.location}
      </div>
      <div className="card-state lotline-font-regular-w">{cardProps.state}</div>
    </div>
  );
};

export default ProjectCard;
