import { FC } from 'react'
import cs from 'classnames'
import styles from './Button.module.scss'

type ButtonProps = {
  text: string,
  onClick?: any,
  isFullWidth?: boolean,
  isActive?: boolean,
  isDisabled?: boolean,
  isSmall?: boolean,
}

const Button: FC<ButtonProps> = ({
  text,
  onClick,
  isFullWidth,
  isActive,
  isDisabled,
  isSmall,
}) => {
  return (
    <div
      className={cs(
        styles.lotline_btn_w,
        isFullWidth ? styles.lotline_btn_full_w : '',
        isActive ? styles.lotline_btn_w_active : '',
        isDisabled ? styles.lotline_btn_w_disabled : '',
        isSmall ? styles.lotline_btn_w_small : ''
      )}
    >
      <a onClick={onClick} className={styles.lotline_btn_inner}>
        {text}
      </a>
    </div>
  )
}

export default Button
