import { FC, useEffect, useRef, useState } from "react";
import { useParallax } from "react-scroll-parallax";

import useWindowDimensions from "../../../hooks/windowDimensions";
import tt1 from "../../../assets/svgs/parallax/tst.svg";
import tt from "../../../assets/svgs/parallax/gold-house.svg";
import "./MobileBackground.css";

type BackgroundProps = {
  rectFunc: () => any;
};

const MobileBackground: FC<BackgroundProps> = (props) => {
  const { width, height } = useWindowDimensions();

  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  });

  const lotStyle = {
    maxHeight: 0.2 * dimension.height,
    minHeight: 0.2 * dimension.height,
  };

  const { ref } = useParallax<HTMLDivElement>({
    speed: -25,
    translateY: [-48, 0],
    translateX: [((-1 * dimension.height) / width) * 48, 0],
    shouldAlwaysCompleteAnimation: true,
    startScroll: 0,
    endScroll: 160,
  });
  const prevRef = useRef<HTMLDivElement>(null);

  const [shadowStyle, setShadowStyle] = useState({
    left: (ref.current?.clientWidth ? ref.current?.clientWidth : 0) + 16,
    top: (ref.current?.clientHeight ? 3 * ref.current?.clientHeight : 0) + 48,
    width: (ref.current?.clientWidth ? ref.current?.clientWidth : 0) - 5,
    height: (ref.current?.clientHeight ? ref.current?.clientHeight : 0) - 5,
  });

  const [backgroundStyle, setBackgroundStyle] = useState({
    width: 0.5 * width,
    height: 0.8 * height,
  });

  useEffect(() => {
    // setBackgroundStyle({width: width, height: 0.7*height});
    const ts = setTimeout(() => {
      const rect = props.rectFunc();
      const d = {
        width: rect.width,
        height: rect.height,
        top: rect.top,
        left: rect.left,
      };
      console.log(d);

      setDimension(d);

      setBackgroundStyle({ width: 1 * d.width, height: 0.6 * d.height });

      setShadowStyle({
        left: (ref.current?.clientWidth ? ref.current?.clientWidth : 0) + 24,
        top:
          (ref.current?.clientHeight ? 2 * ref.current?.clientHeight : 0) + 32,
        width: (ref.current?.clientWidth ? ref.current?.clientWidth : 0) - 8,
        height: (ref.current?.clientHeight ? ref.current?.clientHeight : 0) - 8,
      });
    }, 10);
    return () => clearTimeout(ts);
  }, [width, height]);
  return (
    <div className="lotline-mobile-background" style={backgroundStyle}>
      <div className="lotline-background-1"></div>
      <div className="lotline-over">
        <div className="lot-row" style={{ minWidth: 0.25 * dimension.width }}>
          <div className="lot" style={{ ...lotStyle, opacity: 0.4 }}>
            <img className="awfull-m" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.6 }}>
            <img className="awfull-m" src={tt1} />
          </div>
          <div
            className="lot"
            style={{ ...lotStyle, visibility: "hidden" }}
          ></div>
          <div
            className="lot"
            style={{ ...lotStyle, visibility: "hidden" }}
          ></div>
        </div>
        <div className="lot-row" style={{ minWidth: 0.25 * dimension.width }}>
          <div className="lot" style={{ ...lotStyle, opacity: 0.4 }}>
            <img className="awfull-m" src={tt1} />
          </div>
          <div
            ref={prevRef}
            className="lot"
            style={{ ...lotStyle, opacity: 0.6 }}
          >
            <img className="awfull-m" src={tt1} />
          </div>
          <div ref={ref} className="lot-gold" style={lotStyle}>
            <img className="awfull-m" src={tt} />
          </div>
          <div className="shadow" style={{ ...lotStyle, ...shadowStyle }}></div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.3 }}>
            <img className="awfull-m" src={tt1} />
          </div>
        </div>
        <div className="lot-row" style={{ minWidth: 0.25 * dimension.width }}>
          <div className="lot" style={{ ...lotStyle, opacity: 0.6 }}>
            <img className="awfull-m" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.4 }}>
            <img className="awfull-m" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.3 }}>
            <img className="awfull-m" src={tt1} />
          </div>
          <div
            className="lot"
            style={{ ...lotStyle, visibility: "hidden" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MobileBackground;
