import {
  createContext,
  FC,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react'
import { Storage } from 'aws-amplify'
import { Modal, Carousel } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import YouTube from 'react-youtube'

import UploadImages from './UploadImages'
import Utils from '../../../utils/Utils'
import image from 'assets/images/project-image.jpg'
import styles from './ProjectPageCarousel.module.scss'
import { DataContext } from '../../../pages/project/ProjectPage'

export const FilesContext = createContext<any>({})

const ProjectPageCarousel: FC<{
  edit: boolean,
  preview: boolean,
  form: any,
}> = ({ edit = false, preview = false, form }) => {
  const { data, dicts } = useContext(DataContext)
  const fileListRef = useRef<any>()
  const prevEditProp = Utils.usePrevious(edit)
  const [video, setVideo] = useState<null | any>(null)
  const [fileList, setFileList] = useState<any>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    fileListRef.current = fileList
  }, [fileList])

  const handleSwipe = () => {
    if (video) video.pauseVideo()
  }

  const fetchImages = async () => {
    if (!data?.properties) return

    const images = await Promise.all(
      (data?.properties).map(async (item: any) => {
        const imgs = await Promise.all(
          (JSON.parse(item?.Images) || []).map(async (key: any) => ({
            uid: key,
            url: await Storage.get(key),
          }))
        )
        return {
          RecID: item.RecID,
          Description: item.Description,
          Videos: JSON.parse(item.Videos) || [],
          Images: imgs,
          TagsIds: JSON.parse(item?.TagsIds) || [],
          PropertyType: item?.PropertyType,
          Address: [item?.Street, item?.City, item?.State, item?.ZipCode]
            .filter((i) => i)
            .join(', '),
        }
      })
    ).then((res) =>
      res.reduce(
        (prev: any, item: any) => ({ ...prev, [item.RecID]: item }),
        {}
      )
    )

    form.setFieldsValue({ properties: images })
    setFileList(images)
  }

  const info = (item: any) => (
    <>
      <h1 className={styles.title}>{item.Description}</h1>
      <div className={styles.address}>{item.Address}</div>

      <div className={styles.tags}>
        {(item.TagsIds || []).map((id: any) => (
          <span className={styles.tags_item} key={`tag_${id}`}>
            {dicts?.tags[id]?.value || id}
          </span>
        ))}
      </div>
    </>
  )

  const saveProperties = () => {
    if (fileListRef?.current) {
      const values = form.getFieldsValue()

      setFileList(
        Object.keys(fileListRef.current).reduce(
          (prev, key) => ({
            ...prev,
            [key]: {
              ...fileListRef.current[key],
              ...values.properties[key],
              Images: fileListRef.current[key].Images,
            },
          }),
          {}
        )
      )
    }

    setIsModalVisible(false)
  }

  const returnProperties = () => {
    if (fileListRef?.current) {
      form.setFieldsValue({ properties: fileListRef.current })
    }
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (edit !== prevEditProp) return
    if (data && form) fetchImages()
  }, [data, form])

  return (
    <FilesContext.Provider value={{ fileList, setFileList }}>
      <div>
        {edit && (
          <div className={styles.modal}>
            {!preview && (
              <EditOutlined
                onClick={() => setIsModalVisible(!isModalVisible)}
                className={styles.btn}
              />
            )}

            <Modal
              width={800}
              title="Properties"
              visible={isModalVisible}
              onOk={saveProperties}
              onCancel={returnProperties}
              okText="Save"
            >
              <UploadImages />
            </Modal>
          </div>
        )}

        <Carousel afterChange={handleSwipe}>
          {fileList &&
            Object.keys(fileList).map((key: any) => {
              const item = fileList[key]
              const itemInfo = info(item)
              const images = item.Images.length ? item.Images : [{ url: image }]

              return images
                .map((img: any) => (
                  <div key={img}>
                    {itemInfo}
                    <img src={img.url} alt="" className={styles.image} />
                  </div>
                ))
                .concat(
                  item.Videos.map((key: any) => (
                    <div key={key}>
                      {itemInfo}
                      <YouTube
                        className={styles.video}
                        videoId={key}
                        opts={{
                          height: '390',
                          width: '640',
                          playerVars: {
                            autoplay: 0,
                            modestbranding: 1,
                          },
                        }}
                        onPlay={(event) => setVideo(event.target)}
                      />
                    </div>
                  ))
                )
            })}
        </Carousel>
      </div>
    </FilesContext.Provider>
  )
}

export default ProjectPageCarousel
