import { FC } from 'react'

import Card from '../../../components/common/Card'
import Utils from '../../../utils/Utils'
import styles from './Cards.module.scss'

const Cards: FC<{ data: null | any }> = ({ data }) => {
  const mainText = 'Total Portfolio Value'
  const mainTitle = Utils.currencyFormat(data?.TotalPortfolioValue)
  const source = {
    'Portfolio Yield': Utils.percentFormat(data?.PortfolioYield),
    'Year-to-Date Interest': Utils.currencyFormat(data?.Interest),
    'Year-to-Date Principal': Utils.currencyFormat(data?.Principal),
    'Total Number of Loans': data?.TotalNumberofLoans,
    'Charges Owed to You': Utils.currencyFormat(data?.ChargesOwedtoYou),
    'Charges Owed by You': Utils.currencyFormat(data?.ChargesOwedbyYou),
    'Cash in Trust': Utils.currencyFormat(data?.CashinTrust),
    'Amount Pending Disbursement (0 checks)': Utils.currencyFormat(
      data?.AmountPendingDisbursement
    ),
    'Year-to-Date Servicing Fees Paid': Utils.currencyFormat(
      data?.ServicingFeesPaid
    ),
    'Loans Maturing in the Next 3 Months': data?.LoansMaturingintheNext3Months,
    'Weighted Average Maturity (Months)': data?.WeightedAverageMaturity,
  }

  return (
    <>
      <Card mainTitle={mainTitle} mainText={mainText} data={source} />
      <div className={styles.chartCards}>
        <Card
          text={'Portfolio LTV'}
          title={Utils.percentFormat(data?.PortfolioLTV)}
        />
        <Card
          text={'Portfolio LTC'}
          title={Utils.percentFormat(data?.PortfolioLTC)}
        />
      </div>
    </>
  )
}

export default Cards
