import { FC, useEffect, useState, useRef, useCallback } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Form, Select, Button, Table, message, Breadcrumb } from 'antd'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import cs from 'classnames'

import aws_export from '../../aws-exports'
import ProjectsTable from '../../components/common/Table'
import styles from './InvestorsPage.module.scss'
import Utils from '../../utils/Utils'

const today = new Date()
const options = [
  {
    id: 1,
    title: 'Last 30 days',
    to: today,
    from: moment().subtract(1, 'months'),
  },
  {
    id: 2,
    title: 'Last 3 months',
    to: today,
    from: moment().subtract(3, 'months'),
  },
  {
    id: 3,
    title: 'Last 6 months',
    to: today,
    from: moment().subtract(6, 'months'),
  },
  {
    id: 4,
    title: 'Last 12 months',
    to: today,
    from: moment().subtract(12, 'months'),
  },
  {
    id: 5,
    title: 'Month to date',
    to: today,
    from: moment().startOf('month'),
  },
  {
    id: 6,
    title: 'Quarter to date',
    to: today,
    from: moment().startOf('quarter'),
  },
  { id: 7, title: 'Year to date', to: today, from: moment().startOf('year') },
]

const portfolioColumns = [
  {
    title: 'Loan Account',
    dataIndex: 'Account',
    key: 'Account',
  },
  {
    title: 'Borrower Name',
    dataIndex: 'BorrowerName',
    key: 'BorrowerName',
    render: (name: any, record: any) => (
      <>
        {record.BorrowerFirstName} {record.BorrowerLastName}
      </>
    ),
  },
  {
    title: 'Percent Owned',
    dataIndex: 'PercentOwned',
    key: 'PercentOwned',
  },
  {
    title: 'Interest Rate',
    dataIndex: 'SoldRate',
    key: 'SoldRate',
    render: (SoldRate: any) => Utils.percentFormat(SoldRate),
  },
  {
    title: 'Maturity Date',
    dataIndex: 'MaturityDate',
    key: 'MaturityDate',
    render: (MaturityDate: any) => Utils.dateFormat(MaturityDate),
  },
  {
    title: 'Term Left',
    dataIndex: 'RemainingTerm',
    key: 'RemainingTerm',
    render: (remainingTerm: any, record: any) =>
      record?.MaturityDate
        ? Utils.getMonthDifference(new Date(record?.MaturityDate), new Date())
        : '',
  },
  {
    title: 'Next Payment',
    dataIndex: 'NextDueDate',
    key: 'NextDueDate',
    render: (NextDueDate: any) => Utils.dateFormat(NextDueDate),
  },
  {
    title: 'Regular Payment',
    dataIndex: 'RegularPayment',
    key: 'RegularPayment',
    render: (regPayment: any) => Utils.currencyFormat(regPayment),
  },
  {
    title: 'Loan Balance',
    dataIndex: 'PrinBal',
    key: 'PrinBal',
    render: (loanBalance: any) => Utils.currencyFormat(loanBalance),
  },
]

const historyColumns = [
  {
    title: 'Check Number',
    dataIndex: 'ACH_TransNumber',
    key: 'ACH_TransNumber',
  },
  {
    title: 'Check Date',
    dataIndex: 'DateRec',
    key: 'DateRec',
    render: (ACH_Transmission_DateTime: any, record: any) =>
      Utils.dateFormat(ACH_Transmission_DateTime || record.DateRec),
  },
  {
    title: 'Amount',
    dataIndex: 'Amount',
    key: 'Amount',
    render: (Amount: any) => Utils.currencyFormat(Amount),
  },
  {
    title: 'Serv. Fee',
    dataIndex: 'ServFee',
    key: 'ServFee',
    render: (ServFee: any) => Utils.currencyFormat(ServFee),
  },
  {
    title: 'Interest',
    dataIndex: 'ToInterest',
    key: 'ToInterest',
    render: (ToInterest: any) => Utils.currencyFormat(ToInterest),
  },
  {
    title: 'Principal',
    dataIndex: 'ToPrincipal',
    key: 'ToPrincipal',
    render: (ToPrincipal: any) => Utils.currencyFormat(ToPrincipal),
  },
  {
    title: 'Charges',
    dataIndex: 'Charges',
    key: 'Charges',
    render: (Charges: any) => Utils.currencyFormat(Charges),
  },
  {
    title: 'Other',
    dataIndex: 'ToOtherPayments',
    key: 'ToOtherPayments',
    render: (ToOtherPayments: any) => Utils.currencyFormat(ToOtherPayments),
  },
  {
    title: 'Loan account',
    dataIndex: 'LoanAccount',
    key: 'LoanAccount',
    render: (LoanAccount: any, record: any) => LoanAccount || record?.Account,
  },
  {
    title: 'Borrower Name',
    dataIndex: 'BorrowerName',
    key: 'BorrowerName',
    render: (name: any, record: any) => (
      <>
        {record.BorrowerFirstName} {record.BorrowerLastName}
      </>
    ),
  },
]

const { Option } = Select

const StatementPage: FC = () => {
  const navigate = useNavigate()
  const { user } = useAuthenticator((context) => [context.user])
  const componentRef = useRef<any>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [form] = Form.useForm()
  const [range, setRange] = useState<any | null>()
  const [account, setAccount] = useState<any[]>([])
  const [portfolio, setPortfolio] = useState<any[]>([])
  const [portfolioTotal, setPortfolioTotal] = useState<null | number>(null)
  const [portfolioAvg, setPortfolioAvg] = useState<any>({
    soldRate: 0,
    regPayment: 0,
    prinBalance: 0,
  })
  const [history, setHistory] = useState<any[]>([])
  const [historyAvg, setHistoryAvg] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    Amplify.configure(aws_export)
    const res = searchParams.get('range')
    if (res) {
      setRange(options.filter((i) => i.id === parseInt(res))[0])
    }
  }, [])

  const fetchData = useCallback(async (): Promise<any> => {
    setLoading(true)

    const res = await API.get('investorsApi', '/list', {
      queryStringParameters: {
        FilterUser: user.username,
        FilterShowStatusActive: true,
        //Add range
      },
    }).catch((e) => message.error("Can't upload loans. Try again."))
    setPortfolioAvg(res?.avg || {})
    setPortfolioTotal(res?.total || 0)
    setPortfolio(res?.data || [])

    const result = await API.get('investorsApi', '/history', {
      queryStringParameters: {
        FilterUser: user.username,
        FilterDate: moment(range?.from).toISOString(),
      },
    }).catch((e) => message.error("Can't upload history. Try again."))
    setHistoryAvg(result?.avg || {})
    setHistory(result?.data || [])

    setLoading(false)
  }, [range])

  useEffect(() => {
    if (!range) return
    setAccount([
      {
        col1: `${user.attributes?.given_name || ''} 
          ${user.attributes?.family_name || ''}`,
        col2: `URL: ${user.attributes?.website || ''}`,
      },
      {
        col1: `Company: ${user.attributes?.['custom:company_name'] || ''}`,
        col2: `Address: ${user.attributes?.['custom:company_address'] || ''}`,
      },
      {
        col1: `Phone: ${user.attributes?.phone_number || ''}`,
        col2: `Statement Date: ${Utils.dateFormat(today)}`,
      },
      {
        col1: `Email: ${user.attributes?.email}`,
        col2: `Statement Period: ${Utils.dateFormat(range?.from)} \
        To ${Utils.dateFormat(range?.to)}`,
      },
    ])
    fetchData()
  }, [range])

  const historySummary = (
    <>
      <Table.Summary.Row className="ant-table-row">
        <Table.Summary.Cell index={0}>
          <div>Totals</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1}> </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          <div>{Utils.currencyFormat(historyAvg?.Amount)} </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          <div>{Utils.currencyFormat(historyAvg?.ServFee)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4}>
          <div>{Utils.currencyFormat(historyAvg?.Interest)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          <div>{Utils.currencyFormat(historyAvg?.Principal)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          <div>{Utils.currencyFormat(historyAvg?.Charges)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7}>
          <div>{Utils.currencyFormat(historyAvg?.Other)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8}> </Table.Summary.Cell>
        <Table.Summary.Cell index={9}> </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  )

  const loansSummary = (
    <>
      <Table.Summary.Row className="ant-table-row">
        <Table.Summary.Cell index={12} colSpan={1}>
          <div>Totals</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={13} colSpan={6}>
          <div>
            Portfolio Yield{' '}
            <span>{Utils.percentFormat(portfolioAvg?.soldRate)}</span>{' '}
            <span>({portfolioTotal ? portfolioTotal : 0} loans)</span>
          </div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={14}>
          <div>{Utils.currencyFormat(portfolioAvg?.regPayment)}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={15}>
          <div>{Utils.currencyFormat(portfolioAvg?.prinBalance)}</div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  )

  const onShow = useCallback(() => {
    setSearchParams({ range: form.getFieldValue('dateRange') })
  }, [form])

  if (range)
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item key="1">
            <a onClick={() => navigate('/statement')}>Back To All</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="2">{range.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.print}>
          <ReactToPrint
            trigger={() => <Button>Print</Button>}
            content={() => componentRef.current}
          />
        </div>
        <div ref={componentRef}>
          <Table
            key="Statement"
            className="simpleTable"
            rowKey="col1"
            title={() => 'Statement of Account'}
            pagination={false}
            showHeader={false}
            columns={[
              { key: 'col1', dataIndex: 'col1', align: 'left' },
              { key: 'col2', dataIndex: 'col2', align: 'left' },
            ]}
            dataSource={account}
          />
          <ProjectsTable
            className="simpleTable"
            rowKey="Account"
            key="Portfolio"
            title={() => 'Account Portfolio'}
            columns={portfolioColumns}
            data={portfolio}
            loading={loading}
            total={0}
            summary={() => loansSummary}
          />
          <ProjectsTable
            className="simpleTable"
            key="History"
            rowKey="RecID"
            title={() => 'Account History'}
            columns={historyColumns}
            data={history}
            loading={loading}
            total={0}
            summary={() => historySummary}
          />
        </div>
      </>
    )

  return (
    <>
      <div className={styles.title}>Statement</div>
      <p>
        History can be displayed for a selected date range. Select a date range
        below.
      </p>
      <Form
        form={form}
        className={cs(styles.form, styles.statementForm)}
        initialValues={{ dateRange: 4 }}
      >
        <Form.Item name="dateRange" label="Select Range of Dates">
          <Select allowClear={false} optionFilterProp="children">
            {options.map((val: any) => (
              <Option key={val.id} value={val.id}>
                {val.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Button onClick={onShow}>Show</Button>
      </Form>
    </>
  )
}

export default StatementPage
