import { FC, useState } from 'react'
import { Marker, Popup } from 'react-map-gl'
import marker from '../../../../assets/images/marker.png'

export type Marker = { address: string, latitude: number, longitude: number }

export const MarkerWithPopup: FC<Marker> = ({
  latitude,
  longitude,
  address,
}) => {
  const [showPopup, setShowPopup] = useState(false)

  const handleMarkerClick = ({ originalEvent }: any) => {
    if (originalEvent) originalEvent.stopPropagation()
    setShowPopup(true)
  }

  return (
    <>
      <Marker
        latitude={latitude}
        longitude={longitude}
        anchor="bottom"
        color="transparent"
      />
      <Popup
        latitude={latitude}
        longitude={longitude}
        className="marker"
        closeOnClick={false}
        closeOnMove={false}
        closeButton={false}
        offset={{ bottom: [0, -10] }}
      >
        <img src={marker} onClick={handleMarkerClick} />
      </Popup>
      {showPopup && (
        <Popup
          latitude={latitude}
          longitude={longitude}
          offset={{ bottom: [0, -40] }}
          onClose={() => setShowPopup(false)}
        >
          {address}
        </Popup>
      )}
    </>
  )
}
