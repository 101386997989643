import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

type SwiperProps = {
  opinions: any[];
};

const MobileProjectsSwiper: FC<SwiperProps> = (props) => {
  const opinions = props.opinions;
  return (
    <>
      <Swiper
        pagination={{ clickable: true }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {opinions.map((v, idx) => {
          return (
            <SwiperSlide key={v.key}>
              <div className="project-slider">
                <div className="project-slider-header lotline-font-header2">
                  {v.title}
                </div>
                <div className="project-slider-txt">{v.text}</div>
                <div className="project-slider-delimiter"></div>
                <div className="lotline-font-regular">{v.who}</div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default MobileProjectsSwiper;
