import { FC } from "react";
import "./Footer.css";
import logo from "../../../assets/svgs/footer-logo.svg";
import linkedin from "../../../assets/svgs/linkedin.svg";
import twitter from "../../../assets/svgs/twitter.svg";

const Footer: FC = () => {
  return (
    <footer className="lotline-footer">
      <div className="footer-vertical-container lotline-left-border lotline-right-border">
        <div className="footer-row-1">
          <div className="footer-row1-logo">
            <img src={logo} className="footer-logo" />
          </div>
          <div className="footer-row1-menu">
            <div className="footer-row1-menu-row">
              <div className="footer-row1-col">
                <span>BUILDERS</span>
                <span className="footer-menu-item paddingTop16">
                  Lorem Ipsum
                </span>
                <span className="footer-menu-item paddingDesktopTop16">
                  Consectetur
                </span>
              </div>
              <div className="footer-row1-col">
                <span>INVESTORS</span>
                <span className="footer-menu-item paddingTop16">
                  Accumsan magna
                </span>
                <span className="footer-menu-item paddingDesktopTop16">
                  Lorem Ipsum
                </span>
              </div>
              <div className="footer-row1-col">
                <span>PRODUCTS</span>
                <span className="footer-menu-item paddingTop16">
                  Lorem Ipsum
                </span>
                <span className="footer-menu-item paddingDesktopTop16">
                  Accumsan magna
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-row-2">
          <div className="footer-row-2-rights">
            2021.LotLine.All right reserved.
          </div>
          <div className="footer-row-2-social">
            <div>
              <img src={linkedin} className="footer-social" />
              <img
                src={twitter}
                className="footer-social footer-social-padding"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
