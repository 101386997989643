import { FC, useEffect, useState, useCallback } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Table, message } from 'antd'
import { DownloadOutlined, LinkOutlined } from '@ant-design/icons'

import aws_export from '../../aws-exports'
import styles from './InvestorsPage.module.scss'
import ProjectsTable from '../../components/common/Table'

const TaxFormsPage: FC = () => {
  const { user } = useAuthenticator((context) => [context.user])
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>({})
  const [years, setYears] = useState<any>([])

  const fetchData = useCallback(async (): Promise<any> => {
    setLoading(true)

    const res = await API.get('investorsApi', '/tax', {
      queryStringParameters: {
        FilterUser: user.username,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))

    const data = (res?.data || []).reduce((prev: any, item: any) => {
      const year = new Date(item?.SysCreatedDate).getFullYear()
      return {
        ...prev,
        [year]: (prev[year] || []).concat([item]),
      }
    }, {})
    setYears(
      Object.keys(data).map((year: any) => ({
        year,
        record: data[year],
      }))
    )
    setData(data)
    setLoading(false)
  }, [user])

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  const columns = [
    {
      title: 'Loan Account',
      dataIndex: 'Account',
      key: 'Account',
      align: 'left',
      width: '20%',
    },

    {
      title: 'Form',
      dataIndex: 'Description',
      key: 'Description',
      align: 'left',
    },
    {
      title: 'Action',
      dataIndex: 'FileName',
      key: 'FileName',
      align: 'right',
      render: (action: any, record: any) => (
        <div>
          <LinkOutlined className={styles.iconBtn} />
          <DownloadOutlined className={styles.iconBtn} />
        </div>
      ),
    },
    Table.EXPAND_COLUMN,
  ]

  return (
    <>
      <div className={styles.title}>Tax Forms</div>
      <ProjectsTable
        showHeader={false}
        columns={[
          Table.EXPAND_COLUMN,
          {
            title: '',
            dataIndex: 'year',
            key: '',
            align: 'left',
          },
        ]}
        total={data?.length || 0}
        loading={loading}
        rowKey="year"
        data={years}
        fetchData={fetchData}
        expandedRowRender={(record: any) => (
          <Table
            pagination={false}
            columns={columns}
            rowKey="Account"
            dataSource={record.record}
          />
        )}
      />
    </>
  )
}

export default TaxFormsPage
