import * as React from 'react'
import { Table, Pagination } from 'antd'
import Amplify, { API } from 'aws-amplify'
import aws_export from '../../../aws-exports'

const columns = [
  {
    dataIndex: 'email',
    key: 'email',
  },
  {
    dataIndex: 'status',
    key: 'status',
  },
]

const NotificationsStatusesTable: React.FC<{ id: any }> = ({ id }) => {
  const pageSize = 2
  const [statuses, setStatuses] = React.useState<any>([])
  const [total, setTotal] = React.useState(0)
  const [offset, setOffset] = React.useState(0)

  const fetchStatuses = async (): Promise<any> => {
    const res = await API.get('notificationsApi', '/item/' + id, {
      queryStringParameters: {
        Offset: offset,
        PageSize: pageSize,
      },
    })
    setStatuses(res.data)
    setTotal(res.total)
  }

  const paginationChangeHandler = (page: any) => {
    setOffset((page - 1) * pageSize)
  }

  React.useEffect(() => {
    fetchStatuses()
  }, [offset])

  React.useEffect(() => {
    Amplify.configure(aws_export)
    setOffset(0)
  }, [])

  return (
    <>
      <Table
        showHeader={false}
        dataSource={statuses}
        columns={columns}
        pagination={false}
      />
      {total > pageSize && (
        <Pagination
          pageSize={pageSize}
          onChange={paginationChangeHandler}
          showSizeChanger={false}
          showLessItems={false}
          showPrevNextJumpers={false}
          showQuickJumper={false}
          total={total}
        />
      )}
    </>
  )
}

export default NotificationsStatusesTable
