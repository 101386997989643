import { FC, useRef, useContext } from 'react'
import { Form } from 'antd'
import JoditEditor from 'jodit-react'

import Utils from '../../../utils/Utils'
import styles from './ProjectPageDescription.module.scss'
import { DataContext } from '../../../pages/project/ProjectPage'

const ProjectPageDescription: FC<{ edit: boolean }> = ({ edit = false }) => {
  const { data } = useContext(DataContext)
  const editor = useRef(null)

  return (
    <div id="Summary" className={styles.description}>
      {edit && (
        <Form.Item name="description" key="description">
          {/* @ts-ignore: */}
          <JoditEditor ref={editor} value={''} config={Utils.editorConfig} />
        </Form.Item>
      )}
      {!edit && (
        <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
      )}
    </div>
  )
}

export default ProjectPageDescription
