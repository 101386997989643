import { FC, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import './Projects.css'
import ProjectCard from './ProjectCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

import DesktopProjectsSwiper from './DesktopProjectsSwiper'
import MobileProjectsSwiper from './MobileProjectsSwiper'

import projects from '../../../assets/images/projects.png'
import prj1 from '../../../assets/images/project1.png'
import prj2 from '../../../assets/images/project2.png'
import prj3 from '../../../assets/images/project3.png'

const Projects: FC = () => {
  const [opinions] = useState([
    {
      key: 1,
      title: 'What they say',
      text: '“I was able to get a higher loan amount than I would have in the past. It makes it easier on my cashflow“',
      who: 'Tom S.',
    },
    {
      key: 2,
      title: 'What they say',
      text: '“Working with Lot Line saved me time I would have otherwise been spending with other lenders that required much more from us to get started”',
      who: 'Maria X.',
    },
    {
      key: 3,
      title: 'What they say',
      text: '“Lot line has a deep understanding of my needs as a home developer and builder and I trust their guidance”',
      who: 'Bill W.',
    },
  ])

  const [cards] = useState([
    {
      key: 1,
      img: prj1,
      lotCount: 20,
      location: 'Villas San Marco',
      state: 'North Carolina',
    },
    {
      key: 2,
      img: prj2,
      lotCount: 35,
      location: 'Middlebridge Farm',
      state: 'Texas',
    },
    {
      key: 3,
      img: prj3,
      lotCount: 15,
      location: 'Fairgate Estates',
      state: 'California',
    },
  ])

  return (
    <div className="projects-container">
      <div className="projects-img-container">
        <img src={projects} className="projects-img" />
      </div>
      <div className="projects-opinion-container lotline-left-border lotline-right-border">
        <div className="projects-opinion">
          <DesktopProjectsSwiper opinions={opinions}></DesktopProjectsSwiper>
        </div>
        <div className="projects-opinion-mobile">
          <MobileProjectsSwiper opinions={opinions}></MobileProjectsSwiper>
        </div>
      </div>
      <div className="projects-inner-container lotline-left-border lotline-right-border">
        <div className="project-title lotline-font-header2-w">Our projects</div>
        <div className="desktop-carousel">
          <Swiper navigation={true} modules={[Navigation]} slidesPerView={2}>
            {cards.map((card, idx) => {
              return (
                <SwiperSlide key={card.key}>
                  <ProjectCard
                    key={card.key}
                    img={card.img}
                    lotCount={card.lotCount}
                    state={card.state}
                    location={card.location}
                  ></ProjectCard>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>

        <div className="mobile-carousel">
          {cards.map((card, idx) => {
            return (
              <ProjectCard
                key={card.key}
                img={card.img}
                lotCount={card.lotCount}
                state={card.state}
                location={card.location}
              ></ProjectCard>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Projects
