import { FC, useState, useEffect } from 'react'
import Amplify, { API } from 'aws-amplify'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Typography, message } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import cs from 'classnames'

import aws_export from '../../../aws-exports'
import styles from '../NotificationsWidget/NotificationsWidget.module.scss'

const { Paragraph } = Typography

const MessagesWidget: FC = () => {
  const navigate = useNavigate()
  const [messages, setMessages] = useState<null | any[]>(null)
  const [total, setTotal] = useState<number>(0)
  const [unreadMessages, setUnreadMessages] = useState<number>(0)

  const fetchMessages = async (): Promise<any> => {
    const res = await API.get('messageApi', '/items', {
      queryStringParameters: {
        Sent: false,
        PageSize: 5,
      },
    }).catch((e) => message.error("Can't upload messages. Try again."))

    setMessages(res?.data || [])
    setTotal(res?.total || 0)
    setUnreadMessages(
      res?.data.reduce(
        (res: any, item: any) =>
          res + (!item.userStatus && item.status === 'new' ? 1 : 0),
        0
      )
    )
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchMessages()
  }, [])

  return (
    <>
      <div className={styles.blockTitle}>
        <Link to="/messages">
          {unreadMessages > 0 && (
            <span
              className="status"
              style={{
                background: 'red',
                marginRight: 5,
              }}
            ></span>
          )}
          Messages{' '}
          {messages && (
            <span>
              ({unreadMessages}/{total})
            </span>
          )}
        </Link>

        <Link to="/send-message">
          <FormOutlined />
        </Link>
      </div>
      <div>
        {messages &&
          messages.map((item: any) => (
            <div
              key={item.id}
              className={cs(
                styles[item.userStatus || item.status],
                styles.item
              )}
            >
              <Paragraph
                ellipsis={{ rows: 1 }}
                onClick={() => navigate(`/messages/${item.id}`)}
              >
                {item?.from}: {item?.subject}
              </Paragraph>
            </div>
          ))}
      </div>
    </>
  )
}

export default MessagesWidget
