import React from 'react'
import { Area } from '@ant-design/charts'
import Utils from '../../../utils/Utils'

const PortfolioChart: React.FC<{ data: any }> = ({ data }) => {
  const config = {
    data: data || [],
    xField: 'data',
    yField: 'value',
    seriesField: 'category',
    color: ['#00D05C', '#9AA2B1'],
    colorField: 'category',
    line: { color: ['#00D05C', '#9AA2B1'] },
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: '#9AA2B1',
            lineWidth: 1,
          },
        },
      },
    },
    legend: { position: 'top-right' },
    areaStyle: {
      fill: 'l(90) 0.2:#00D05C 1:#02163D',
      fillOpacity: 0.9,
    },
    tooltip: {
      customContent: (title: any, items: any) => {
        return (
          <>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item: any, index: any) => {
                let { name, value, color } = item
                if (name === 'total acount value') {
                  value =
                    parseInt(
                      items.filter((i: any) => i.name === 'net investment')[0]
                        ?.value
                    ) + parseInt(value)
                }
                return (
                  <li
                    key={`${item.title}_${index}`}
                    className="g2-tooltip-list-item"
                    data-index={index}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span>
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">
                        {Utils.currencyFormat(value)}
                      </span>
                    </span>
                  </li>
                )
              })}
            </ul>
          </>
        )
      },
    },
  }
  return (
    <>
      {/*  @ts-ignore: */}
      <Area {...config} />
    </>
  )
}

export default PortfolioChart
