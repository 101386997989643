import { FC, useState, useEffect } from 'react'
import Amplify, { API, Storage } from 'aws-amplify'
import { useParams, useNavigate } from 'react-router-dom'
import { Form, Upload, Select, message, Modal, Breadcrumb, Input } from 'antd'
import { LoadingOutlined, InboxOutlined } from '@ant-design/icons'
import cs from 'classnames'

import aws_export from '../../aws-exports'
import Button from '../../components/common/Button'
import styles from './AdminPage.module.scss'

const { Option } = Select
const { Dragger } = Upload

const NewsFormPage: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [data, setData] = useState<null | any>(null)
  const [fileList, setFileList] = useState<any>([])
  const [loading, setLoading] = useState<boolean | string>(false)
  const [previewVisible, setPreviewVisible] = useState<boolean | any>(false)

  const fetchData = async (): Promise<any> => {
    const res = await API.get('newsApi', '/rssitem/' + id, {}).catch((e) =>
      message.error("Can't upload item. Try again.")
    )
    setData(res)

    if (res?.image && res.image !== 'undefined') {
      const url = await Storage.get(res?.image)
      setFileList([{ uid: res?.image, url }])
    }
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    if (id) fetchData()
  }, [id])

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        status: data?.status,
        feed: data?.feed,
        link: data?.link,
      })
    }
    if (!id) form.setFieldsValue({ status: 'show' })
  }, [form, data])

  const submitFn = async (values: any): Promise<any> => {
    if (fileList.length) {
      values.image = fileList[0]?.uid
    }

    API[id ? 'put' : 'post']('newsApi', `/rssitem/${id ? id : 'object'}`, {
      body: values,
    })
      .then(() => {
        navigate('/news')
      })
      .catch((error) => {
        if (error?.response?.data?.code === 'ENOTFOUND') {
          message.error('The rss feed url is not valid')
          return
        }
        message.error(error?.response?.data)
      })
  }

  const statuses = ['show', 'hide']

  const uploadImageToS3 = async (file: any) => {
    try {
      setLoading(true)
      const put = await Storage.put(`rss/${file.name}`, file, {
        contentType: file.type,
      })
      const res = await Storage.get(put.key)
      setLoading(false)
      setFileList([{ uid: put.key, url: res }])
    } catch (error) {
      message.error('Error uploading file: ' + error)
      setLoading(false)
    }
  }

  const beforeUpload = (file: any) => {
    if (!['image/png', 'image/jpeg', 'image/svg+xml'].includes(file.type)) {
      message.error(`${file.name} is not a png, svg or jpeg file`)
      return false
    }

    uploadImageToS3(file)
    return false
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="1">
          <a onClick={() => navigate(-1)}>Back To All</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="2">
          {id ? '' : 'New'} Rss Feed {id}
        </Breadcrumb.Item>
      </Breadcrumb>
      <h3 className={styles.title}>
        {id ? '' : 'New'} Rss Feed {id}
      </h3>
      <Form
        layout="vertical"
        className={cs('loansPage', styles.form)}
        form={form}
        onFinish={submitFn}
      >
        <Form.Item
          name="feed"
          key="feed"
          label="Feed Name"
          rules={[
            {
              required: true,
              message: 'Feed name is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="link"
          key="link"
          label="Feed Url"
          rules={[
            {
              required: true,
              message: 'Feed url is required',
            },
            {
              type: 'url',
              message: 'Feed url must be a valid url',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="status"
          key="status"
          label="Status"
          rules={[
            {
              required: true,
              message: 'Status is required',
            },
          ]}
        >
          <Select showSearch allowClear optionFilterProp="children">
            {statuses.map((status: any) => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item key="image" label="Image">
          <Dragger
            multiple={false}
            name={id}
            fileList={fileList}
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            maxCount={1}
            onPreview={(file) => setPreviewVisible(file)}
          >
            {loading ? (
              <LoadingOutlined />
            ) : (
              <div>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </div>
            )}
          </Dragger>
          <Modal
            visible={!!previewVisible}
            title={previewVisible?.uid}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
          >
            <img
              alt="example"
              style={{ width: '100%' }}
              src={previewVisible?.url}
            />
          </Modal>
        </Form.Item>

        <Button text="Save" onClick={form.submit} />
      </Form>
    </>
  )
}

export default NewsFormPage
