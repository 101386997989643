import React from 'react'
import cs from 'classnames'
import {
  Authenticator,
  withAuthenticator,
  AmplifyProvider,
  useAuthenticator,
  View,
  useTheme,
  Text,
  RadioGroupField,
  Radio,
} from '@aws-amplify/ui-react'
import { useLocation } from 'react-router-dom'
import Amplify from 'aws-amplify'

import Utils from '../utils/Utils'
import awsExports from '../aws-exports'
import Logo from '../components/common/Logo'
import Header from '../components/common/Header'

Amplify.configure(awsExports)

const custom_builder_default = '0'

const FormFields = () => {
  return (
    <>
      <RadioGroupField
        label=""
        name="builder"
        style={{ display: 'none' }}
        defaultValue={custom_builder_default}
      >
        <Radio value="1">Are you builder?</Radio>
        <Radio value="0">Or an investor?</Radio>
      </RadioGroupField>
      <Authenticator.SignUp.FormFields />
    </>
  )
}

const formFields = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: 'Email Address',
    },
  },
  signUp: {
    given_name: {
      labelHidden: true,
      placeholder: 'Last name*',
      order: 1,
    },
    family_name: {
      labelHidden: true,
      placeholder: 'First name*',
      order: 2,
    },
    email: {
      labelHidden: true,
      placeholder: 'Email Address*',
      order: 3,
    },
    phone_number: {
      labelHidden: true,
      placeholder: 'Phone Number',
      order: 4,
      required: false,
      dialCode: '+227',
    },
    'custom:company_name': {
      labelHidden: true,
      placeholder: 'Company Name',
      order: 5,
    },
    'custom:position': {
      labelHidden: true,
      placeholder: 'Position/Title at Company',
      order: 6,
    },
    'custom:company_address': {
      labelHidden: true,
      placeholder: 'Company Address',
      order: 7,
    },
    'custom:builder': {
      labelHidden: true,
      placeholder: '',
      order: 7,
    },
    website: {
      labelHidden: true,
      placeholder: 'Website URL',
      order: 8,
    },
    password: {
      labelHidden: true,
      placeholder: 'Enter your Password*',
      order: 9,
    },
    confirm_password: {
      labelHidden: true,
      placeholder: 'Confirm Password*',
      order: 10,
    },
  },
}

const AuthHeader = () => {
  const { tokens } = useTheme()

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <a href="/">
        <Logo className="logo" />
      </a>
    </View>
  )
}

const AuthFooter = () => {
  const { tokens } = useTheme()

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Text color={`${tokens.colors.neutral['80']}`}>
        &copy; All Rights Reserved
      </Text>
    </View>
  )
}

const services = {
  async validateCustomSignUp(formData: any) {
    formData['custom:builder'] = !formData.builder ? 1 : formData.builder
  },
}

const withAuth = (WrappedComponent: any, group?: any) => {
  const App: any = () => {
    const location = useLocation()
    const { user } = useAuthenticator((context) => [context.user])
    const groups = user ? Utils.getUserGroups(user) : []
    const isAdmin = user ? Utils.isAdmin(user) : false

    const allowed =
      group && groups ? groups.includes(group.toUpperCase()) : true

    const confirmed = isAdmin
      ? true
      : user?.attributes
      ? user.attributes['custom:admin_confirmed'] === 'true'
      : false

    return (
      <AmplifyProvider colorMode={'dark'}>
        <div className={cs('wrap', location.pathname.split('/')[1] + 'Page')}>
          <Header></Header>
          <div className="content">
            {allowed && confirmed && <WrappedComponent />}

            {!allowed && confirmed && (
              <div className="medium-text">
                You don't have permission to access
              </div>
            )}
            {!confirmed && (
              <div className="medium-text">
                Thank you for registration! <br />
                We will approve your request and send you an access confirmation
                as soon as possible!
              </div>
            )}
          </div>
        </div>
      </AmplifyProvider>
    )
  }

  return withAuthenticator(App, {
    components: {
      Header: AuthHeader,
      Footer: AuthFooter,
      SignUp: { FormFields },
    },
    variation: 'default',
    formFields: formFields,
    initialState: 'signIn',
    services: services,
  })
}

export default withAuth
