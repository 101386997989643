import { FC, useContext } from 'react'
import { Area } from '@ant-design/charts'
import Utils from '../../../utils/Utils'
import { DataContext } from '../../../pages/project/ProjectPage'

const max = 100000

const ProjectChart: FC<{ edit: boolean }> = ({ edit = false }) => {
  const { data } = useContext(DataContext)

  const contractPrize = data?.AsCompletedValuePerLot || 0

  const max = Math.pow(10, Math.ceil(Math.log10(contractPrize)))
  const config = {
    data: data?.chartData || [],
    xField: 'type',
    yField: 'value',
    color: ['#00D05C', '#9AA2B1'],
    colorField: 'value',
    line: { color: ['#00D05C', '#9AA2B1'] },
    legend: { position: 'top-right' },
    xAxis: {
      title: {
        text: 'Loan Funding',
      },
    },
    yAxis: {
      title: {
        text: 'Value Per Lot',
      },
      label: {
        formatter: (val: any) => Utils.currencyFormat(val),
      },
      max: max,
      tickMethod: (linear: any) => {
        const interval = Math.ceil(linear.max / 6)
        return [0, interval, contractPrize, ...linear.values, linear.max]
      },

      grid: {
        line: {
          style: {
            stroke: '#9AA2B1',
            lineWidth: 1,
          },
        },
      },
    },
    annotations: [
      {
        type: 'text',
        content: 'LOAN FUNDING',
        position: ['50%', '45%'],
        style: { fill: 'white' },
      },
      {
        type: 'text',
        position: { type: 6, value: contractPrize },
        content: 'Contract Price',
        offsetY: 0,
        style: {
          textBaseline: 'bottom',
          fill: 'white',
        },
      },
      {
        type: 'line',
        start: { type: '0', value: contractPrize },
        end: { type: '12', value: contractPrize },
        style: {
          stroke: 'green',
        },
      },
    ],
    areaStyle: {
      fill: 'l(90) 0.2:#00D05C 1:#02163D',
      fillOpacity: 0.9,
    },
    tooltip: {
      title: 'Value Per Lot',
      formatter: (datum: any) => {
        return { name: datum.type, value: Utils.currencyFormat(datum.value) }
      },
    },
  }

  return (
    <>
      {/* @ts-ignore: */}
      <Area {...config} />
    </>
  )
}

export default ProjectChart
