import moment from 'moment'
import { useContext, useEffect, useCallback, useRef, useState } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

const getGreetingPrefix = (currentTime: Date = new Date()): string => {
  const currentHour = currentTime.getHours()
  const splitAfternoon = 12 // 24hr time to split the afternoon
  const splitEvening = 17 // 24hr time to split the evening

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return 'Good Afternoon'
  } else if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return 'Good Evening'
  }
  // Between dawn and noon
  return 'Good Morning'
}

const getDaysDifference = (startDate: Date, endDate: Date) => {
  const days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
  return !isNaN(days) ? Math.abs(days).toFixed(0) : 0
}

const getMonthDifference = (endDate: Date, startDate: Date) => {
  const months =
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  return !isNaN(months) ? months : 0
}

const currencyFormat = (num: number) => {
  return '$' + (num | 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const dateFormat = (date: any) => {
  return isNaN(Date.parse(date)) ? '' : moment(date).format('L')
}

const dateTimeFormat = (date: any) => {
  return isNaN(Date.parse(date))
    ? ''
    : moment(date).format('MM/DD/YYYY HH:mm:ss')
}

const percentFormat = (num: any) => {
  return (parseFloat(num) || 0).toFixed(2) + '%'
}

const getSorters = (sorter: any) => {
  const arr = sorter.length ? sorter : [sorter]
  return arr
    .filter((item: any) => item.order)
    .reduce(
      (obj: any, item: any) =>
        Object.assign(obj, {
          [['ToInterest', 'ToPrincipal'].includes(item.field)
            ? `ABS(${item.field})`
            : item.field]: item.order === 'ascend' ? 'ASC' : 'DESC',
        }),
      {}
    )
}

const getUserGroups = (user: any) =>
  user.getSignInUserSession()?.getAccessToken()?.payload['cognito:groups']

const isAdmin = (user: any) => {
  const groups = getUserGroups(user)
  return groups ? groups.includes('ADMIN') : false
}

const isInvestor = (user: any) => {
  const groups = getUserGroups(user)
  return groups ? groups.includes('LOTLINE-INVESTOR') : false
}

const getOrderBySorters = (sorters: any) =>
  Object.entries(sorters)
    .map((i) => {
      // if (i[0].includes('Date')) {
      //   i[0] = `STR_TO_DATE(${i[0]}, '%m/%d/%Y')`
      // }
      if (i[0] === 'BorrowerName') {
        return `BorrowerFirstName ${i[1]}, BorrowerLastName ${i[1]}`
      }

      return i.join(' ')
    })
    .join(', ')

const statusColorMap: { [key: string]: string } = {
  Performing: '#16C95B',
  Watch: 'orange',
  NonPerforming: 'yellow',
  Foreclosure: '#FF3600',
  REO: 'violet',
}

const getStatusColor = (status: string) =>
  status in statusColorMap ? statusColorMap[status] : 'transparent'

function useBlocker(blocker: any, when = true) {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) return
    // @ts-ignore:
    const unblock = navigator.block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}

function usePrompt(message: any, when = true, action?: () => void) {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) {
        if (action) action()
        tx.retry()
      }
    },
    [message]
  )

  useBlocker(blocker, when)
}

const editorConfig = {
  readonly: false,
  height: 400,
  theme: 'dark',
  toolbar: true,
  statusbar: false,
  buttons: [
    'bold',
    'strikethrough',
    'underline',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'fontsize',
    'paragraph',
    '|',
    'link',
    'align',
    '|',
  ],
}

const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const statuses = ['Performing', 'Watch', 'NonPerforming', 'Foreclosure', 'REO']

const states = [
  'AA',
  'AE',
  'AL',
  'AK',
  'AP',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'VI',
  'WA',
  'WV',
  'WI',
  'WY',
]

const getDictByKey = (array: any[], key: string) =>
  (array || []).reduce(
    (prev: any, item: any) => ({ ...prev, [item[key]]: item }),
    {}
  )

const useHash: () => [string, (hash: string) => void] = () => {
  const [hash, setHash] = useState(() => window.location.hash)

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash)
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler)
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler)
    }
  }, [])

  const updateHash: (hash: string) => void = useCallback(
    (newHash) => {
      if (newHash !== hash) window.location.hash = newHash
    },
    [hash]
  )

  return [hash, updateHash]
}

export default {
  getGreetingPrefix,
  getMonthDifference,
  getDaysDifference,
  dateFormat,
  dateTimeFormat,
  percentFormat,
  currencyFormat,
  getSorters,
  getOrderBySorters,
  isAdmin,
  isInvestor,
  getUserGroups,
  getStatusColor,
  usePrompt,
  editorConfig,
  statuses,
  states,
  usePrevious,
  getDictByKey,
  useHash,
}
