import { FC, useEffect, useRef, useState, useCallback } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy'
import { ReloadOutlined, SettingOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Avatar,
  Typography,
  List,
  Skeleton,
  Modal,
  Form,
  Checkbox,
  Divider,
  message,
} from 'antd'
import cs from 'classnames'
import moment from 'moment'

import aws_export from '../../../aws-exports'
import Utils from '../../../utils/Utils'
import styles from './NewsWidget.module.scss'

const { Paragraph } = Typography
const CheckboxGroup = Checkbox.Group

const NEWS_LIMIT = 5
const NEWS_INTERVAL_IN_MINUTES = 1

const NewsWidget: FC<{ showTitle?: boolean }> = ({ showTitle = true }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const isAdmin = user ? Utils.isAdmin(user) : false
  const [form] = Form.useForm()
  const ref = useRef<HTMLDivElement>(null)
  const [loadingNews, setLoadingNews] = useState(false)
  const [total, setTotal] = useState(0)
  const [newsOffset, setNewsOffset] = useState(0)
  const [news, setNews] = useState<any>([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [checkedList, setCheckedList] = useState<any[]>([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [rssIds, setRssIds] = useState([])
  const [rssOptions, setRssOptions] = useState([])

  const fetchRss = async (): Promise<any> => {
    let res = await API.get('newsApi', '/rss', {
      queryStringParameters: { Status: 'show' },
    }).catch((e) => message.error("Can't upload settings. Try again."))
    setRssIds((res?.data || []).map((item: any) => item?.id))
    setRssOptions(
      (res?.data || []).map((item: any) => ({
        label: (
          <span className={styles.label}>
            {item?.feed}
            {item?.image && (
              <AmplifyS3Image
                imgKey={item?.image}
                className={cs(styles.icon, styles[item?.feed])}
              />
            )}
          </span>
        ),
        value: item?.id,
      }))
    )
  }

  const fetchRssSettings = async (): Promise<any> => {
    const res = await API.get('newsApi', '/rssSettings', {
      queryStringParameters: { FilterUser: user.username },
    }).catch((e) => message.error("Can't upload settings. Try again."))

    setCheckedList(res?.data?.Settings ? JSON.parse(res.data.Settings) : rssIds)
  }

  const onChange = (list: any[]) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < rssIds.length)
    setCheckAll(list.length === rssIds.length)
  }

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? rssIds : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const onReload = () => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
    reloadNews()
  }

  const fetchNews = async (newOffset: any = null): Promise<any> => {
    if (loadingNews) return

    setLoadingNews(true)
    const res = await API.get('newsApi', '/items', {
      queryStringParameters: {
        PageSize: NEWS_LIMIT,
        Offset: newOffset || newsOffset,
        FilterUser: user.username,
      },
    }).catch((e) => message.error("Can't upload news. Try again."))

    setLoadingNews(false)
    setTotal(res?.total)
    setNews((prevData: any) =>
      newOffset !== null
        ? res?.data || []
        : [...(prevData || []), ...(res?.data || [])]
    )
  }

  const reloadNews = useCallback(() => {
    fetchNews(0)
    setNewsOffset(0)
  }, [])

  const loadMoreNews = useCallback(
    () => setNewsOffset(newsOffset + NEWS_LIMIT),
    [newsOffset]
  )

  useEffect(() => {
    form.setFieldsValue({ feeds: checkedList })
    setIndeterminate(!!checkedList.length && checkedList.length < rssIds.length)
    setCheckAll(checkedList.length === rssIds.length)
  }, [form, checkedList])

  useEffect(() => {
    Amplify.configure(aws_export)

    fetchRss()

    const id = setInterval(reloadNews, NEWS_INTERVAL_IN_MINUTES * 60000)
    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    fetchNews()
  }, [newsOffset])

  useEffect(() => {
    fetchRssSettings()
  }, [rssIds])

  const submitFn = async (vals: any): Promise<any> => {
    await API.put('newsApi', '/rssSettings', {
      body: { Username: user.username, Settings: vals.feeds },
    }).catch((e) => message.error("Can't save settings. Try again."))

    setIsModalVisible(false)
    onReload()
  }

  return (
    <div className={cs(styles.news, 'news')}>
      {showTitle && (
        <div className={styles.blockTitle}>
          News
          <span className={styles.btns}>
            <ReloadOutlined onClick={onReload} />
            <SettingOutlined onClick={() => setIsModalVisible(true)} />
            <Modal
              title="News Settings"
              visible={isModalVisible}
              onOk={form.submit}
              onCancel={() => setIsModalVisible(false)}
              okText="Save"
              forceRender
            >
              <Form
                id="scrollableDiv"
                layout="vertical"
                className={cs('loansPage', styles.form)}
                form={form}
                onFinish={submitFn}
              >
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Check all
                </Checkbox>
                <Divider />
                <Form.Item name="feeds">
                  <CheckboxGroup options={rssOptions} onChange={onChange} />
                </Form.Item>
              </Form>
            </Modal>
          </span>
        </div>
      )}

      <div id="scrollableDiv" ref={ref} className={styles.content}>
        <InfiniteScroll
          dataLength={news.length}
          next={loadMoreNews}
          hasMore={news.length < total}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={''}
          scrollableTarget="scrollableDiv"
        >
          <List
            itemLayout="vertical"
            size="large"
            pagination={false}
            dataSource={news}
            renderItem={(item: any) => {
              const link = item.link.replace('nitter.net/', 'twitter.com/')
              return (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={<Avatar src={item?.image} />}
                    title={
                      <Paragraph ellipsis={{ rows: 2 }}>
                        <a href={link} target="_blank">
                          {item.title}
                        </a>
                      </Paragraph>
                    }
                    description={
                      <div className={styles.description}>
                        {moment(item?.pubDate).format('L')}{' '}
                        <a href={link} target="_blank">
                          {item?.icon ? (
                            <AmplifyS3Image
                              imgKey={item?.icon}
                              className={cs(styles.icon, styles[item?.feed])}
                            />
                          ) : (
                            item?.feed
                          )}
                        </a>
                      </div>
                    }
                  />
                  <Paragraph ellipsis={{ rows: 3 }}>
                    {(item?.contentSnippet || '')
                      .replace(/&quot;/g, "'")
                      .replace(/(<([^>]+)>)/gi, '')}
                  </Paragraph>
                </List.Item>
              )
            }}
          />
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default NewsWidget
