import { FC, memo } from 'react'
import { Source, Layer } from 'react-map-gl'

const PolygonsFeature: FC<{ data: null | any[] }> = ({ data }) => {
  const sourceData: any = { type: 'FeatureCollection', features: data }

  const dataLayer = {
    id: 'data',
    type: 'fill',
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': 0.3,
    },
  }
  return (
    <>
      {data && (
        <Source type="geojson" data={sourceData}>
          {/* @ts-ignore: */}
          <Layer {...dataLayer} />
        </Source>
      )}
    </>
  )
}

export default memo(PolygonsFeature)
