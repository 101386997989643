import { FC, useState, useEffect } from 'react'
import Amplify, { API } from 'aws-amplify'
import { Table, Pagination, message } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import aws_export from '../../aws-exports'
import Utils from '../../utils/Utils'
import NotificationsStatusesTable from '../../components/notifications/NotificationsStatusesTable'
import ProjectsTable from '../../components/common/Table'
import styles from './NotificationsPage.module.scss'

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
    render: (from: any, record: any) =>
      from === 'system' ? from : record?.fromEmail,
  },
  {
    title: 'To Group',
    dataIndex: 'toGroup',
    key: 'toGroup',
  },
  {
    title: 'To User',
    dataIndex: 'toEmail',
    key: 'toEmail',
    render: (toEmail: any, record: any) => (record?.toGroup ? '' : toEmail),
  },
  {
    title: 'Status',
    dataIndex: 'notificationStatus',
    key: 'notificationStatus',
    render: (notificationStatus: any, record: any) =>
      record?.toGroup ? '' : record.userStatus || notificationStatus,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (date: any) => Utils.dateFormat(date),
  },
]

const NotificationsPage: FC = () => {
  const pageSize = 5
  const [notifications, setNotifications] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const fetchNotifications = async (offset = 0): Promise<any> => {
    setLoading(true)
    const res = await API.get('notificationsApi', '/items', {
      queryStringParameters: {
        AllItems: true,
        Offset: offset,
        PageSize: pageSize,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    setNotifications(res?.data || [])
    setTotal(res?.total || 0)
    setLoading(false)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  return (
    <>
      <h3 className={styles.title}>Notifications</h3>
      <ProjectsTable
        data={notifications}
        columns={columns}
        rowKey="id"
        total={total}
        loading={loading}
        fetchData={fetchNotifications}
        expandedRowRender={(record: any) => (
          <NotificationsStatusesTable id={record.id} />
        )}
        expandIcon={({
          expanded = true,
          onExpand = (record: any, e: any) => {},
          record = {},
        }) =>
          record.toGroup &&
          (!expanded ? (
            <DownOutlined
              className={styles.icon}
              onClick={(e) => onExpand(record, e)}
            />
          ) : (
            <UpOutlined
              className={styles.icon}
              onClick={(e) => onExpand(record, e)}
            />
          ))
        }
      />
    </>
  )
}

export default NotificationsPage
