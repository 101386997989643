import { FC, useState, useEffect } from 'react'
import Amplify, { API } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Modal, Typography, Popconfirm, message } from 'antd'
import { CloseOutlined, FormOutlined } from '@ant-design/icons'
import cs from 'classnames'
import moment from 'moment'

import aws_export from '../../../aws-exports'
import Utils from '../../../utils/Utils'
import styles from './NotificationsWidget.module.scss'

const { Paragraph } = Typography
const { confirm } = Modal

const NotificationsWidget: FC = () => {
  const { user } = useAuthenticator((context) => [context.user])
  const [notifications, setNotifications] = useState<null | any[]>(null)
  const [total, setTotal] = useState<number>(0)
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0)
  const isAdmin = Utils.isAdmin(user)

  const fetchNotifications = async (): Promise<any> => {
    const res = await API.get('notificationsApi', '/items', {
      queryStringParameters: {
        PageSize: 5,
      },
    }).catch((e) => message.error("Can't upload notifications. Try again."))

    setNotifications(res?.data || [])
    setTotal(res?.total || 0)
    setUnreadNotifications(
      res?.data.reduce(
        (res: any, item: any) =>
          res + (!item.userStatus && item.status === 'new' ? 1 : 0),
        0
      )
    )
  }

  const updateNotification = async (id: any, status: any): Promise<any> => {
    const res = await API.put('notificationsApi', `/item/${id}`, {
      body: {
        status,
      },
    })
    const newItems = (notifications || [])
      .map((item: any) => (item.id === res.data?.id ? res.data : item))
      .filter(
        (item) => item.userStatus !== 'deleted' && item.status !== 'deleted'
      )
    setNotifications(newItems)
  }

  const deleteNotification = (id: any) => updateNotification(id, 'deleted')
  const readNotification = (id: any) => updateNotification(id, 'read')

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchNotifications()
  }, [])

  const showMessage = (item: any) => {
    confirm({
      icon: false,
      content: (
        <>
          <div>
            <span className={styles.label}>From:</span> {item.from}
          </div>
          <div>
            <span className={styles.label}>Date:</span>{' '}
            {moment(item.date).format('L')}
          </div>
          <div>
            <span className={styles.label}>Message:</span> {item.message}
          </div>
        </>
      ),
      okCancel: false,
      onOk() {
        readNotification(item.id)
      },
    })
  }

  return (
    <>
      <div className={styles.blockTitle}>
        {isAdmin ? (
          <>
            <Link to="/notifications">
              {unreadNotifications > 0 && (
                <span
                  className="status"
                  style={{
                    background: 'red',
                    marginRight: 5,
                  }}
                ></span>
              )}
              Notifications{' '}
              {notifications && (
                <span>
                  ({unreadNotifications}/{total})
                </span>
              )}
            </Link>
            <Link to="/send-notification">
              <FormOutlined />
            </Link>
          </>
        ) : (
          <div>Notifications</div>
        )}
      </div>
      <div>
        {notifications &&
          notifications.map((item: any) => (
            <div
              key={item.id}
              className={cs(
                styles[item.userStatus || item.status],
                styles.item
              )}
            >
              <Paragraph
                ellipsis={{ rows: 1 }}
                onClick={showMessage.bind(null, item)}
              >
                {item?.message}
              </Paragraph>
              <Popconfirm
                icon={false}
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={deleteNotification.bind(null, item.id)}
              >
                <CloseOutlined />
              </Popconfirm>
            </div>
          ))}
      </div>
    </>
  )
}

export default NotificationsWidget
