import { FC, useEffect, useState } from 'react'
import Amplify, { API } from 'aws-amplify'
import { Form, Input, Select, Table, message } from 'antd'
import { Column, Pie } from '@ant-design/charts'
import debounce from 'lodash.debounce'

import aws_export from '../../aws-exports'
import Utils from '../../utils/Utils'
import styles from './AdminPage.module.scss'

const USER_ID = 'lotline'
const MODEL_PATH = 'LotLine'
const { Option } = Select

const AdminPage: FC = () => {
  const [form] = Form.useForm()
  const [reference, setReference] = useState<any>([])
  const [widgets, setWidgets] = useState<any[]>([])

  // Fetch dashboard model info and get constant info for widgets
  const fetchModel = async () => {
    const data = await API.get('modelshopApi', '/items', {
      queryStringParameters: {
        url: `/models/${USER_ID}/${MODEL_PATH}`,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    if (!data?.model) message.error("Can't upload items. Try again.")

    const charts = data?.model?.charts.reduce(
      (res: any, chart: any) => ({ ...res, [chart?.id]: chart }),
      {}
    )
    let reference: any
    let result = data?.model?.dashboardGroups[0]?.dashboards[0]?.widgets || []

    result = await Promise.all(
      result.map(async (widget: any) => {
        if (widget?.type === 'REFERENCE') {
          const source = JSON.parse(widget?.source)
          const dashboard = encodeURIComponent(
            widget.id.split(':' + widget.type)[0]
          )
          const title = encodeURIComponent(widget.title)
          const res = await API.get('modelshopApi', '/items', {
            queryStringParameters: {
              url: `/dashboard/${USER_ID}/${MODEL_PATH}/widget/${dashboard}/${title}/entities`,
              fieldName: source?.selectedField,
              viewName: source?.selectedView,
            },
          })
          reference = {
            name: source?.selectedView,
            value: res?.fieldValues[0]?.id,
          }
          return {
            ...widget,
            name: source?.selectedView,
            values: res?.fieldValues.map((val: any) => ({
              value: val.id,
              label: val.name,
            })),
          }
        }
        if (widget?.type === 'CHART') {
          const chart = charts[widget?.sourceName]
          const config = getConfig(chart)
          return { ...widget, ...chart, config }
        }

        return widget
      })
    )
    setWidgets(result)

    if (reference) {
      form.setFieldsValue({ [reference.name]: reference.value })
      setReference([
        {
          entityName: reference.name,
          entityId: reference.value,
        },
      ])
    }
  }

  const updateWidgetsData = async () => {
    if (widgets.length === 0) return
    const res = await Promise.all(
      widgets.map(async (widget: any) => {
        if (widget?.type === 'FORM') {
          return await fetchFormData(widget)
        }
        if (widget?.type === 'CHART') {
          return await fetchChartData(widget)
        }
        if (widget?.type === 'VIEW') {
          return await fetchViewData(widget)
        }
        return widget
      })
    )

    setWidgets(res)
  }

  const onInputChange = debounce((widget: any) => updateFormData(widget), 1000)

  // Update data for forms
  const updateFormData = async (widget: any) => {
    const values = form.getFieldsValue()
    widget.form = widget.form.map((item: any) => ({
      ...item,
      value: item?.format.includes('%')
        ? values[item.name] / 100
        : values[item.name],
    }))

    const dashboard = encodeURIComponent(widget.id.split(':' + widget.type)[0])
    const title = encodeURIComponent(widget.title)

    await API.put('modelshopApi', '/items', {
      queryStringParameters: {
        url: `/dashboard/${USER_ID}/${MODEL_PATH}/widget/${dashboard}/${title}`,
        reference: JSON.stringify(reference),
        layoutOnly: false,
      },
      body: { dashboardData: { data: widget?.form } },
    })
    updateWidgetsData()
  }

  // Fetch data for forms
  const fetchFormData = async (widget: any) => {
    const dashboard = encodeURIComponent(widget.id.split(':' + widget.type)[0])
    const title = encodeURIComponent(widget.title)

    const res = await API.get('modelshopApi', '/items', {
      queryStringParameters: {
        url: `/dashboard/${USER_ID}/${MODEL_PATH}/widget/${dashboard}/${title}`,
        reference: JSON.stringify(reference),
        layoutOnly: false,
      },
    })
    const result = res?.dashboardData[0]?.data.map((item: any) => {
      let val = item?.value
      if (item?.format.includes('%')) val *= 100
      if (['CURRENCY', 'DECIMAL'].includes(item?.type)) {
        val = parseFloat(val).toFixed(2)
      }

      return { ...item, value: val }
    })
    const values = result.reduce(
      (prev: any, item: any) => ({
        ...prev,
        [item?.name]: item?.value,
      }),
      {}
    )
    form.setFieldsValue(values)

    return { ...widget, form: result }
  }

  // Create config for a chart
  const getConfig = (data: any) => {
    const serie = data?.series[0]
    if (!['COLUMN', 'PIE'].includes(serie?.seriesType)) return {}

    const colors = JSON.parse(serie?.color)
    const config = {
      id: data?.id,
      type: serie?.seriesType,
      color: colors ? Object.values(colors) : [],
    }
    if (serie?.seriesType === 'COLUMN') {
      return {
        ...config,
        xField: serie?.xfield,
        yField: serie?.yfield,
        seriesField: serie?.byField[0],
      }
    }
    return {
      ...config,
      angleField: serie?.angleField,
      colorField: serie?.byField[0],
    }
  }

  // Fetch data for charts
  const fetchChartData = async (widget: any): Promise<any> => {
    const data = await API.get('modelshopApi', '/items', {
      queryStringParameters: {
        url: `/chart/${USER_ID}/${MODEL_PATH}/data/${encodeURIComponent(
          widget.title
        )}`,
        reference: JSON.stringify(reference),
      },
    })

    return { ...widget, data: data?.chartPoints }
  }

  const fetchViewData = async (widget: any) => {
    const title = encodeURIComponent(widget.title)

    const res = await API.get('modelshopApi', '/items', {
      queryStringParameters: {
        url: `/extjs/${USER_ID}/${MODEL_PATH}/view/${widget?.entityName}/${title}_Grid`,
        reference: JSON.stringify(reference),
      },
    })
    const fields = res?.columns
      .filter((item: any) => !item.hidden)
      .map((item: any) => ({
        dataIndex: item.dataIndex,
        key: item.fieldName,
        title: item.fieldName,
        render: (val: any) =>
          item?.fieldType === 'CURRENCY'
            ? Utils.currencyFormat(val)
            : item?.fieldType === 'DECIMAL'
            ? Utils.percentFormat(val)
            : val,
      }))
    const res2 = await API.get('modelshopApi', '/items', {
      queryStringParameters: {
        url: `/data/${USER_ID}/${MODEL_PATH}/${title}/agData`,
        reference: JSON.stringify(reference),
        includeErrors: false,
        includeFlags: false,
        includeRules: false,
        includeEdited: false,
        expandGroups: true,
        startRow: 0,
        endRow: 99,
      },
    })

    return { ...widget, fields, records: res2?.records }
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchModel()
  }, [])

  useEffect(() => {
    updateWidgetsData()
  }, [reference])

  return (
    <div className={styles.adminPage}>
      <div className={styles.title}>Transaction Data</div>
      <Form form={form} layout="vertical">
        <div className={styles.grid}>
          {widgets.map((item, i) => {
            const layout = JSON.parse(item?.layout)
            return (
              <div
                className={styles.block}
                key={i}
                style={{
                  gridColumnStart: layout?.x,
                  gridRowStart: layout?.y,
                  gridRowEnd: layout?.h,
                }}
              >
                <div className={styles.title}>{item?.title}</div>

                {item.type === 'FORM' &&
                  item?.form &&
                  item.form.map((field: any) => (
                    <Form.Item
                      name={field?.name}
                      label={field?.name}
                      key={field?.name}
                    >
                      {['INTEGER', 'DECIMAL', 'CURRENCY'].includes(
                        field?.type
                      ) && (
                        <Input
                          name={field?.name}
                          disabled={!field?.editable}
                          prefix={field?.type === 'CURRENCY' ? '$' : ''}
                          suffix={field?.format.includes('%') ? '%' : ''}
                          onChange={() => onInputChange(item)}
                        />
                      )}
                    </Form.Item>
                  ))}

                {item.type === 'REFERENCE' && (
                  <Form.Item name={item?.entityName}>
                    <Select
                      allowClear={false}
                      placeholder={item.title}
                      optionFilterProp="children"
                      onChange={(val) =>
                        setReference([
                          {
                            entityName: item.entityName,
                            entityId: val,
                          },
                        ])
                      }
                    >
                      {item?.values.map((val: any) => (
                        <Option key={val?.value} value={val?.value}>
                          {val?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {/* View */}
                {item?.type === 'VIEW' && (
                  <div className={styles.table}>
                    <Table
                      id="scrollableDiv"
                      rowKey={(record) => record?.id}
                      dataSource={item?.records}
                      columns={item?.fields}
                      pagination={false}
                    />
                  </div>
                )}

                {/* Charts */}
                {item?.config && item?.config.type === 'COLUMN' && (
                  <Column
                    {...item?.config}
                    data={item?.data || []}
                    columnStyle={{ fillOpacity: 0.8 }}
                    legend={true}
                  />
                )}
                {item?.config && item?.config.type === 'PIE' && (
                  <Pie
                    {...item?.config}
                    data={item?.data || []}
                    radius="1"
                    pieStyle={{ fillOpacity: 0.8, stroke: '#152039' }}
                    label={{ content: '' }}
                    legend={true}
                  />
                )}
              </div>
            )
          })}
        </div>
      </Form>
    </div>
  )
}

export default AdminPage
