import { FC, useEffect, useState } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy'
import { message } from 'antd'

import aws_export from '../../aws-exports'
import Button from '../../components/common/Button'
import ProjectsTable from '../../components/common/Table'
import styles from './AdminPage.module.scss'

const NewsPage: FC = () => {
  const pageSize = 10
  const navigate = useNavigate()
  const [rss, setRss] = useState([])
  const [total, setTotal] = useState<null | number>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchRss = async (offset = 0): Promise<any> => {
    setLoading(true)
    const res = await API.get('newsApi', '/rss', {
      queryStringParameters: {
        Offset: offset,
        PageSize: pageSize,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    setRss(res?.data || [])
    setTotal(res?.total || 0)
    setLoading(false)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  const columns = [
    {
      title: 'Feed',
      dataIndex: 'feed',
      key: 'feed',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <span
          className="status"
          style={{
            background: status === 'show' ? 'green' : 'red',
          }}
        ></span>
      ),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image: any) =>
        image ? <AmplifyS3Image imgKey={image} className={styles.image} /> : '',
    },
    {
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (id: any) => (
        <Button text="Edit" isSmall onClick={() => navigate(`/news/${id}`)} />
      ),
    },
  ]

  return (
    <>
      <h3 className={styles.title}>
        <span>Rss Feeds</span>
        <span className={styles.btn}>
          <Button text="Add" onClick={() => navigate(`/add-news`)} />
        </span>
      </h3>

      <ProjectsTable
        data={rss}
        columns={columns}
        total={total}
        loading={loading}
        rowKey="id"
        fetchData={fetchRss}
      />
    </>
  )
}

export default NewsPage
