import React from 'react'
import cs from 'classnames'
import { Column, Scatter, Pie } from '@ant-design/charts'
import { Carousel, Progress, Table } from 'antd'
import styles from './Market.module.scss'

const Market: React.FC = () => {
  const dataColumn = [
    {
      type: 'GED',
      value: 20,
    },
    {
      type: 'HS',
      value: 220,
    },
    {
      type: 'Assc',
      value: 120,
    },
    {
      type: 'Bach',
      value: 320,
    },
    {
      type: 'Mstr',
      value: 240,
    },
    {
      type: 'Dr',
      value: 40,
    },
  ]

  const configColumn = {
    data: dataColumn,
    xField: 'type',
    yField: 'value',
    columnStyle: {
      fill: 'l(90) 0.2:#00D05C 1:#02163D',
      fillOpacity: 0.9,
    },
    label: {
      content: (originData: any) => {
        const val = parseFloat(originData.value)

        return (val / 10).toFixed(1) + '%'
      },
      offset: 10,
    },
    yAxis: {
      nice: true,
      // tickCount: 2,
      // verticalLimitLength: 1,
    },
  }

  const dataScatter = [
    {
      Type: 'Type0',
      sqft: 2000,
      Value: 250000,
    },
    {
      Type: 'Type1',
      sqft: 6000,
      Value: 350000,
    },
    {
      Type: 'Type2',
      sqft: 8000,
      Value: 450000,
    },
    {
      Type: 'Type3',
      sqft: 5000,
      Value: 550000,
    },
    {
      Type: 'Type4',
      sqft: 12000,
      Value: 200000,
    },
    {
      Type: 'Type0',
      sqft: 2200,
      Value: 220000,
    },
    {
      Type: 'Type1',
      sqft: 6400,
      Value: 380000,
    },
    {
      Type: 'Type2',
      sqft: 9000,
      Value: 480000,
    },
    {
      Type: 'Type3',
      sqft: 8000,
      Value: 950000,
    },
    {
      Type: 'Type4',
      sqft: 11000,
      Value: 220000,
    },
    {
      Type: 'Type0',
      sqft: 5000,
      Value: 200000,
    },
    {
      Type: 'Type1',
      sqft: 5000,
      Value: 330000,
    },
    {
      Type: 'Type2',
      sqft: 5000,
      Value: 650000,
    },
    {
      Type: 'Type3',
      sqft: 5400,
      Value: 580000,
    },
    {
      Type: 'Type4',
      sqft: 10000,
      Value: 220000,
    },
  ]

  const configScatter = {
    appendPadding: 10,
    data: dataScatter,
    xField: 'sqft',
    yField: 'Value',
    shape: 'circle',
    colorField: 'Type',
    size: 10,
    legend: false,
    color: [
      'r(0.5,0.5,0.7) 0:rgba(0, 208, 92, 1) 1:rgba(0, 208, 92, 0.2)',
      'r(0.5,0.5,0.7) 0:rgba(206, 166, 62, 1) 1:rgba(206, 166, 62, 0.2)',
    ],
    pointStyle: {
      stroke: 'transparent',
    },
  }

  const configPie = {
    appendPadding: 10,
    data: [
      {
        type: 'Education',
        value: 46,
      },
      {
        type: 'Demographic',
        value: 56,
      },
      {
        type: 'Economic',
        value: 51,
      },
      {
        type: 'Housing',
        value: 58,
      },
      {
        type: 'Crime',
        value: 67,
      },
      {
        type: 'Envioment',
        value: 65,
      },
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.9,
    height: 300,
    label: {
      content: '',
    },
    legend: false,
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'white',
        },
        content: '57\nLivability\nScore',
      },
    },
    pieStyle: {
      fill: '#00D05C',
      stroke: '#152039',
      lineWidth: 10,
    },
  }

  const progressOptions = {
    // trailColor="#02163D"
    strokeColor: '#00D05C',
    strokeLinecap: 'square',
    strokeWidth: 20,
  }

  return (
    <div className={cs(styles.market, 'market')}>
      {/*<Carousel afterChange={() => {}}>*/}
      {/*  <div>*/}
      {/*    <p className={styles.title}>Liability</p>*/}
      {/*    /!*  @ts-ignore: *!/*/}
      {/*    <Pie {...configPie} />*/}
      {/*    <label>Education</label>*/}
      {/*    /!*  @ts-ignore: *!/*/}
      {/*    <Progress {...progressOptions} percent={46} />*/}
      {/*    <label>Demographic</label>*/}
      {/*    /!*  @ts-ignore: *!/*/}
      {/*    <Progress {...progressOptions} percent={56} />*/}
      {/*    <label>Economic</label>*/}
      {/*    /!*  @ts-ignore: *!/*/}
      {/*    <Progress {...progressOptions} percent={51} />*/}
      {/*    <label>Housing</label>*/}
      {/*    /!*  @ts-ignore: *!/*/}
      {/*    <Progress {...progressOptions} percent={58} />*/}
      {/*    <label>Crime</label>*/}
      {/*    /!*  @ts-ignore: *!/*/}
      {/*    <Progress {...progressOptions} percent={67} />*/}
      {/*    <label>Envioment</label>*/}
      {/*    /!*  @ts-ignore: *!/*/}
      {/*    <Progress {...progressOptions} percent={65} />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <p className={styles.title}>Education</p>*/}
      {/*    <Column {...configColumn} />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <p className={styles.title}>Neighborhood Listings</p>*/}
      {/*    /!*  @ts-ignore: *!/*/}
      {/*    <Scatter {...configScatter} />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <p className={styles.title}>New Home Market</p>*/}
      {/*    <Table*/}
      {/*      pagination={false}*/}
      {/*      dataSource={[*/}
      {/*        {*/}
      {/*          title: 'Median Home Price',*/}
      {/*          amount: '$341,045',*/}
      {/*          percent: '5.20%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'Average Price PSF',*/}
      {/*          amount: '$155.36',*/}
      {/*          percent: '4.10%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'No. Of Closings - Month',*/}
      {/*          amount: '589',*/}
      {/*          percent: '12.20%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'No. Of Closings - YTD',*/}
      {/*          amount: '2,502',*/}
      {/*          percent: '23.30%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'No. Of Closings - LTM',*/}
      {/*          amount: '8,406',*/}
      {/*          percent: '18.70%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'New Home Permits - Month',*/}
      {/*          amount: '828',*/}
      {/*          percent: '0.00%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'New Home Permits - YTD',*/}
      {/*          amount: '3,068',*/}
      {/*          percent: '7.60%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'New Home Permits - LTM',*/}
      {/*          amount: '8,734',*/}
      {/*          percent: '12.40%',*/}
      {/*        },*/}
      {/*      ]}*/}
      {/*      columns={[*/}
      {/*        {*/}
      {/*          title: '',*/}
      {/*          dataIndex: 'title',*/}
      {/*          key: 'title',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: '',*/}
      {/*          dataIndex: 'amount',*/}
      {/*          key: 'amount',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: '',*/}
      {/*          dataIndex: 'percent',*/}
      {/*          key: 'percent',*/}
      {/*        },*/}
      {/*      ]}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <p className={styles.title}>Existing Home Market</p>*/}
      {/*    <Table*/}
      {/*      pagination={false}*/}
      {/*      dataSource={[*/}
      {/*        {*/}
      {/*          title: 'Median Home Price',*/}
      {/*          amount: '$218,000',*/}
      {/*          percent: '11.80%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'Average Price PSF',*/}
      {/*          amount: '$133.80',*/}
      {/*          percent: '10.10%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'No. Of Closings - Month',*/}
      {/*          amount: '4,025',*/}
      {/*          percent: '-1.10%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'No. Of Closings - YTD',*/}
      {/*          amount: '15,009',*/}
      {/*          percent: '5.30%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'No. Of Closings - LTM',*/}
      {/*          amount: '47,665',*/}
      {/*          percent: '5.90%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'MLS Listings - Available',*/}
      {/*          amount: '5,940',*/}
      {/*          percent: '-31.10%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'Effective MLS Inventory',*/}
      {/*          amount: '1.7',*/}
      {/*          percent: '-31.10%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'New/Existing Median',*/}
      {/*          amount: '1.56',*/}
      {/*          percent: '',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'Y/Y Change',*/}
      {/*          amount: '',*/}
      {/*          percent: '20.00%',*/}
      {/*        },*/}
      {/*      ]}*/}
      {/*      columns={[*/}
      {/*        {*/}
      {/*          title: '',*/}
      {/*          dataIndex: 'title',*/}
      {/*          key: 'title',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: '',*/}
      {/*          dataIndex: 'amount',*/}
      {/*          key: 'amount',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: '',*/}
      {/*          dataIndex: 'percent',*/}
      {/*          key: 'percent',*/}
      {/*        },*/}
      {/*      ]}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <p className={styles.title}>Lot Supply Counts</p>*/}
      {/*    <Table*/}
      {/*      pagination={false}*/}
      {/*      dataSource={[*/}
      {/*        {*/}
      {/*          title: 'LTM Sales',*/}
      {/*          market: '8,406',*/}
      {/*          submarket: '1,050',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: "VDL's",*/}
      {/*          market: '14,200',*/}
      {/*          submarket: '1,250',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: '"A-B" VDL\'s',*/}
      {/*          market: '10,500',*/}
      {/*          submarket: '800',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'Y/Y Change',*/}
      {/*          market: '-10.0%',*/}
      {/*          submarket: '-15.0%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: "VDL's in 2008",*/}
      {/*          market: '32,000',*/}
      {/*          submarket: '4,500',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: "Current VDL's % o 2008",*/}
      {/*          market: '44.4%',*/}
      {/*          submarket: '27.8%',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: '"A-B" VDL/Months\' Sales',*/}
      {/*          market: '15.70',*/}
      {/*          submarket: '9.1',*/}
      {/*        },*/}
      {/*      ]}*/}
      {/*      columns={[*/}
      {/*        {*/}
      {/*          title: '',*/}
      {/*          dataIndex: 'title',*/}
      {/*          key: 'title',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'Market',*/}
      {/*          dataIndex: 'market',*/}
      {/*          key: 'market',*/}
      {/*        },*/}
      {/*        {*/}
      {/*          title: 'Submarket',*/}
      {/*          dataIndex: 'submarket',*/}
      {/*          key: 'submarket',*/}
      {/*        },*/}
      {/*      ]}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</Carousel>*/}
    </div>
  )
}

export default Market
