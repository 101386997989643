import { FC, useState, useEffect } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import {
  Form,
  message,
  Input,
  Breadcrumb,
  Tabs,
  Divider,
  Select,
  DatePicker,
  Checkbox,
} from 'antd'
import cs from 'classnames'
import moment from 'moment'

import aws_export from '../../aws-exports'
import Utils from '../../utils/Utils'
import Button from '../../components/common/Button'
import styles from './ProfilePage.module.scss'

const { TabPane } = Tabs
const { Option } = Select
const dateFormat = 'YYYY-MM-DD'

const ProfilePage: FC = () => {
  const { edit, id } = useParams()
  const location = useLocation()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState<any>({})
  const [usePayee, setUsePayee] = useState<boolean>()
  const isUserProfile = !!location?.pathname.includes('/user-profile')
  const auth = useAuthenticator((context) => [context.user])
  const user = isUserProfile && id ? { username: id } : auth.user

  let [isBlocking, setIsBlocking] = useState<boolean | null>(null)
  Utils.usePrompt('Are you sure you want to leave?', !!isBlocking)

  const fetchUser = async (): Promise<any> => {
    const res = await API.get('usersApi', '/user/' + user.username, {}).catch(
      (e) => message.error("Can't upload profile. Try again.")
    )
    if (res?.AccountType) res.AccountType = String(res.AccountType)
    if (res?.ServiceStatus) res.ServiceStatus = String(res.ServiceStatus)
    res.Birthday = isNaN(Date.parse(res.Birthday))
      ? null
      : moment(res.Birthday, dateFormat)
    setCurrentUser(res || {})
    setUsePayee(res?.UsePayee === 1)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchUser()
  }, [])

  useEffect(() => {
    if (currentUser?.Account) {
      form.setFieldsValue(currentUser)
    }
  }, [form, currentUser])

  const submitFn = async (values: any): Promise<any> => {
    API.put('usersApi', '/user/' + user.username, {
      body: {
        ...currentUser,
        ...values,
        FullName: `${values.FirstName} ${values.LastName}`,
      },
    })
      .catch((e) => message.error(e.response.data?.message))
      .finally(() => {
        message.success('Profile was updated')
        setIsBlocking(false)
      })
  }

  useEffect(() => {
    if (isBlocking === false) {
      navigate(`/profile`)
    }
  }, [isBlocking])

  return (
    <>
      <div className="breadcrumbWrapper">
        <div className="breadcrumbContent">
          <Breadcrumb>
            {edit && (
              <Breadcrumb.Item>
                <a onClick={() => navigate('/profile')}>Back</a>
              </Breadcrumb.Item>
            )}
            {isUserProfile && (
              <Breadcrumb.Item>
                <a onClick={() => navigate('/users')}>Back</a>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item> {edit ? 'Edit' : ''} Profile</Breadcrumb.Item>
          </Breadcrumb>
          {!edit && !isUserProfile && (
            <span className={styles.saveBtn}>
              <Button text="Edit" onClick={() => navigate(`/profile/edit`)} />
            </span>
          )}
          {edit && (
            <span className={styles.saveBtn}>
              <Button text="Save" onClick={form.submit} />
              <Button text="Cancel" onClick={() => navigate(`/profile`)} />
            </span>
          )}
        </div>
      </div>
      <h3 className={styles.title}>{edit ? 'Edit' : ''} Profile</h3>
      <Form
        layout="vertical"
        // layout={edit ? 'vertical' : 'horizontal'}
        className={cs(styles.form, edit ? styles.formEdit : styles.formView)}
        form={form}
        onChange={() => setIsBlocking(true)}
        onFinish={(vals) => {
          submitFn(vals).then((val) => {
            if (val == 0) {
              form.resetFields()
            }
          })
        }}
        requiredMark={!!edit}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="General" key="1">
            <div className={styles.tab}>
              <div>
                <Divider orientation="left">Name & Salutation</Divider>
                <Form.Item
                  name="FirstName"
                  key="FirstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: 'Enter your First name',
                    },
                  ]}
                >
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item
                  name="LastName"
                  key="LastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: 'Enter your Last name',
                    },
                  ]}
                >
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item name="MI" key="MI" label="MI">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item
                  name="Salutation"
                  key="Salutation"
                  label="Salutation"
                >
                  <Input disabled={!edit} />
                </Form.Item>
                <Divider orientation="left">Mailing Address</Divider>
                <Form.Item name="Street" key="Street" label="Street">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item name="City" key="City" label="City">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item name="State" key="State" label="State">
                  <Select disabled={!edit}>
                    {Utils.states.map((state) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="ZipCode" key="ZipCode" label="Zip">
                  <Input disabled={!edit} />
                </Form.Item>

                <Divider orientation="left">E-mail & Delivery Options</Divider>
                <Form.Item name="EmailAddress" key="EmailAddress" label="Email">
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item name="EmailFormat" key="EmailFormat" label="Format">
                  <Select disabled={!edit}>
                    <Option key={'Text'} value={0}>
                      Plain Text
                    </Option>
                    <Option key={'HTML'} value={1}>
                      HTML
                    </Option>
                  </Select>
                </Form.Item>
                <Divider orientation="left">Delivery</Divider>
                <Form.Item
                  name="DeliveryPrint"
                  key="DeliveryPrint"
                  valuePropName="checked"
                  className={styles.horizontal}
                >
                  <Checkbox disabled={!edit}>Print</Checkbox>
                </Form.Item>
                <Form.Item
                  name="DeliveryEmail"
                  key="DeliveryEmail"
                  valuePropName="checked"
                  className={styles.horizontal}
                >
                  <Checkbox disabled={!edit}>Email</Checkbox>
                </Form.Item>
                <Form.Item
                  name="DeliverySMS"
                  key="DeliverySMS"
                  valuePropName="checked"
                  className={styles.horizontal}
                >
                  <Checkbox disabled={!edit}>SMS</Checkbox>
                </Form.Item>
              </div>
              <div>
                <Divider orientation="left">Phone Numbers</Divider>
                <Form.Item name="PhoneHome" key="PhoneHome" label="Home">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item name="PhoneWork" key="PhoneWork" label="Work">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item name="PhoneCell" key="PhoneCell" label="Mobile">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item name="PhoneFax" key="PhoneFax" label="Fax">
                  <Input disabled={!edit} />
                </Form.Item>

                <Divider orientation="left">Account Information</Divider>
                <Form.Item name="Account" key="Account" label="Account">
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item name="TIN" key="TIN" label="TIN">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item name="TINType" key="TINType" label="TIN Type">
                  <Select disabled={!edit}>
                    <Option key={'Unknown'} value={0}>
                      0-Unknown
                    </Option>
                    <Option key={'EIN'} value={3}>
                      1-EIN
                    </Option>
                    <Option key={'SSN'} value={1}>
                      2-SSN
                    </Option>
                  </Select>
                </Form.Item>
                <Divider orientation="left">Notices & Forms</Divider>
                <Form.Item
                  name="TaxReporting"
                  key="TaxReporting"
                  valuePropName="checked"
                  className={styles.horizontal}
                >
                  <Checkbox disabled={!edit}>Tax Reporting</Checkbox>
                </Form.Item>
                <Form.Item
                  name="Rolodex"
                  key="Rolodex"
                  valuePropName="checked"
                  className={styles.horizontal}
                >
                  <Checkbox disabled={!edit}>Print Rolodex Cards</Checkbox>
                </Form.Item>
                <Divider orientation="left">Personal</Divider>
                <Form.Item name="Birthday" key="Birthday" label="Birthday">
                  <DatePicker disabled={!edit} format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Details" key="2">
            <div className={styles.tab}>
              <div>
                <Divider orientation="left">Categories & Grouping</Divider>
                <Form.Item name="Code" key="Code" label="Lender Code">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item name="Counselor" key="Counselor" label="Counselor">
                  <Input disabled={!edit} />
                </Form.Item>
                <Form.Item
                  name="Categories"
                  key="Categories"
                  label="Categories"
                >
                  <Input disabled={true} />
                </Form.Item>
              </div>
              <div>
                <Divider orientation="left">
                  Send Check to Payee
                  <Form.Item
                    name="UsePayee"
                    key="UsePayee"
                    label=""
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={!edit}
                      onChange={(event) => setUsePayee(event?.target?.checked)}
                    />
                  </Form.Item>
                </Divider>
                <Form.Item name="Payee" key="Payee" label="">
                  <Input disabled={!usePayee || !edit} />
                </Form.Item>
              </div>
            </div>
          </TabPane>
          <TabPane tab="ACH" key="3">
            <Divider orientation="left">ACH Information</Divider>
            <Form.Item name="BankName" key="BankName" label="Bank Name">
              <Input disabled={!edit} />
            </Form.Item>
            <Form.Item
              name="BankAddress"
              key="BankAddress"
              label="Bank Address"
            >
              <Input disabled={!edit} />
            </Form.Item>
            <Form.Item
              name="RoutingNumber"
              key="RoutingNumber"
              label="Routing Number"
            >
              <Input disabled={!edit} />
            </Form.Item>
            <Form.Item
              name="AccountNumber"
              key="AccountNumber"
              label="Account Number"
            >
              <Input disabled={!edit} />
            </Form.Item>
            <Form.Item name="Account" key="Account" label="Individual Id">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item name="FullName" key="FullName" label="Individual Name">
              <Input disabled={!edit} />
            </Form.Item>
            <Form.Item
              name="AccountType"
              key="AccountType"
              label="Account Type"
            >
              <Select disabled={!edit}>
                <Option value="0">Checking</Option>
                <Option value="1">Savings</Option>
                <Option value="2">GL Deposite(Credit)</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="ServiceStatus"
              key="ServiceStatus"
              label="Service Status"
            >
              <Select disabled={!edit}>
                <Option value="0">None</Option>
                <Option value="1">Active</Option>
                <Option value="2">Cancelled</Option>
                <Option value="3">Hold</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="SendPrtNotDe"
              key="SendPrtNotDe"
              valuePropName="checked"
              className={styles.horizontal}
            >
              <Checkbox disabled={!edit}>
                Send Printed Notification of Deposit
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="SendEmailNotDe"
              key="SendEmailNotDe"
              valuePropName="checked"
              className={styles.horizontal}
            >
              <Checkbox disabled={!edit}>
                Send Email Notification of Deposit
              </Checkbox>
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </>
  )
}

export default ProfilePage
