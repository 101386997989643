import React from 'react'

const Logo: React.FC<any> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 234 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.864 24.202c0 12.744-5.194 18.179-17.264 18.179-12.108 0-17.264-5.472-17.264-18.179 0-12.744 5.157-18.18 17.264-18.18 12.032-.036 17.264 5.436 17.264 18.18Zm-27.989 0c0 9.328 3.14 13.221 10.725 13.221 7.548 0 10.724-3.893 10.724-13.221S50.187 10.981 42.6 10.981s-10.725 3.856-10.725 13.221ZM22.31 41.39H0V6.978h6.24v29.454h16.07v4.958Zm57.958-29.454h10.65V6.978h-27.54v4.958h10.65v29.453h6.24V11.936Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.463 41.39h22.309v-4.96h-16.068V6.979h-6.241V41.39Zm35.089 0V6.977h-6.241v34.411h6.241Zm38.116-34.412v34.411h-7.399l-15.77-26.405v26.405h-5.941V6.978h7.361l15.77 26.405V6.978h5.979Zm15.283 14.213v-9.55H234V6.979h-24.327V41.39H234v-4.664h-18.049V25.818h16.068V21.19h-16.068Z"
        fill="#00D05C"
      />
      <path d="M109.676 0h-3.737v48h3.737V0Z" fill="#CEA63E" />
    </svg>
  )
}

export default Logo
