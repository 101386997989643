import { FC, useCallback } from 'react'
import { Menu } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import Button from '../Button'
import Utils from '../../../utils/Utils'
import styles from './MainMenu.module.scss'

type MenuItem = Required<MenuProps>['items'][number]

const MainMenu: FC<MenuProps> = (props = { mode: 'horizontal' }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ])

  const signIn = () => navigate('dashboard')
  const signOutHandler = useCallback(() => {
    navigate('/')
    signOut({ global: true })
  }, [])

  const isAdmin = user ? Utils.isAdmin(user) : false
  const isInvestor = user ? Utils.isInvestor(user) : false

  const getItem = (
    label: React.ReactNode,
    key?: React.Key | undefined,
    icon?: React.ReactNode,
    children?: MenuItem[],
    style?: any,
    type?: 'group'
  ): MenuItem => ({
    key,
    icon,
    children,
    label,
    // @ts-ignore:
    type,
    style,
  })

  const items: MenuItem[] = [
    ...(!user
      ? [
          getItem(<Link to="/builders">Builders</Link>, '/builders'),
          getItem(<Link to="/dashboard">Investors</Link>, 'investors'),
          getItem(<Link to="/products">Products</Link>, '/products'),
          getItem(
            <Button onClick={signIn} text="SIGN IN"></Button>,
            'signin',
            null,
            [],
            { paddingRight: 0, marginLeft: 'auto' }
          ),
        ]
      : []),

    ...(user && isAdmin && !isInvestor
      ? [
          getItem(
            <Link to="/admin-dashboard">Dashboard</Link>,
            '/admin-dashboard'
          ),
          getItem(
            <Link to="/transaction-data">Transaction Data</Link>,
            '/transaction-data'
          ),
          getItem(<Link to="/market-data">Market Data</Link>, '/market-data'),
          getItem('Notifications', 'notificationsItem', null, [
            getItem(
              <Link to="/notifications">Notifications</Link>,
              '/notifications'
            ),
            getItem(
              <Link to="/send-notification">Send Notification</Link>,
              'send-notification-admin'
            ),
          ]),
          getItem('Users', 'usersItem', null, [
            getItem(<Link to="/users">Users</Link>, 'users'),
            getItem(
              <Link to="/users-requests">Requests</Link>,
              '/users-requests'
            ),
          ]),
          getItem(<Link to="/loans">Loans</Link>, '/loans'),
          getItem(<Link to="/news">News</Link>, '/news'),
        ]
      : []),

    ...(user && isInvestor
      ? [
          getItem(<Link to="/dashboard">Dashboard</Link>, '/dashboard'),
          getItem('Account', 'account', null, [
            getItem(<Link to="/dashboard">Overview</Link>, '/overview'),
            getItem(<Link to="/investors">Portfolio</Link>, '/investors'),
            getItem(<Link to="/history">History</Link>, '/history'),
            getItem(<Link to="/tax">Tax Forms</Link>, '/tax'),
          ]),
          getItem('Registered Area', 'registered', null, [
            getItem('Reporting', 'reporting', null, [
              getItem(
                <Link to="/statement">Statement of Account</Link>,
                '/statement'
              ),
              getItem(
                <Link to="/investors">Account Portfolio</Link>,
                'account-portfolio'
              ),
              getItem(
                <Link to="/history">Account History</Link>,
                'account-history'
              ),
            ]),
          ]),
          getItem(<Link to="/projects">Investment Offerings</Link>, 'projects'),
        ]
      : []),

    ...(user
      ? [
          getItem('Messages', 'messagesItem', null, [
            getItem(<Link to="/messages">Inbox</Link>, '/messages'),
            getItem(<Link to="/send-message">Compose</Link>, '/send-message'),
            getItem(
              <Link to="/messages/sent">Sent Items</Link>,
              '/messages/sent'
            ),
          ]),
        ]
      : []),

    ...(user && isInvestor
      ? [
          getItem('Servicer', 'servicer', null, [
            // getItem(<Link to="/announcement">Announcement</Link>, 'announcement'),
            getItem(
              <Link to="/send-message">Send Message</Link>,
              'send-message'
            ),
          ]),
        ]
      : []),

    ...(user
      ? [
          getItem(
            '',
            'user',
            <span className={styles.icon}>
              <UserOutlined />
              {isAdmin ? 'Admin' : isInvestor ? 'Investor' : ''}
            </span>,
            [
              getItem(
                <span>
                  {Utils.getGreetingPrefix()}, {user?.attributes?.given_name}{' '}
                  {user?.attributes?.family_name}
                </span>,
                'name'
              ),
              getItem(<Link to="/profile">Profile</Link>, '/profile'),
              getItem(
                <Link to="/send-notification">Send Notification</Link>,
                '/send-notification'
              ),
              getItem(
                <Button
                  onClick={signOutHandler}
                  text="SIGN OUT"
                  isSmall
                ></Button>,
                'signOutHandler'
              ),
            ],
            { paddingRight: 0, marginLeft: 'auto', height: 44 }
          ),
        ]
      : []),
  ]

  return (
    <Menu
      mode={props.mode}
      className={styles.lotline_menu}
      overflowedIndicator={<MenuOutlined />}
      selectedKeys={[location.pathname]}
      items={items}
    />
  )
}

export default MainMenu
