import React, { createElement } from 'react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { Route, Routes } from 'react-router'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { Hub } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'

import withAuth from './hoc/withAuth'
import Utils from 'utils/Utils'
import HomePage from './pages/home/HomePage'
import BuildersPage from './pages/builders/BuildersPage'
import InvestorsPage from './pages/investors/InvestorsPage'
import ProductsPage from './pages/products/ProductsPage'
import DashboardPage from './pages/dashboard/DashboardPage'
import ProjectsPage from './pages/projects/ProjectsPage'
import ProjectPage from './pages/project/ProjectPage'
import HistoryPage from './pages/history/HistoryPage'
import {
  AdminPage,
  UsersPage,
  UserPage,
  LoansPage,
  LoanPage,
  NewsPage,
  NewsFormPage,
} from './pages/admin'
import ProfilePage from './pages/service/ProfilePage'
import NotificationsPage from './pages/notifications/NotificationsPage'
import NotificationsFormPage from './pages/notifications/NotificationsFormPage'
import MessagesPage from './pages/messages/MessagesPage'
import MessagesFormPage from './pages/messages/MessagesFormPage'
import TaxFormsPage from './pages/investors/TaxFormsPage'
import StatementPage from './pages/investors/StatementPage'
import RequestsPage from './pages/admin/RequestsPage'
import LoanInvestorsPage from './pages/admin/LoanInvestorsPage'
import FredDataPage from './pages/admin/FredDataPage'
import AdminDashboardPage from './pages/admin/AdminDashboardPage'

import './App.css'
import './App.scss'

const Root: React.FC = () => {
  const navigate = useNavigate()
  const { user } = useAuthenticator((context) => [context.user])
  const [authStatus, setAuthStatus] = React.useState()

  React.useEffect(() => {
    if (authStatus === 'signIn') {
      const isAdmin = user ? Utils.isAdmin(user) : false

      if (isAdmin) {
        navigate('/admin-dashboard')
      }
    }
  }, [authStatus, user])

  React.useEffect(() => {
    return () => Hub.remove('signIn', listener)
  }, [])

  const listener = (data: any) => {
    if (['signIn', 'signUp', 'signOut'].includes(data.payload.event)) {
      setAuthStatus(data.payload.event)
    }
    return
  }

  Hub.listen('auth', listener)

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/builders" element={createElement(withAuth(BuildersPage))} />
      <Route
        path="/investors"
        element={createElement(withAuth(InvestorsPage))}
      />
      <Route path="/products" element={createElement(withAuth(ProductsPage))} />
      <Route
        path="/dashboard"
        element={createElement(withAuth(DashboardPage))}
      />
      <Route path="/projects" element={createElement(withAuth(ProjectsPage))} />
      <Route path="/history" element={createElement(withAuth(HistoryPage))} />
      <Route
        path="/send-notification"
        element={createElement(withAuth(NotificationsFormPage))}
      />
      <Route path="/profile" element={createElement(withAuth(ProfilePage))}>
        <Route path=":edit" element={createElement(withAuth(ProfilePage))} />
      </Route>

      <Route
        path="/notifications"
        element={createElement(withAuth(NotificationsPage, 'admin'))}
      />
      <Route
        path="/investors/project/:id"
        element={createElement(withAuth(ProjectPage))}
      >
        <Route path=":edit" element={createElement(withAuth(ProjectPage))} />
      </Route>
      <Route
        path="/transaction-data"
        element={createElement(withAuth(AdminPage, 'admin'))}
      />
      <Route
        path="/users"
        element={createElement(withAuth(UsersPage, 'admin'))}
      />
      <Route
        path="/users-requests"
        element={createElement(withAuth(UsersPage, 'admin'))}
      />
      <Route
        path="/user-profile/:id"
        element={createElement(withAuth(ProfilePage, 'admin'))}
      />
      <Route
        path="/users/:id"
        element={createElement(withAuth(UserPage, 'admin'))}
      />
      <Route
        path="/loans"
        element={createElement(withAuth(LoansPage, 'admin'))}
      />
      <Route
        path="/loans/:id"
        element={createElement(withAuth(LoanPage, 'admin'))}
      />
      <Route
        path="/loans/:id/investors"
        element={createElement(withAuth(LoanInvestorsPage, 'admin'))}
      />
      <Route
        path="/loans/:id/requests"
        element={createElement(withAuth(RequestsPage, 'admin'))}
      />

      <Route
        path="/news"
        element={createElement(withAuth(NewsPage, 'admin'))}
      />
      <Route
        path="/news/:id"
        element={createElement(withAuth(NewsFormPage, 'admin'))}
      />
      <Route
        path="/add-news"
        element={createElement(withAuth(NewsFormPage, 'admin'))}
      />
      <Route path="/messages" element={createElement(withAuth(MessagesPage))} />
      <Route
        path="/messages/:id"
        element={createElement(withAuth(MessagesFormPage))}
      />
      <Route
        path="/messages/:id/reply"
        element={createElement(withAuth(MessagesFormPage))}
      />
      <Route
        path="/send-message"
        element={createElement(withAuth(MessagesFormPage))}
      />
      <Route
        path="/messages/sent"
        element={createElement(withAuth(MessagesPage))}
      />
      <Route path="/tax" element={createElement(withAuth(TaxFormsPage))} />
      <Route
        path="/statement"
        element={createElement(withAuth(StatementPage))}
      />
      <Route
        path="/market-data"
        element={createElement(withAuth(FredDataPage, 'admin'))}
      >
        <Route
          path=":edit"
          element={createElement(withAuth(FredDataPage, 'admin'))}
        />
      </Route>
      <Route
        path="/admin-dashboard"
        element={createElement(withAuth(AdminDashboardPage, 'admin'))}
      />
    </Routes>
  )
}

const App: React.FC = () => {
  return (
    <Authenticator.Provider>
      <ParallaxProvider>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </ParallaxProvider>
    </Authenticator.Provider>
  )
}

export default App
