import { FC, useEffect, useState, ReactNode } from 'react'
import { Table, Pagination, Empty, Tag } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import './Table.css'

import Utils from '../../../utils/Utils'

type TableProp = {
  data: any,
  columns: any,
  total: any,
  fetchData?: (offset: number, sorters: {}) => {},
  filters?: {},
  pageSize?: any,
  rowKey?: string,
  loading?: any,
  showHeader?: boolean,
  className?: string,
  title?: any,
  rowSelection?: any,
  expandedRowRender?: (record: any) => ReactNode,
  expandIcon?: (record: any) => ReactNode,
  summary?: (data: any) => ReactNode,
}
const ProjectsTable: FC<TableProp> = ({
  data,
  columns,
  total,
  fetchData,
  filters,
  pageSize = 10,
  rowKey,
  loading = false,
  showHeader = true,
  className,
  title,
  rowSelection,
  expandedRowRender,
  expandIcon,
  summary,
}) => {
  const [sorters, setSorters] = useState<{}>({})
  const [offset, setOffset] = useState(0)

  const changeHandler = (pagination: any, filters: any, sorter: any) => {
    setSorters(Utils.getSorters(sorter))
    setOffset(0)
  }

  const paginationChangeHandler = (page: any) => {
    setOffset((page - 1) * pageSize)
  }

  const getTitle = (name: any, key: any) => {
    if (!Object.keys(sorters).includes(key)) key = `ABS(${key})`

    const index = Object.keys(sorters).indexOf(key)
    return (
      <>
        {name}
        {index !== -1 && (
          <Tag className="sortTag" color="green">
            {index + 1}
          </Tag>
        )}
      </>
    )
  }

  columns = columns.map((item: any) => ({
    ...item,
    title: getTitle(item.title, item.dataIndex),
  }))

  useEffect(() => {
    if (offset > 0) setOffset(0)
    else if (filters && fetchData) fetchData(offset, sorters)
  }, [filters])

  useEffect(() => {
    if (fetchData) fetchData(offset, sorters)
  }, [offset, sorters])

  return (
    <>
      <Table
        className={className}
        id="scrollableDiv"
        title={title}
        loading={loading}
        showHeader={showHeader}
        dataSource={data}
        columns={columns}
        rowKey={rowKey}
        pagination={false}
        summary={summary}
        onChange={changeHandler}
        rowSelection={rowSelection}
        locale={{
          emptyText: loading ? (
            'loading'
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ),
        }}
        expandable={{
          expandedRowRender: expandedRowRender,
          expandIcon: expandIcon
            ? expandIcon
            : expandedRowRender
            ? ({
                expanded = true,
                onExpand = (record: any, e: any) => {},
                record,
              }) =>
                !expanded ? (
                  <DownOutlined
                    className="icon"
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                  <UpOutlined
                    className="icon"
                    onClick={(e) => onExpand(record, e)}
                  />
                )
            : undefined,
        }}
      />
      {total > pageSize && (
        <Pagination
          current={offset / pageSize + 1}
          pageSize={pageSize}
          onChange={paginationChangeHandler}
          showSizeChanger={false}
          showLessItems={false}
          showPrevNextJumpers={false}
          showQuickJumper={false}
          total={total}
        />
      )}
    </>
  )
}

export default ProjectsTable
