import { FC, Ref, useEffect, useRef, useState } from 'react'
import { useParallax } from 'react-scroll-parallax'

import tt from '../../../assets/svgs/parallax/gold-house.svg'
import tt1 from '../../../assets/svgs/parallax/tst.svg'
import useWindowDimensions from '../../../hooks/windowDimensions'
import './Background1.css'

type BackgroundProps = {
  rectFunc: () => any,
}

const Background1: FC<BackgroundProps> = (props) => {
  const { width, height } = useWindowDimensions()

  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  })

  const lotStyle = {
    maxHeight: 0.15 * dimension.width,
    minHeight: 0.15 * dimension.height,
  }

  // const { ref } = useParallax<HTMLDivElement>({
  //   speed: 1,
  //   translateY: [-64, 0],
  //   translateX: [
  //     ((-1.3 * dimension.height) / (dimension.width ? dimension.width : 1)) *
  //       64,
  //     0,
  //   ],
  //   shouldAlwaysCompleteAnimation: true,
  //   startScroll: 0,
  //   endScroll: 160,
  // });
  // const prevRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement>(null)

  const [shadowStyle, setShadowStyle] = useState({
    left: (ref.current?.clientWidth ? ref.current?.clientWidth : 0) + 16,
    top: (ref.current?.clientHeight ? 3 * ref.current?.clientHeight : 0) + 48,
    width: (ref.current?.clientWidth ? ref.current?.clientWidth : 0) - 5,
    height: (ref.current?.clientHeight ? ref.current?.clientHeight : 0) - 5,
  })

  const [backgroundStyle, setBackgroundStyle] = useState({
    width: 0.5 * width,
    height: 0.8 * height,
  })

  useEffect(() => {
    const rect = props.rectFunc()
    const d = {
      width: rect.width,
      height: rect.height,
      top: rect.top,
      left: rect.left,
    }
    console.log(d)

    setDimension(d)

    setBackgroundStyle({ width: 0.5 * d.width, height: 1.0 * d.height })
    const ts = setTimeout(() => {
      setShadowStyle({
        left: (ref.current?.clientWidth ? ref.current?.clientWidth : 0) + 16,
        top:
          (ref.current?.clientHeight ? 3 * ref.current?.clientHeight : 0) + 44,
        width: (ref.current?.clientWidth ? ref.current?.clientWidth : 0) - 8,
        height: (ref.current?.clientHeight ? ref.current?.clientHeight : 0) - 8,
      })
    }, 10)
    return () => clearTimeout(ts)
  }, [width, height])
  return (
    <div className="lotline-background" style={backgroundStyle}>
      <div className="lotline-background-1"></div>
      <div className="lotline-over">
        <div className="lot-row" style={{ width: 0.16 * dimension.width }}>
          <div
            className="lot"
            style={{ ...lotStyle, visibility: 'hidden' }}
          ></div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.4 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.6 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div
            className="lot"
            style={{ ...lotStyle, visibility: 'hidden' }}
          ></div>
          <div
            className="lot"
            style={{ ...lotStyle, visibility: 'hidden' }}
          ></div>
          <div
            className="lot"
            style={{ ...lotStyle, visibility: 'hidden' }}
          ></div>
        </div>
        <div className="lot-row" style={{ width: 0.16 * dimension.width }}>
          <div className="lot" style={{ ...lotStyle, opacity: 0.3 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.4 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div
            // ref={prevRef}
            className="lot"
            style={{ ...lotStyle, opacity: 0.6 }}
          >
            <img className="awfull1" src={tt1} />
          </div>
          <div
            className="shadow"
            style={{
              ...lotStyle,
              ...shadowStyle,
            }}
          ></div>
          <div ref={ref} className="lot-gold" style={lotStyle}>
            <img className="awfull1" src={tt} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.3 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.2 }}>
            <img className="awfull1" src={tt1} />
          </div>
        </div>
        <div className="lot-row" style={{ width: 0.16 * dimension.width }}>
          <div className="lot" style={{ ...lotStyle, opacity: 0.3 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.4 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.6 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.4 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div className="lot" style={{ ...lotStyle, opacity: 0.3 }}>
            <img className="awfull1" src={tt1} />
          </div>
          <div
            className="lot"
            style={{ ...lotStyle, visibility: 'hidden' }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Background1
