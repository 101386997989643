import { FC, useEffect, useState } from 'react'
import { message } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import Amplify, { API } from 'aws-amplify'

import aws_export from '../../aws-exports'
import ProjectsTable from '../../components/common/Table'
import Button from '../../components/common/Button'
import styles from './AdminPage.module.scss'

const UsersPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const isRequests = location?.pathname === '/users-requests'

  const fetchUsers = async (): Promise<any> => {
    setLoading(true)
    const res = await API.get('usersApi', '/users', {
      queryStringParameters: {
        unconfirmed: isRequests ? false : true,
        investors: isRequests ? true : false,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    setUsers(res?.users || [])
    setLoading(false)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
    fetchUsers()
  }, [])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: any, record: any) => (
        <>
          {record?.given_name} {record?.family_name}
        </>
      ),
    },
    {
      dataIndex: 'action',
      key: 'action',
      render: (action: any, record: any) => (
        <Button
          text={isRequests ? 'Edit' : 'View'}
          isSmall
          onClick={() =>
            navigate(`/${isRequests ? 'users' : 'user-profile'}/${record.id}`)
          }
        />
      ),
    },
  ]

  return (
    <>
      <h3 className={styles.title}>User's Requests</h3>
      <ProjectsTable
        data={users}
        total={users.length}
        columns={columns}
        loading={loading}
        rowKey="id"
      />
    </>
  )
}

export default UsersPage
