import { FC, useState, useEffect } from 'react'
import Amplify, { API } from 'aws-amplify'
import { Link, useLocation } from 'react-router-dom'
import { Button, message } from 'antd'

import aws_export from '../../aws-exports'
import Utils from '../../utils/Utils'
import ProjectsTable from '../../components/common/Table'
import styles from './MessagesPage.module.scss'

const MessagesPage: FC = () => {
  const location = useLocation()
  const isSent = location.pathname.includes('sent')

  const pageSize = 5
  const [messages, setMessages] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])

  const columns = [
    {
      title: `${isSent ? 'Recipient' : 'From'}`,
      dataIndex: isSent ? 'to' : 'from',
      key: 'from',
      render: (from: any, record: any) => (
        <Link
          to={`/messages/${record.id}`}
          style={{
            fontWeight: record.userStatus === 'read' ? 'normal' : 'bold',
          }}
        >
          {from}
        </Link>
      ),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (subject: any, record: any) => (
        <Link
          to={`/messages/${record.id}`}
          style={{
            fontWeight: record.userStatus === 'read' ? 'normal' : 'bold',
          }}
        >
          {subject}
        </Link>
      ),
    },

    {
      title: `Date ${isSent ? 'Sent' : 'Received'}`,
      dataIndex: 'date',
      key: 'date',
      render: (date: any) => Utils.dateTimeFormat(date),
    },
  ]

  const fetchMessages = async (offset = 0): Promise<any> => {
    setLoading(true)
    const res = await API.get('messageApi', '/items', {
      queryStringParameters: {
        Sent: isSent,
        Offset: offset,
        PageSize: pageSize,
      },
    }).catch((e) => message.error("Can't upload items. Try again."))
    setMessages(res?.data || [])
    setTotal(res?.total || 0)
    setLoading(false)
  }

  const deleteMessages = async (): Promise<any> => {
    const res = await API.del('messageApi', '/items', {
      queryStringParameters: {
        Sent: isSent,
        Ids: JSON.stringify(selectedRowKeys),
      },
    }).catch((e) => message.error("Can't delete items. Try again."))
    setMessages(res?.data)
    setTotal(res?.total)
  }

  useEffect(() => {
    Amplify.configure(aws_export)
  }, [])

  return (
    <>
      <h3 className={styles.title}>Messages</h3>
      <ProjectsTable
        data={messages}
        columns={columns}
        total={total}
        loading={loading}
        fetchData={fetchMessages}
        rowKey="id"
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys)
          },
        }}
      />

      <Button onClick={deleteMessages} disabled={selectedRowKeys.length === 0}>
        Delete Checked Mail
      </Button>
    </>
  )
}

export default MessagesPage
