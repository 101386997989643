import { FC, useState } from "react";
import "./Products.css";
import Product from "./Product";
import product0 from "../../../assets/images/product-0.png";
import product1 from "../../../assets/images/product-1.png";
import product2 from "../../../assets/images/product-2.png";

const Products: FC = () => {
  const [products, setProducts] = useState([
    {
      index: "01",
      text: "Acquisition & development facilities",
      img: product0,
    },
    {
      index: "02",
      text: "Lot acquisition & inventory loans",
      img: product1,
    },
    {
      index: "03",
      text: "Infill & lot splitt loans",
      img: product2,
    },
  ]);

  return (
    <div className="products-container">
      <div className="products-inner-container lotline-left-border lotline-right-border">
        <div className="product-title lotline-font-header2-w">
          Our products include
        </div>
        <div className="products">
          <Product
            index={products[0].index}
            text={products[0].text}
            image={products[0].img}
          ></Product>
          <Product
            index={products[1].index}
            text={products[1].text}
            image={products[1].img}
          ></Product>
          <Product
            index={products[2].index}
            text={products[2].text}
            image={products[2].img}
          ></Product>
        </div>
      </div>
    </div>
  );
};
export default Products;
