import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

type SwiperProps = {
  opinions: any[];
};

const DesktopProjectsSwiper: FC<SwiperProps> = (props) => {
  const opinions = props.opinions;
  return (
    <Swiper navigation={true} modules={[Navigation]}>
      {opinions.map((v, idx) => {
        return (
          <SwiperSlide key={v.key}>
            <div className="project-slider">
              <div className="project-slider-header lotline-font-header2-w">
                {v.title}
              </div>
              <div className="project-slider-txt">{v.text}</div>
              <div className="project-slider-delimiter"></div>
              <div className="lotline-font-regular-w">{v.who}</div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default DesktopProjectsSwiper;
